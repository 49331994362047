function OfficialRulesPDF({ officialRulesLink, isRubric = false }) {
  return (
    <div className="official-rules-section">
      <div className="container center-text">
        <span className="subheading">
          Click the link below to download a copy of the{" "}
          {isRubric
            ? "rubric that we give our judges"
            : "participant agreement"}
        </span>
        <h2 className="heading-secondary">
          <a
            className="official-rules"
            target="_blank"
            href={
              isRubric
                ? "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Rules%2FRubric_mostRecent.pdf?alt=media"
                : officialRulesLink
            }
          >
            {isRubric ? "Writing Battle Rubric" : "Official Rules Agreement"}
          </a>
        </h2>
      </div>
    </div>
  );
}

export default OfficialRulesPDF;
