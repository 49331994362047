import JudgeMallet from "../../img/judgeMallet.png";
import { useEffect, useRef, useState } from "react";
import { IonIcon } from "@ionic/react";
import { caretBackOutline } from "ionicons/icons";
import { getDebriefHouse } from "../../publicFunctions/debriefHandler";
import { getPublicProfileByUserId } from "../../publicFunctions/publicProfileHandler";
import { NOT_ACTIVE } from "../../publicFunctions/publicHelpers/standardErrorMessages";
import {
  getCardImageUrlByCard,
  getFlagImageUrlByFlagFileName,
} from "../../publicFunctions/publicHelpers/imageUrlBuilder";
import DebriefRounds from "./DebriefRounds";

function DebriefHouse({
  houseId,
  goBackHandler,
  myStory,
  debriefMeta,
  myDuels,
  storiesIJudged,
}) {
  const roundsRef = useRef(null);
  const [houseData, setHouseData] = useState(null);
  const [renderedHouse, setRenderedHouse] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [listOfPublishedUserIds, setListOfPublishedUserIds] = useState(null);
  const [mapOfPublishedStoryIds, setMapOfPublishedStoryIds] = useState(null);
  const [mapOfUserIdToPublicProfile, setMapOfUserIdToPublicProfile] =
    useState(null);
  const [mapStoryIdToStoryInfo, setMapStoryIdToStoryInfo] = useState(null);
  const [selectedStory, setSelectedStory] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (houseId) {
      // get debriefHouse from database then display the
      getDebriefHouse(houseId)
        .then((houseData) => {
          if (houseData) {
            setHouseData(houseData);
          }
        })
        .catch((err) => {
          if (err?.message && err.message.includes("permission")) {
            setErrorMessage(NOT_ACTIVE);
          } else {
            console.log(err);
            setErrorMessage(
              "Something went wrong. Please try refreshing the page."
            );
          }
        });
    }
  }, [houseId]);

  useEffect(() => {
    if (!houseData) {
      return;
    }

    const CONTEST_STRUCTURE =
      houseData.battleId === "may2023" || houseData.battleId === "aug2023"
        ? "LEGACY_0"
        : houseData.battleId === "nov2023" ||
          houseData.battleId === "feb2024" ||
          houseData.battleId === "may2024"
        ? "LEGACY_1"
        : "CURRENT";

    let mapOfFinalShowdownStories = null;
    // set the map of finalshowdown if one exists
    if (debriefMeta?.finalShowdown) {
      mapOfFinalShowdownStories = new Map();
      for (let i = 0; i < debriefMeta.finalShowdown.storyList.length; i++) {
        const story = debriefMeta.finalShowdown.storyList[i];
        mapOfFinalShowdownStories.set(story.storyId, true);
      }
    }

    const tempRenderArray = [];

    // FLAG and HOUSE NAME
    tempRenderArray.push(
      <div key={"houseTitle"} className="debrief-genre-title--container">
        <img
          className="debrief-flag"
          src={getFlagImageUrlByFlagFileName(houseData.flagFileName)}
        />
        <p className="heading-secondary margin--none">{houseData.name}</p>
      </div>
    );
    const subtitle =
      "The following stories represent " +
      houseData.name +
      " in the genre, " +
      houseData.genre;
    tempRenderArray.push(
      <div key={"subtitle"} className="debrief-genre-title--container">
        <p className="heading-tertiary margin--none">{subtitle}</p>
      </div>
    );

    // check for an additional subtitle if some of the rounds have been completed
    if (houseData.rounds && houseData.revealedUpToRound) {
      const MAX_SWISS_ROUNDS = 5;

      let subsubtitle;
      if (houseData.revealedUpToRound === 5) {
        subsubtitle =
          "The scores are out of a possible 10 points and are the sum " +
          "of the Duels up to and including Round " +
          MAX_SWISS_ROUNDS.toString(10) +
          (CONTEST_STRUCTURE === "LEGACY_0"
            ? ". With 8 or more points, a story qualifies for the Final Showdown (highlighted in green)."
            : CONTEST_STRUCTURE === "LEGACY_1"
            ? ". The TOP 8 stories qualify for the Final Showdown (highlighted in green)."
            : ". With 7 or more points, a story qualifies for the Final Showdown (highlighted in green).");

        if (!mapOfFinalShowdownStories && CONTEST_STRUCTURE === "LEGACY_0") {
          subsubtitle +=
            " Please refer to your reveal schedule to find out when the Dark Horse " +
            "Candidates (if any) have been selected from this house.";
        } else if (CONTEST_STRUCTURE === "LEGACY_0") {
          subsubtitle +=
            " The Dark Horse Candidates (if any) that have qualified for the Final " +
            "Showdown have also been highlighted in green.";
        } else if (CONTEST_STRUCTURE === "LEGACY_1") {
          subsubtitle +=
            " Stories tied at the cut off for TOP 8, but were not chosen to move on after the " +
            "Tie-breaking round are designated as Honourable Mentions (HM).";
        }
      } else {
        subsubtitle =
          "The scores are out of a possible " +
          Math.floor(houseData.revealedUpToRound * 2).toString(10) +
          " points and are the sum of the Duels up to and including Round " +
          houseData.revealedUpToRound.toString(10) +
          ". Please refer to " +
          "your reveal schedule for the next results.";
      }
      subsubtitle +=
        " Note that stories that have less than 3 " +
        "points do not display " +
        "a score, and are ordered alphabetically.";
      tempRenderArray.push(
        <div key={"subsubtitle"} className="debrief-genre-title--container">
          <p className="description">{subsubtitle}</p>
        </div>
      );
    }

    // get a map of the story info
    let tempMapStoryIdToStoryInfo = mapStoryIdToStoryInfo
      ? mapStoryIdToStoryInfo
      : new Map();
    let tempMapStoryIdToCurrentScore = new Map();

    const listOfStories = houseData.listOfStories;
    if (houseData?.rounds && houseData.revealedUpToRound) {
      // iterate and set map
      for (let i = 0; i < listOfStories.length; i++) {
        const story = listOfStories[i];
        tempMapStoryIdToStoryInfo.set(story.storyId, story);
        tempMapStoryIdToCurrentScore.set(story.storyId, 0);
      }

      // score stories
      for (let i = houseData.revealedUpToRound + 1; i >= 1; i--) {
        const round = houseData.rounds["round" + i.toString(10)];
        if (!round) continue;
        // also update the current score for the duels in this round

        for (let j = 0; j < round.length; j++) {
          const duel = round[j];
          if (duel.winner) {
            tempMapStoryIdToCurrentScore.set(
              duel.winner,
              tempMapStoryIdToCurrentScore.get(duel.winner) + 1
            );
          }
        }
      }
    }

    const MINIMUM_POINTS_TO_SHOW_SCORE = 3;
    // sort the stories
    listOfStories.sort((a, b) => {
      const storyAScore = tempMapStoryIdToCurrentScore.get(a.storyId);
      const storyBScore = tempMapStoryIdToCurrentScore.get(b.storyId);
      if (
        storyAScore >= MINIMUM_POINTS_TO_SHOW_SCORE &&
        storyAScore > storyBScore
      ) {
        return -1;
      } else if (
        storyBScore >= MINIMUM_POINTS_TO_SHOW_SCORE &&
        storyBScore > storyAScore
      ) {
        return 1;
      } else {
        if (mapOfFinalShowdownStories) {
          if (
            mapOfFinalShowdownStories.has(a.storyId) &&
            !mapOfFinalShowdownStories.has(b.storyId)
          ) {
            return -1;
          } else if (
            mapOfFinalShowdownStories.has(b.storyId) &&
            !mapOfFinalShowdownStories.has(a.storyId)
          ) {
            return 1;
          }
        }
        if (a.storyTitle < b.storyTitle) {
          return -1;
        } else if (a.storyTitle >= b.storyTitle) {
          return 1;
        } else return 0;
      }
    });
    const renderedMasterListOfStories = [];
    const tempListOfPublishedUserIds = [];
    const tempMapOfPublishedStoryIds = new Map();
    const NUMBER_OF_STORIES_SELECTED = 8;
    let cutOffPoints = 9000;
    for (let i = 0; i < listOfStories.length; i++) {
      // having a publicProfile means this story has been
      // shared publicaly
      const story = listOfStories[i];
      let publicProfile;
      if (
        story.publishedUserId &&
        mapOfUserIdToPublicProfile &&
        mapOfUserIdToPublicProfile.has(story.publishedUserId)
      ) {
        publicProfile = mapOfUserIdToPublicProfile.get(story.publishedUserId);
      }

      if (story.publishedUserId) {
        tempListOfPublishedUserIds.push(story.publishedUserId);
        tempMapOfPublishedStoryIds.set(story.storyId, true);
      }

      const tinyCardImages = story.tinyCardImages;
      const renderedCards = [];
      if (tinyCardImages?.length) {
        for (let i = 0; i < tinyCardImages.length; i++) {
          const card = tinyCardImages[i];
          renderedCards.push(
            <img
              className="debrief-genre-title-card"
              key={i.toString() + "_" + story.storyId + "_img"}
              src={card}
            />
          );
        }
      } else if (story.cards) {
        renderedCards.push(
          <img
            className="debrief-genre-title-card"
            key={"genre_" + story.storyId + "_img"}
            src={getCardImageUrlByCard(story.cards.genre, true)}
          />,
          <img
            className="debrief-genre-title-card"
            key={"prompt1_" + story.storyId + "_img"}
            src={getCardImageUrlByCard(story.cards.prompt1, true)}
          />,
          <img
            className="debrief-genre-title-card"
            key={"prompt2_" + story.storyId + "_img"}
            src={getCardImageUrlByCard(story.cards.prompt2, true)}
          />
        );
      }
      // this will go cards, story, currentPoints
      let textClass = "description description-line-height--smaller";
      let nameClass = "description--smaller";
      if (myStory?.storyId === story.storyId) {
        textClass += " bold";
      }
      if (publicProfile) {
        textClass += " underline cursor--pointer";
        nameClass += " underline cursor--pointer";
      } else if (story?.publishedUserId) {
        textClass += " underline cursor--pointer";
      }
      const currentPoints = tempMapStoryIdToCurrentScore.get(story.storyId);
      let isHM = false;
      if (i < NUMBER_OF_STORIES_SELECTED) {
        cutOffPoints = currentPoints;
      } else if (
        CONTEST_STRUCTURE === "LEGACY_1" &&
        currentPoints === cutOffPoints &&
        mapOfFinalShowdownStories
      ) {
        isHM = true;
      }

      let currentPointsText = "";
      if (CONTEST_STRUCTURE === "LEGACY_1" && isHM) {
        const pointsText = currentPoints.toString(10);
        currentPointsText = pointsText + "\nHM";
      } else if (currentPoints >= MINIMUM_POINTS_TO_SHOW_SCORE) {
        currentPointsText = currentPoints.toString(10);
      }

      const backgroundColorToUse =
        mapOfFinalShowdownStories &&
        mapOfFinalShowdownStories.has(story.storyId)
          ? "#b5ca8d"
          : "#f3f5f2";

      renderedMasterListOfStories.push(
        <div
          key={i.toString(10) + "_" + "master-list-story"}
          className="house-master-list--story"
        >
          {/* START OF SCORE BUTTON */}
          {houseData.rounds ? (
            <div
              role="button"
              className={
                selectedStory?.storyId === story.storyId
                  ? "debrief-score-button debrief-score-button--selected"
                  : "debrief-score-button"
              }
              style={{
                backgroundColor: backgroundColorToUse,
                cursor: "pointer",
              }}
              onClick={() => {
                if (houseData.rounds && story?.storyId) {
                  setSelectedStory({
                    storyId: story.storyId,
                    storyTitle: story.storyTitle,
                  });
                  if (roundsRef?.current) {
                    roundsRef.current.scrollIntoView();
                  }
                }
              }}
            >
              {storiesIJudged && storiesIJudged.has(story.storyId) ? (
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                  }}
                >
                  <img className="social-icon" src={JudgeMallet} />
                </div>
              ) : null}
              <p
                className={
                  isHM
                    ? "description--smaller bold margin--none center-text padding--small blue pre-wrap"
                    : "description bold margin--none center-text padding--small blue pre-wrap"
                }
              >
                {currentPoints >= MINIMUM_POINTS_TO_SHOW_SCORE
                  ? currentPointsText?.length <= 1
                    ? "\u00A0\u00A0" + currentPointsText + "\u00A0\u00A0"
                    : currentPointsText === "HM"
                    ? currentPointsText
                    : "\u00A0" + currentPointsText + "\u00A0"
                  : "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}
              </p>
            </div>
          ) : storiesIJudged && storiesIJudged.has(story.storyId) ? (
            <div>
              <img className="social-icon" src={JudgeMallet} />
            </div>
          ) : null}
          {/* END OF SCORE BUTTON */}
          <a
            style={
              publicProfile
                ? {
                    display: "flex",
                    cursor: "pointer",
                    pointerEvents: "auto",
                  }
                : { display: "flex", pointerEvents: "none" }
            }
            href={"/story/debrief/" + story.storyId + "?uploadedStory"}
          >
            {renderedCards}
          </a>
          <div>
            <p className={textClass}>
              <a
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  pointerEvents:
                    publicProfile || story.publishedUserId ? "auto" : "none",
                }}
                href={"/story/debrief/" + story.storyId + "?uploadedStory"}
              >
                {story.storyTitle}
              </a>
            </p>
            {publicProfile ? (
              <p className="description--smaller">written by</p>
            ) : !publicProfile && story.publishedUserId ? (
              <p className="description--smaller">loading author...</p>
            ) : null}
            {publicProfile ? (
              <p className={nameClass}>
                <a
                  style={{ textDecoration: "none", color: "inherit" }}
                  href={"/profile/" + publicProfile.pageName}
                  target="_blank"
                >
                  {publicProfile.name}
                </a>
              </p>
            ) : null}
          </div>
        </div>
      );
    }
    tempRenderArray.push(
      <div key="master-list" className="debrief-house-masterlist--stories">
        {renderedMasterListOfStories}
      </div>
    );

    setRenderedHouse(tempRenderArray);

    if (!mapStoryIdToStoryInfo) {
      setMapStoryIdToStoryInfo(tempMapStoryIdToStoryInfo);
    }
    if (!listOfPublishedUserIds) {
      setListOfPublishedUserIds(tempListOfPublishedUserIds);
    }
    if (!mapOfPublishedStoryIds) {
      setMapOfPublishedStoryIds(tempMapOfPublishedStoryIds);
    }
  }, [
    houseData,
    mapOfUserIdToPublicProfile,
    debriefMeta,
    mapOfPublishedStoryIds,
    selectedStory,
    storiesIJudged,
  ]);

  useEffect(() => {
    if (listOfPublishedUserIds?.length && !mapOfUserIdToPublicProfile) {
      populateMap(listOfPublishedUserIds);
    }
  }, [listOfPublishedUserIds]);

  // we really want to make sure this is done only once
  // because it is expensive, but we also have to make sure
  // that we have all the data available here
  async function populateMap(listOfPublishedUserIds) {
    try {
      const tempMap = new Map();
      if (listOfPublishedUserIds?.length) {
        for (let i = 0; i < listOfPublishedUserIds.length; i++) {
          const userId = listOfPublishedUserIds[i];
          if (userId) {
            const publicProfile = await getPublicProfileByUserId(userId);
            if (publicProfile) {
              tempMap.set(userId, publicProfile);
            }
          }
        }
      }
      setMapOfUserIdToPublicProfile(tempMap);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="container">
      {errorMessage ? (
        <p className="description center-text">{errorMessage}</p>
      ) : null}
      <div
        style={{ cursor: "pointer" }}
        onClick={goBackHandler}
        className="house-go-back"
      >
        <IonIcon className="social-icon" icon={caretBackOutline} />
        <p
          style={{ display: "inline-block" }}
          className="description debrief-nav-item"
        >
          Return to House List
        </p>
      </div>
      {renderedHouse}
      {!selectedStory && houseData?.rounds ? (
        <p className="description margin-bottom-sm center-text bold">
          To view the rounds of a story, select the scores on the stories listed
          above.
        </p>
      ) : null}
      <div ref={roundsRef} className="simple-divider"></div>
      {houseData &&
      mapOfPublishedStoryIds &&
      mapStoryIdToStoryInfo &&
      selectedStory ? (
        <>
          <p className="description margin-bottom-sm center-text">
            Displaying the Duels for the story,{" "}
            <span className="bold">{selectedStory.storyTitle}</span>.
            <br />
            To view the rounds of a different story, select the scores on the
            stories listed above.
          </p>
          <DebriefRounds
            houseData={houseData}
            mapStoryIdToStoryInfo={mapStoryIdToStoryInfo}
            mapOfPublishedStoryIds={mapOfPublishedStoryIds}
            myDuels={myDuels}
            selectedStoryId={selectedStory.storyId}
          />
        </>
      ) : null}
    </div>
  );
}

export default DebriefHouse;
