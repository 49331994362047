import { useEffect } from "react";
import SupportingLogo from "../../img/logos/writingBattleLogoSupporting_800.png";
import IconLogo from "../../img/logos/writingBattleLogoFull_800_no_text.webp";

function StoryHeader({
  userInfo,
  openLogInHandler,
  setUserHasBeenPromptedToLogin,
}) {
  useEffect(() => {
    if (userInfo) {
      if (!userInfo.isLoggedIn() && !userInfo.isStillCheckingLogin()) {
        openLogInHandler("login");
        setUserHasBeenPromptedToLogin(true);
      }
    }
  }, [userInfo]);

  return (
    <div className="story-read-header hero-background">
      <div
        className={
          "flex flex--direction-column " +
          "align-items--center justify-content--center flex--gap-smaller"
        }
      >
        <img
          className="read-icon-logo"
          src={IconLogo}
          alt="Writing Battle logo"
        />
      </div>
      <img
        className="read-supporting-logo"
        src={SupportingLogo}
        alt="Writing Battle logo"
      />
      {!userInfo ||
      (!userInfo.isStillCheckingLogin() && !userInfo.isLoggedIn()) ? (
        <button
          onClick={(event) => {
            openLogInHandler("login");
            setUserHasBeenPromptedToLogin(true);
          }}
          className="login-btn read-login"
        >
          Log In
        </button>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default StoryHeader;
