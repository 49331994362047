import { IonIcon } from "@ionic/react";
import {
  logoInstagram,
  logoFacebook,
  logoTwitter,
  logoPinterest,
} from "ionicons/icons";

function SocialLinks({ makeLarge }) {
  const classTemp = makeLarge ? "social-icon--large" : "social-icon";
  return (
    <ul className="social-links">
      <li>
        <a
          className="footer-link"
          target="_blank"
          href="https://www.instagram.com/writingbattle/"
        >
          <IonIcon className={classTemp} icon={logoInstagram} />
        </a>
      </li>
      <li>
        <a
          className="footer-link"
          target="_blank"
          href="https://www.facebook.com/WritingBattle"
        >
          <IonIcon className={classTemp} icon={logoFacebook} />
        </a>
      </li>
      <li>
        <a
          className="footer-link"
          target="_blank"
          href="https://twitter.com/WritingBContest"
        >
          <IonIcon className={classTemp} icon={logoTwitter} />
        </a>
      </li>
      <li>
        <a
          className="footer-link"
          target="_blank"
          href="https://www.pinterest.ca/writingbattle/"
        >
          <IonIcon className={classTemp} icon={logoPinterest} />
        </a>
      </li>
    </ul>
  );
}

export default SocialLinks;
