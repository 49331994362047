import "../styles/myAccount.css";
import "../styles/myAccountQueries.css";
import { useEffect, useState } from "react";
import PrivateAccount from "../components/myAccount/PrivateAccount";
import MyAccountNav from "../components/myAccount/MyAccountNav";
import ChangePassword from "../components/myAccount/ChangePassword";
import SEO from "../components/SEO";

function MyAccount({
  userInfo,
  openLogInHandler,
  myProfile,
  refreshMyProfile,
}) {
  const [settingsCard, setSettingsCard] = useState("private");

  useEffect(() => {
    if (userInfo) {
      if (!userInfo.isLoggedIn()) {
        openLogInHandler("login");
      }
    }
  }, [userInfo]);

  const handleChangePage = (pageToChangeTo) => {
    setSettingsCard(pageToChangeTo);
  };

  return (
    <div className="hero-background my-account">
      <SEO
        title="My Account | Writing Battle"
        description="Modify your settings, and make changes to Writing Battle account information."
        name="Writing Battle"
        type="website"
      />
      <MyAccountNav selectedPage={settingsCard} />
      <div className="settings-wrapper">
        <p
          className="description bold"
          style={{
            padding: "2.4rem 0",
            display: userInfo.isLoggedIn() ? "none" : "block",
          }}
        >
          Please log in to change your account settings
        </p>
        <PrivateAccount
          showMe={
            settingsCard === "private" && userInfo.isLoggedIn() ? true : false
          }
          myProfile={myProfile}
          refreshMyProfile={refreshMyProfile}
          openLogInHandler={openLogInHandler}
          handleChangePage={handleChangePage}
        />
        <ChangePassword
          showMe={
            settingsCard === "changePassword" && userInfo.isLoggedIn()
              ? true
              : false
          }
          userInfo={userInfo}
          handleChangePage={handleChangePage}
        />
      </div>
    </div>
  );
}

export default MyAccount;
