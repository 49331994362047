import { IonIcon } from "@ionic/react";
import {
  getSuperSimpleEstWithTime,
  getSimplestRangePossible,
} from "../../publicFunctions/publicHelpers/helpers";

function GlanceContainer({ icon, title, dates, isOdd, description }) {
  return (
    <div className="glance-container">
      <div
        className={
          isOdd
            ? "glance-card glance-card--odd"
            : "glance-card glance-card--even"
        }
      >
        <h3 className="heading-tertiary center-text">{title}</h3>
        <p className="description center-text">
          <em>
            {dates.length > 1
              ? getSimplestRangePossible(dates[0], dates[1])
              : getSuperSimpleEstWithTime(dates[0])}
          </em>
        </p>
        <IonIcon className="rules-icon" icon={icon} />
        <p className="description--small center-text">{description}</p>
      </div>
    </div>
  );
}

export default GlanceContainer;
