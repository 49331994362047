import { getTrophyImageUrlByFileName } from "./imageUrlBuilder";

export function getNameOfBracket(numberOfMatches) {
  // Tremendous 2048
  // Tubular 1024
  if (numberOfMatches === 256) {
    return "Fantastic 512";
  } else if (numberOfMatches === 128) {
    return "Terrific 256";
  } else if (numberOfMatches === 64) {
    return "Wonderful 128";
  } else if (numberOfMatches === 32) {
    return "Sensational 64";
  } else if (numberOfMatches === 16) {
    return "Thrilling 32";
  } else if (numberOfMatches === 8) {
    return "Sweet 16";
  } else if (numberOfMatches === 4) {
    return "Elite Eight";
  } else if (numberOfMatches === 2) {
    return "Ferocious Four";
  } else if (numberOfMatches === 1) {
    return "Finalists";
  }
}

export function getTrophyUrlForNumberOfMatches(numberOfMatches) {
  if (numberOfMatches === 64) {
    return getTrophyImageUrlByFileName("wonderful128_c.png");
  } else if (numberOfMatches === 32) {
    return getTrophyImageUrlByFileName("sensational64_c.png");
  } else if (numberOfMatches === 16) {
    return getTrophyImageUrlByFileName("thrilling32_c.png");
  } else if (numberOfMatches === 8) {
    return getTrophyImageUrlByFileName("sweet16_c.png");
  } else if (numberOfMatches === 4) {
    return getTrophyImageUrlByFileName("eliteEight_c.png");
  } else if (numberOfMatches === 2) {
    return getTrophyImageUrlByFileName("ferociousFour_c.png");
  } else if (numberOfMatches === 1) {
    return getTrophyImageUrlByFileName("gold_trophy1_c.png");
  } else {
    return getTrophyImageUrlByFileName("silver_trophy1_c.png");
  }
}
