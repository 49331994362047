import { useEffect, useState } from "react";
import MessageWithPreview from "./MessageWithPreview";
import {
  duelStates,
  stateNames,
} from "../../publicFunctions/publicHelpers/helpers";
import Duel from "./Duel";

function SecondStage({ battleInfo, loadedGameState, refreshGameState }) {
  const [loadedDuel, setLoadedDuel] = useState(null);
  const [notCompletedDuel, setNotCompletedDuel] = useState(false);
  const [isFirstDuel, setIsFirstDuel] = useState(false);

  useEffect(() => {
    // TODO: do we have to convert the unix dates to js?
    const { duel, notComplete, myOnlyDuel } =
      getTheNextReadyDuel(loadedGameState);
    setLoadedDuel(duel);
    setNotCompletedDuel(notComplete);
    setIsFirstDuel(myOnlyDuel);
  }, [loadedGameState]);

  const duelIsReadyToView =
    battleInfo.rawTuple.state === stateNames.FIRST_DUELS &&
    loadedDuel &&
    !notCompletedDuel;

  return (
    <div className="second-stage">
      {duelIsReadyToView ? (
        <Duel
          refreshGameState={refreshGameState}
          battleId={battleInfo.rawTuple.battleId}
          loadedDuel={loadedDuel}
        />
      ) : (
        <MessageWithPreview
          battleInfo={battleInfo}
          loadedGameState={loadedGameState}
          loadedDuel={loadedDuel}
          isFirstDuel={isFirstDuel}
          isOut={loadedGameState?.status?.outOfGame ? true : false}
        />
      )}
    </div>
  );
}

function getTheNextReadyDuel(loadedGameState) {
  if (
    !loadedGameState ||
    !loadedGameState.duels ||
    !loadedGameState.duels.length
  ) {
    return {
      duel: null,
      notComplete: false,
      myOnlyDuel: false,
    };
  }
  for (let i = 0; i < loadedGameState.duels.length; i++) {
    const duel = loadedGameState.duels[i];
    if (duel.state === duelStates.NOT_COMPLETED) {
      return {
        duel,
        notComplete: true,
        myOnlyDuel: loadedGameState.duels.length === 1,
      };
    } else if (duel.state === duelStates.READY) {
      return {
        duel,
        notComplete: false,
        myOnlyDuel: loadedGameState.duels.length === 1,
      };
    }
  }
  return {
    duel: null,
    notComplete: false,
    myOnlyDuel: false,
  };
}

export default SecondStage;
