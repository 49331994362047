import Reveal from "../animations/Reveal";
import PeerJudgedBattles from "../../img/home/PeerJudgedBattles.webp";
import ProJudgedBattles from "../../img/home/ProJudgedBattles.webp";
import HeartFace from "../../img/battleFaces/heartCrop.webp";
import WonderFace from "../../img/battleFaces/wonderCrop.webp";
import FearFace from "../../img/battleFaces/fearCrop.webp";
import WinterFace from "../../img/battleFaces/winterCrop.webp";
import SpringFace from "../../img/battleFaces/springCrop.webp";
import SummerFace from "../../img/battleFaces/summerCrop.webp";
import AutumnFace from "../../img/battleFaces/autumnCrop.webp";
import BattleTypeExplanation from "./BattleTypeExplanation";
import BattleFaceWrapper from "./BattleFaceWrapper";
import BattleDatesList from "./BattleDatesList";

function PeerVSPro({ battles }) {
  return (
    <div className="purple-background--lightest padding-top-bot--smaller">
      <div className="container margin-bottom-large">
        <Reveal type="fromBottom" rotate={true}>
          <span className="subheading">Two Types of Battles</span>
        </Reveal>
        <Reveal type="fromBottom" rotate={true}>
          <h2 className="heading-secondary margin-bottom-md">
            Peer-Judged and Pro-Judged Battles
          </h2>
        </Reveal>
        <div className="peer-vs-pro">
          <Reveal type="fromLeft" rotate={false}>
            <BattleTypeExplanation
              image={PeerJudgedBattles}
              title="Peer-Judged"
              listOfItems={
                <>
                  <li>
                    All writers read and judge 10 short stories in different
                    Genres than their own
                  </li>
                  <li>The Genres are kept secret until the Battle starts</li>
                  <li>10 bits of feedback by 10 anonymous peer judges</li>
                  <li>Top 8 stories are judged by authors and editors</li>
                </>
              }
              listOfBattleDescriptions={
                <>
                  <BattleFaceWrapper
                    faceImg={WinterFace}
                    title="Winter Battle"
                    words="1000"
                    body="three days to write"
                  />
                  <BattleFaceWrapper
                    faceImg={SpringFace}
                    title="Spring Battle"
                    words="500"
                    body="two days to write"
                  />
                  <BattleFaceWrapper
                    faceImg={SummerFace}
                    title="Summer Battle"
                    words="250"
                    body="two days to write"
                  />
                  <BattleFaceWrapper
                    faceImg={AutumnFace}
                    title="Autumn Battle"
                    words="2000"
                    body="seven days to write"
                  />
                </>
              }
            />
          </Reveal>
          <Reveal type="fromRight" rotate={false}>
            <BattleTypeExplanation
              image={ProJudgedBattles}
              title="Professionally-Judged"
              listOfItems={
                <>
                  <li>The entire tournament is judged professionally</li>
                  <li>The possible Genres are known beforehand</li>
                  <li>3 bits of feedback by 3 professional judges</li>
                  <li>Seven days to write a 1000 word story</li>
                  <li>Top 8 stories are judged by authors and editors</li>
                </>
              }
              listOfBattleDescriptions={
                <>
                  <BattleFaceWrapper
                    faceImg={HeartFace}
                    title="Heart Battle"
                    words="1000"
                    body="Drama, Romance, Rom-Com, Comedy"
                  />
                  <BattleFaceWrapper
                    faceImg={WonderFace}
                    title="Wonder Battle"
                    words="1000"
                    body="Sci-Fi, Fantasy, Fairy Tale, Adventure"
                  />
                  <BattleFaceWrapper
                    faceImg={FearFace}
                    title="Fear Battle"
                    words="1000"
                    body="Horror, Thriller, Suspense, Mystery"
                  />
                </>
              }
            />
          </Reveal>
        </div>
        <BattleDatesList battles={battles} />
      </div>
    </div>
  );
}

export default PeerVSPro;
