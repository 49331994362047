export function getTrophyImageUrlByFileName(fileName) {
  return (
    "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/trophyImages%2F" +
    fileName +
    "?alt=media"
  );
}

export function getCardImageUrlByCard(card, isTiny = false) {
  if (card.image) {
    // this is legacy, so it already has its url
    if (isTiny) {
      return card.image.tinyImage.url;
    } else {
      return card.image.image.url;
    }
  } else {
    const tinyModifier = isTiny ? "t" : "";
    return (
      "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/cardImages%2F" +
      card.imageFileName.replace("/", "%2F") +
      tinyModifier +
      ".webp?alt=media"
    );
  }
}

export function getFlagImageUrlByFlagFileName(flagFileName) {
  return (
    "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Flags%2F" +
    flagFileName +
    ".svg?alt=media"
  );
}

// https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/cardImages%2F00d14344-5907-420a-a441-3495f6753ec6%2FS21.webp?alt=media&token=fc528d50-f923-4a0b-a132-b1702847ddfe&_gl=1*c7crrw*_ga*NjU4NDMyMTcuMTY3ODcwNjk5OQ..*_ga_CW55HF8NVT*MTY5NzgyMTg4MS40NTQuMS4xNjk3ODI1MDkwLjUxLjAuMA..
