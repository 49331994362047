import "../styles/tarot.css";
import "../styles/tarotQueries.css";
import tarotTitle from "../img/tarot/battle_raven_tarot_v2.svg";
import { useEffect, useRef, useState } from "react";
import LargeTarotCard from "../components/tarot/LargeTarotCard";
import {
  TAROT_COOKIE_KEY,
  TAROT_PREVIEW_2,
  TAROT_PREVIEW_9,
  VINCENZO_PREVIEW_1,
  VINCENZO_PREVIEW_2,
  constructTarotUrl,
  getFreeTarotInfo,
} from "../publicFunctions/miscHandler";
import TarotMeaningsList from "../components/tarot/TarotMeaningsList";
import TarotMeaningsNav from "../components/tarot/TarotMeaningsNav";
import { pageScrollSettings } from "../publicFunctions/publicHelpers/helpers";
import SimpleCallToAction from "../components/preview/SimpleCallToAction";
import TarotPreview from "../components/tarot/TarotPreview";
import Home from "./Home";
import {
  getDataFromLocalStorageWithExpiry,
  saveDataToLocalStorageWithExpiry,
} from "../publicFunctions/localStorageHandler";
import SEO from "../components/SEO";

const NUMBER_OF_MAJOR_ARCANA = 22;
const NUMBER_OF_MINOR_ARCANA = 56;

function Tarot({ userInfo, battles, openLogInHandler, alreadyJoinedMap }) {
  const [randomCards, setRandomCards] = useState(null);
  const [renderedTarotCards, setRenderedTarotCards] = useState(null);
  const [flipped, setFlipped] = useState(false);
  const [tarotInfo, setTarotInfo] = useState(null);
  const [myTarotMeanings, setMyTarotMeanings] = useState(null);
  const [tarotMeaningKey, setTarotMeaningKey] = useState("none");
  const [tarotMeaningsList, setTarotMeaningsList] = useState(null);
  const myReadingRef = useRef(null);
  const meaningsRef = useRef(null);

  useEffect(() => {
    getFreeTarotInfo()
      .then((tempInfo) => {
        setTarotInfo(tempInfo);
      })
      .catch((err) => {
        console.log(err);
        // try again
        getFreeTarotInfo()
          .then((tempInfo) => {
            setTarotInfo(tempInfo);
          })
          .catch((err) => {
            console.log(err);
          });
      });

    // TODO: check if this user has already dealt cards today
    // and if so, then use those cards instead of dealing new ones.
    let majorCard;
    let minorCard1;
    let minorCard2;
    let majorCardReversed;
    let minorCard1Reversed;
    let minorCard2Reversed;

    const previousTarotReading =
      getDataFromLocalStorageWithExpiry(TAROT_COOKIE_KEY);
    if (previousTarotReading) {
      majorCard = previousTarotReading.majorCard;
      minorCard1 = previousTarotReading.minorCard1;
      minorCard2 = previousTarotReading.minorCard2;
      majorCardReversed = previousTarotReading.majorCardReversed;
      minorCard1Reversed = previousTarotReading.minorCard1Reversed;
      minorCard2Reversed = previousTarotReading.minorCard2Reversed;
      setFlipped(true);
    } else {
      majorCard = Math.floor(Math.random() * NUMBER_OF_MAJOR_ARCANA);
      minorCard1 = Math.floor(
        Math.random() * NUMBER_OF_MINOR_ARCANA + NUMBER_OF_MAJOR_ARCANA
      );

      minorCard2 = Math.floor(
        Math.random() * NUMBER_OF_MINOR_ARCANA + NUMBER_OF_MAJOR_ARCANA
      );
      while (minorCard2 === minorCard1) {
        minorCard2 = Math.floor(
          Math.random() * NUMBER_OF_MINOR_ARCANA + NUMBER_OF_MAJOR_ARCANA
        );
      }
      majorCardReversed = Math.random() >= 0.5 ? true : false;
      minorCard1Reversed = Math.random() >= 0.5 ? true : false;
      minorCard2Reversed = Math.random() >= 0.5 ? true : false;
      // save this to cookies
      saveDataToLocalStorageWithExpiry(
        TAROT_COOKIE_KEY,
        {
          majorCard,
          minorCard1,
          minorCard2,
          majorCardReversed,
          minorCard1Reversed,
          minorCard2Reversed,
        },
        true,
        false,
        true
      );
    }
    setRandomCards({
      majorCard,
      minorCard1,
      minorCard2,
      majorCardReversed,
      minorCard1Reversed,
      minorCard2Reversed,
    });
  }, []);

  useEffect(() => {
    if (randomCards) {
      const tempRendered = [];
      const majorUrl = constructTarotUrl(randomCards.majorCard);
      const minorUrl1 = constructTarotUrl(randomCards.minorCard1);
      const minorUrl2 = constructTarotUrl(randomCards.minorCard2);
      tempRendered.push(
        <LargeTarotCard
          key={randomCards.majorCard.toString(10)}
          imgUrl={majorUrl}
          reversed={randomCards.majorCardReversed}
          isMajor={true}
          flipped={flipped}
          position={0}
        />
      );
      tempRendered.push(
        <LargeTarotCard
          key={randomCards.minorCard1.toString(10)}
          imgUrl={minorUrl1}
          reversed={randomCards.minorCard1Reversed}
          flipped={flipped}
          position={1}
        />
      );
      tempRendered.push(
        <LargeTarotCard
          key={randomCards.minorCard2.toString(10)}
          imgUrl={minorUrl2}
          reversed={randomCards.minorCard2Reversed}
          flipped={flipped}
          position={2}
        />
      );

      setRenderedTarotCards(tempRendered);
      if (flipped && myReadingRef?.current) {
        myReadingRef.current.scrollIntoView(pageScrollSettings);
      }
    }
  }, [randomCards, flipped]);

  useEffect(() => {
    if (tarotInfo && randomCards) {
      const major = JSON.parse(
        JSON.stringify(tarotInfo.cards[randomCards.majorCard])
      );
      major.reversed = randomCards.majorCardReversed;
      const minor1 = JSON.parse(
        JSON.stringify(tarotInfo.cards[randomCards.minorCard1])
      );
      minor1.reversed = randomCards.minorCard1Reversed;
      const minor2 = JSON.parse(
        JSON.stringify(tarotInfo.cards[randomCards.minorCard2])
      );
      minor2.reversed = randomCards.minorCard2Reversed;
      setMyTarotMeanings([major, minor1, minor2]);
    }
  }, [tarotInfo, randomCards]);

  useEffect(() => {
    if (tarotMeaningKey !== "none" && tarotInfo) {
      let tempTarotList;
      if (tarotMeaningKey === "major") {
        tempTarotList = tarotInfo.cards.slice(0, 22);
      } else if (tarotMeaningKey === "wands") {
        tempTarotList = tarotInfo.cards.slice(22, 36);
      } else if (tarotMeaningKey === "cups") {
        tempTarotList = tarotInfo.cards.slice(36, 50);
      } else if (tarotMeaningKey === "swords") {
        tempTarotList = tarotInfo.cards.slice(50, 64);
      } else if (tarotMeaningKey === "coins") {
        tempTarotList = tarotInfo.cards.slice(64, 78);
      }
      if (tempTarotList?.length) {
        setTarotMeaningsList(tempTarotList);
      }
    } else {
      setTarotMeaningsList(null);
    }
  }, [tarotMeaningKey, tarotInfo]);

  const handleListChange = (key) => {
    if (key) {
      setTarotMeaningKey(key);
    }
  };

  return (
    <div>
      <SEO
        title="Tarot | Writing Battle"
        description={
          "Your free daily tarot card reading and all tarot meanings for " +
          "major arcana, wands, cups, swords, and coins (pentacles)."
        }
        name="Writing Battle"
        type="website"
      />
      <div className="tarot-background">
        <div className="tarot-title--background flex justify-content--center">
          <img className="tarot-title" src={tarotTitle} />
        </div>
        <div className="container flex justify-content--end">
          <button
            onClick={(event) => {
              event.preventDefault();
              if (meaningsRef?.current) {
                meaningsRef.current.scrollIntoView();
              }
            }}
            className="btn btn--outline"
          >
            Go to meanings &darr;
          </button>
        </div>
        <p className="container description--larger center-text bold underline margin-top-smaller">
          Your Free Daily Tarot Card Reading
        </p>
        <div
          onClick={(event) => {
            event.preventDefault();
            setFlipped(true);
          }}
          className="tarot-list-wrapper tarot-button-wrapper"
          style={{ cursor: flipped ? "auto" : "pointer" }}
        >
          <div ref={myReadingRef} className="tarot-list">
            {renderedTarotCards}
          </div>
          {!flipped ? (
            <div role="button" className="card-collapse-button center-text">
              <div className="btn btn--outline">
                <p className="description bold">Free Tarot Reading</p>
              </div>
            </div>
          ) : null}
        </div>
        {myTarotMeanings && flipped ? (
          <TarotMeaningsList list={myTarotMeanings} orientation={true} />
        ) : null}
      </div>
      {tarotInfo ? (
        <div className="purple-background--lighter">
          <SimpleCallToAction
            imageUrl1={TAROT_PREVIEW_9}
            imageUrl2={TAROT_PREVIEW_2}
            buttonText={"Buy Now"}
            titleText={"Order your Battle Raven Tarot deck today!"}
            goToLink={tarotInfo.onlineStoreURL}
          />
        </div>
      ) : null}
      {tarotInfo ? (
        <TarotMeaningsNav
          handleListChange={handleListChange}
          listKey={tarotMeaningKey}
          meaningsRef={meaningsRef}
        />
      ) : null}
      {tarotMeaningsList ? (
        <div className="purple-background--lightest">
          <TarotMeaningsList list={tarotMeaningsList} orientation={false} />
        </div>
      ) : null}
      {tarotInfo?.vincenzoOnlineStoreURL ? (
        <div className="purple-background--lighter">
          <SimpleCallToAction
            imageUrl1={VINCENZO_PREVIEW_1}
            imageUrl2={VINCENZO_PREVIEW_2}
            titleText={"Art by Vincenzo Ingenito"}
            buttonText={"Commission Art"}
            bodyText={
              "Experience the rich and intricate artwork crafted by the Italian tattoo artist and illustrator, Vincenzo Ingenito."
            }
            goToLink={tarotInfo.vincenzoOnlineStoreURL}
          />
        </div>
      ) : null}
      {tarotInfo ? <TarotPreview goToLink={tarotInfo.onlineStoreURL} /> : null}
      <Home
        battles={battles}
        openLogInHandler={openLogInHandler}
        userInfo={userInfo}
        alreadyJoinedMap={alreadyJoinedMap}
        removeSEO={true}
      />
    </div>
  );
}

export default Tarot;
