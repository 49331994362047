import "../styles/debrief.css";
import "../styles/debriefQueries.css";
import { useEffect, useState } from "react";
import BasicNav from "../components/BasicNav";
import LoadingSection from "../components/LoadingSection";
import {
  getBattleFeedback,
  getDebriefMeta,
  getUploadedStory,
} from "../publicFunctions/debriefHandler";
import { useSearchParams } from "react-router-dom";
import DebriefBodyNav from "../components/debrief/DebriefBodyNav";
import DebriefHouseSelector from "../components/debrief/DebriefHouseSelector";
import DebriefHouse from "../components/debrief/DebriefHouse";
import { getGameState } from "../publicFunctions/gameHandler";
import DebriefStories from "../components/debrief/DebriefStories";
import PricingSection from "../components/PricingSection";
import DebriefFinalShowdown from "../components/debrief/DebriefFinalShowdown";
import DebriefFeedback from "../components/debrief/DebriefFeedback";
import { NOT_ACTIVE } from "../publicFunctions/publicHelpers/standardErrorMessages";
import SEO from "../components/SEO";
import PagePreview from "../components/preview/PagePreview";
import CardCollection from "../components/debrief/CardCollection";
import Raids from "../components/debrief/Raids";
import UploadTavernStory from "../components/debrief/UploadTavernStory";
import {
  getCardCollection,
  getMyTavernCardsObject,
} from "../publicFunctions/tavernHandler";
import PromptGenerator from "../components/debrief/PromptGenerator";
import BattleHeading from "../components/BattleHeading";

const debriefYearOptions = [
  {
    label: "2024",
    value: "2024",
  },
  {
    label: "2023",
    value: "2023",
  },
  {
    label: "2022",
    value: "2022",
  },
  {
    label: "2021",
    value: "2021",
  },
];

const debriefBattleOptions2024 = [
  {
    label: "Select Battle",
    value: "",
  },
  {
    label: "Summer Nanofiction",
    value: "aug2024",
  },
  {
    label: "Spring Microfiction",
    value: "may2024",
  },
  {
    label: "Winter Flash Fiction",
    value: "feb2024",
  },
];

const debriefBattleOptions2023 = [
  {
    label: "Select Battle",
    value: "",
  },
  {
    label: "Autumn Short Story",
    value: "nov2023",
  },
  {
    label: "Summer Nanofiction",
    value: "aug2023",
  },
  {
    label: "Spring Microfiction",
    value: "may2023",
  },
  {
    label: "Winter Flash Fiction",
    value: "feb2023",
  },
];

const debriefBattleOptions2022 = [
  {
    label: "Select Battle",
    value: "",
  },
  {
    label: "Autumn Short Story",
    value: "nov2022",
  },
  {
    label: "Summer Flash Fiction",
    value: "aug2022",
  },
  {
    label: "May Screenwriting",
    value: "may2022",
  },
  {
    label: "February Flash Fiction",
    value: "feb2022",
  },
];

const debriefBattleOptions2021 = [
  {
    label: "Select Battle",
    value: "",
  },
  {
    label: "November Screenwriting",
    value: "nov2021",
  },
  {
    label: "May Screenwriting",
    value: "may2021",
  },
  {
    label: "March Screenwriting",
    value: "mar2021",
  },
  {
    label: "February Screenwriting",
    value: "feb2021",
  },
];

function getOptionsByYear(year) {
  if (year === "2024") {
    return debriefBattleOptions2024;
  } else if (year === "2023") {
    return debriefBattleOptions2023;
  } else if (year === "2022") {
    return debriefBattleOptions2022;
  } else if (year === "2021") {
    return debriefBattleOptions2021;
  } else {
    return debriefBattleOptions2024;
  }
}

function Debrief({
  battles,
  userInfo,
  myProfile,
  openLogInHandler,
  alreadyJoinedMap,
  isTavern,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedBattleId, setSelectedBattleId] = useState("");
  const [yearOptions, setYearOptions] = useState([]);
  const [battleOptions, setBattleOptions] = useState([]);
  const [myStory, setMyStory] = useState(null);
  const [myUploadedStory, setMyUploadedStory] = useState("");
  const [myDuels, setMyDuels] = useState([]);
  const [storiesIJudged, setStoriesIJudged] = useState(new Map());
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [feedbackArray, setFeedbackArray] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [collectionMeta, setCollectionMeta] = useState(null);
  const [myCards, setMyCards] = useState(null);
  // loaded story will be null if not editing/working on a story
  // if person is working on an unsaved story, then it will just be {storyId: ""}
  // otherwise, it will be the LAST SAVED uploadedStories file
  const [loadedStory, setLoadedStory] = useState(null);

  const [loadedDebriefMeta, setLoadedDebriefMeta] = useState(null);
  const [page, setPage] = useState(
    searchParams.has("page") ? searchParams.get("page") : "stories"
  );

  const requestCollectionMeta = async () => {
    if (!collectionMeta) {
      const tempCollectionMeta = await getCardCollection();
      if (tempCollectionMeta) {
        setCollectionMeta(tempCollectionMeta);
      }
    }
  };

  const requestMyCards = async (userId, override = false) => {
    if ((!myCards || override) && userId) {
      const tempCards = await getMyTavernCardsObject(userId);
      setMyCards(tempCards);
    }
  };

  // Do this once and only once
  // this sets the Battle options
  useEffect(() => {
    const tempBattleIdOptions = [];
    let battleId = "";
    if (searchParams) {
      const tempParams = searchParams;
      if (
        tempParams.has("battleId") &&
        tempParams.get("battleId") !== selectedBattleId
      ) {
        battleId = tempParams.get("battleId");
      }
    }
    let battleFound = false;
    const debriefBattleOptions = getOptionsByYear(
      battleId ? battleId.slice(-4) : ""
    );
    for (let i = 0; i < debriefBattleOptions.length; i++) {
      const tempBattle = debriefBattleOptions[i];
      if (battleId && tempBattle.value === battleId) {
        setSelectedBattleId(battleId);
        setSelectedYear(battleId.slice(-4));
        battleFound = true;
      }
      tempBattleIdOptions.push(
        <option key={i.toString(10)} value={tempBattle.value}>
          {tempBattle.label}
        </option>
      );
    }
    setBattleOptions(tempBattleIdOptions);
    if (isTavern && !battleFound) {
      const tempBattleId = "tavern";
      setSelectedBattleId(tempBattleId);
    } else if (!battleFound) {
      const tempBattleId = debriefBattleOptions[1].value;
      setSelectedBattleId(tempBattleId);
      setSelectedYear(tempBattleId.slice(-4));
    }

    // now set the year options
    const tempYearOptions = [];
    for (let i = 0; i < debriefYearOptions.length; i++) {
      const tempYear = debriefYearOptions[i];
      tempYearOptions.push(
        <option key={i.toString(10)} value={tempYear.value}>
          {tempYear.label}
        </option>
      );
    }
    setYearOptions(tempYearOptions);
  }, []);

  useEffect(() => {
    if (selectedYear) {
      const tempBattleIdOptions = [];
      const options = getOptionsByYear(selectedYear);
      for (let i = 0; i < options.length; i++) {
        const tempBattle = options[i];
        tempBattleIdOptions.push(
          <option key={i.toString(10)} value={tempBattle.value}>
            {tempBattle.label}
          </option>
        );
      }
      setBattleOptions(tempBattleIdOptions);
    }
  }, [selectedYear]);

  // Now that we have loaded the battle, then load the meta
  useEffect(() => {
    if (
      selectedBattleId &&
      !userInfo?.isStillCheckingLogin() &&
      userInfo?.isLoggedIn()
    ) {
      getDebriefMeta(selectedBattleId)
        .then((metaData) => {
          let metaDataAlreadyLoaded = loadedDebriefMeta ? true : false;
          setLoadedDebriefMeta(metaData);
          const tempSearchParams = searchParams;
          tempSearchParams.set("battleId", selectedBattleId);
          if (metaDataAlreadyLoaded) {
            // we want to go back to the stories
            // in case we are on the FS, feedback, or houses of another battle
            tempSearchParams.set("page", "stories");
          } else if (page) {
            tempSearchParams.set("page", page);
          } else {
            tempSearchParams.set("page", "stories");
          }

          if (metaDataAlreadyLoaded) {
            if (tempSearchParams.has("index")) {
              tempSearchParams.delete("index");
            }
            if (tempSearchParams.has("commentNumberIndex")) {
              tempSearchParams.delete("commentNumberIndex");
            }
            if (tempSearchParams.has("filterBy")) {
              tempSearchParams.delete("filterBy");
            }
            if (tempSearchParams.has("sortBy")) {
              tempSearchParams.delete("sortBy");
            }
            if (tempSearchParams.has("num")) {
              tempSearchParams.delete("num");
            }
            if (tempSearchParams.has("fsGenre")) {
              tempSearchParams.delete("fsGenre");
            }
            if (tempSearchParams.has("houseId")) {
              tempSearchParams.delete("houseId");
            }
            if (tempSearchParams.has("search")) {
              tempSearchParams.delete("search");
            }
            if (tempSearchParams.has("searchType")) {
              tempSearchParams.delete("searchType");
            }
          }
          setSearchParams(tempSearchParams);
          setIsLoading(false);
          setMessage("");
        })
        .catch((err) => {
          setIsLoading(false);
          if (
            err?.message?.includes("insufficient permissions") &&
            userInfo?.isLoggedIn()
          ) {
            setMessage(NOT_ACTIVE);
            setShowPreview(true);
          } else {
            console.log(err);
            setMessage("Something went wrong. Please try refreshing the page.");
          }
        });
    } else if (!userInfo?.isStillCheckingLogin() && !userInfo?.isLoggedIn()) {
      setMessage("Please log in to read stories from the Debrief of a Battle.");
      setShowPreview(true);
      setIsLoading(false);
      openLogInHandler("login");
    }
  }, [selectedBattleId, userInfo]);

  // Find my story (if one exists)
  useEffect(() => {
    if (selectedBattleId && myProfile && !isTavern) {
      const uploads = myProfile.uploads;
      let found = false;
      if (uploads?.length) {
        for (let i = 0; i < uploads.length; i++) {
          const clientizedUpload = uploads[i];
          if (clientizedUpload.battleId === selectedBattleId) {
            setMyStory(JSON.parse(JSON.stringify(clientizedUpload)));
            found = true;
            break;
          }
        }
      }
      if (!found) {
        setMyStory(null);
      }
    }
  }, [selectedBattleId, myProfile]);

  // if we have a story, then get the gamestate for this battle
  // we need the gamestate to know which duels we're doing
  useEffect(() => {
    if (
      myStory?.battleId &&
      !userInfo?.isStillCheckingLogin() &&
      userInfo?.isLoggedIn()
    ) {
      getGameState(myStory.battleId)
        .then((result) => {
          if (result) {
            const duels = result.duels;
            if (duels?.length) {
              const tempMyDuels = [];
              const tempStoriesIJudged = new Map();
              // iterate through duelIds and set them as state
              for (let i = 0; i < duels.length; i++) {
                tempMyDuels.push({
                  houseId: duels[i].houseId,
                  duelId: duels[i].duelId,
                });
                tempStoriesIJudged.set(duels[i].storyIdA, true);
                tempStoriesIJudged.set(duels[i].storyIdB, true);
              }
              setMyDuels(tempMyDuels);
              setStoriesIJudged(tempStoriesIJudged);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
      getUploadedStory(myStory.storyId)
        .then((result) => {
          if (result) {
            setMyUploadedStory(result);
          } else {
            setMyUploadedStory(null);
          }
        })
        .catch((err) => {
          setMyUploadedStory(null);
          console.log(err);
        });
      getBattleFeedback(myStory.storyId)
        .then((result) => {
          if (result) {
            setFeedbackArray(result.feedback);
          } else {
            setFeedbackArray(null);
          }
        })
        .catch((err) => {
          console.log(err);
          setFeedbackArray(null);
        });
    } else if (
      !myStory &&
      !userInfo?.isStillCheckingLogin() &&
      userInfo?.isLoggedIn()
    ) {
      setMyDuels([]);
      setMyUploadedStory(null);
      setFeedbackArray(null);
    }
  }, [myStory, userInfo]);

  useEffect(() => {
    const tempPage =
      searchParams && searchParams.has("page") ? searchParams.get("page") : "";
    if (tempPage !== page && tempPage !== "create" && loadedStory) {
      setLoadedStory(null);
    }
    if (tempPage && tempPage !== page) {
      setPage(tempPage);
    }
  }, [searchParams]);

  useEffect(() => {
    setForceRefresh(!forceRefresh);
  }, [loadedDebriefMeta]);

  useEffect(() => {
    let currentlyOnCreatePage =
      searchParams.has("page") && searchParams.get("page") === "create"
        ? true
        : false;
    if (loadedStory?.raidObject && !currentlyOnCreatePage) {
      const tempParams = searchParams;
      tempParams.set("page", "create");
      setSearchParams(tempParams);
    }
  }, [loadedStory]);

  const selectedBattleHandler = (event) => {
    if (event?.target?.value) {
      setSelectedBattleId(event.target.value);
    }
  };

  const selectedYearHandler = (event) => {
    if (event?.target?.value) {
      setSelectedYear(event.target.value);
    }
  };

  const selectPageHandler = (pageToSelect) => {
    if (!pageToSelect) return;
    const params = searchParams;
    params.set("page", pageToSelect);
    if (pageToSelect === "houses") {
      // clear the page that we were on
      if (params.has("index")) {
        params.delete("index");
      }
      if (params.has("num")) {
        params.delete("num");
      }
    }
    setSearchParams(params);
  };

  const houseSelectedHandler = (houseIdSelected) => {
    if (!houseIdSelected) return;
    const params = searchParams;
    params.set("houseId", houseIdSelected);
    setSearchParams(params);
  };

  const goBackHandler = () => {
    const params = searchParams;
    params.delete("houseId");
    setSearchParams(params);
  };

  const refreshMyUploadedStory = (updatedUploadedStory) => {
    setMyUploadedStory(updatedUploadedStory);
  };

  return (
    <div>
      <SEO
        title={
          isTavern ? "Tavern | Writing Battle" : "Debrief | Writing Battle"
        }
        description="Share your story and get even more feedback!"
        name="Writing Battle"
        type="website"
      />
      <div className="hero-background">
        {!isLoading && !message ? (
          <div>
            <BasicNav
              battleOptions={battleOptions}
              selectedBattle={selectedBattleId}
              selectedBattleHandler={selectedBattleHandler}
              title={isTavern ? "Tavern" : "Debrief"}
              yearOptions={yearOptions}
              selectedYear={selectedYear}
              selectedYearHandler={selectedYearHandler}
              hideNav={isTavern}
            />
            {loadedDebriefMeta && !isTavern ? (
              <BattleHeading
                battleId={loadedDebriefMeta.battleId}
                battleTitle={loadedDebriefMeta.battleTitle.replace(
                  "Battle",
                  "Debrief"
                )}
              />
            ) : null}
            {loadedDebriefMeta ? (
              <div>
                <DebriefBodyNav
                  selectPageHandler={selectPageHandler}
                  selectedPage={page}
                  showFinalShowdown={
                    loadedDebriefMeta?.finalShowdown ? true : false
                  }
                  showHouses={loadedDebriefMeta?.houses ? true : false}
                  showFeedback={myUploadedStory && feedbackArray ? true : false}
                  isTavern={isTavern}
                />
                {page === "stories" ? (
                  <DebriefStories
                    key={forceRefresh}
                    debriefMeta={loadedDebriefMeta}
                    stalePaginationArray={loadedDebriefMeta.pagination}
                    myUploadedStory={myUploadedStory}
                    myProfile={myProfile}
                    refreshMyUploadedStory={refreshMyUploadedStory}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                  />
                ) : page === "houses" && !searchParams.has("houseId") ? (
                  <DebriefHouseSelector
                    debriefMeta={loadedDebriefMeta}
                    houseSelectedHandler={houseSelectedHandler}
                    myStory={myStory}
                    myDuels={myDuels}
                  />
                ) : page === "houses" && searchParams.has("houseId") ? (
                  <DebriefHouse
                    debriefMeta={loadedDebriefMeta}
                    houseId={searchParams.get("houseId")}
                    goBackHandler={goBackHandler}
                    myStory={myStory}
                    myDuels={myDuels}
                    storiesIJudged={storiesIJudged}
                  />
                ) : page === "finalShowdown" ? (
                  <DebriefFinalShowdown
                    debriefMeta={loadedDebriefMeta}
                    myStory={myStory}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                  />
                ) : page === "feedback" ? (
                  <DebriefFeedback
                    myProfile={myProfile}
                    feedbackArray={feedbackArray}
                  />
                ) : page === "collection" ? (
                  <CardCollection
                    myProfile={myProfile}
                    requestCollectionMeta={requestCollectionMeta}
                    requestMyCards={requestMyCards}
                    collectionMeta={collectionMeta}
                    myCards={myCards}
                  />
                ) : page === "raids" ? (
                  <Raids
                    myProfile={myProfile}
                    myCards={myCards}
                    debriefMeta={loadedDebriefMeta}
                    setLoadedStory={setLoadedStory}
                    requestMyCards={requestMyCards}
                  />
                ) : page === "create" ? (
                  <UploadTavernStory
                    myProfile={myProfile}
                    requestCollectionMeta={requestCollectionMeta}
                    requestMyCards={requestMyCards}
                    collectionMeta={collectionMeta}
                    myCards={myCards}
                    loadedStory={loadedStory}
                    setLoadedStory={setLoadedStory}
                  />
                ) : page === "generator" ? (
                  <PromptGenerator
                    myProfile={myProfile}
                    requestCollectionMeta={requestCollectionMeta}
                    requestMyCards={requestMyCards}
                    collectionMeta={collectionMeta}
                    myCards={myCards}
                    setLoadedStory={setLoadedStory}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        ) : isLoading ? (
          <LoadingSection />
        ) : (
          // not logged in or do not have permissions
          <div>
            <p className="description--larger center-text debrief-message max-width--readable-center--larger">
              {message}
            </p>
            {showPreview ? (
              <PagePreview
                userInfo={userInfo}
                openLoginHandler={openLogInHandler}
                hideLogInButtons={false}
                animated={false}
                isActive={message?.includes("must have joined") ? false : true}
                page={isTavern ? "Tavern" : "Debrief"}
              />
            ) : null}
          </div>
        )}

        <PricingSection
          battles={battles}
          hideTitle={true}
          openLogInHandler={openLogInHandler}
          userInfo={userInfo}
          alreadyJoinedMap={alreadyJoinedMap}
        />
      </div>
    </div>
  );
}

export default Debrief;
