import { IonIcon } from "@ionic/react";
import {
  arrowBackCircleOutline,
  arrowForwardCircleOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";

function BasicPageNav({
  pageNum,
  noMorePages,
  incrementPageHandler,
  decrementPageHandler,
  goToPageHandler,
  isWorking,
  showPageDropdown,
  pagination,
  forceRefreshNav,
  alsoIndexingComments,
  searchList,
  searchIncrement,
  searchDecrement,
}) {
  const [renderedPageDropdown, setRenderedPageDropdown] = useState(null);
  const [pageDropdownSelection, setPageDropdownSelection] = useState("");
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (showPageDropdown && pagination?.size) {
      const tempRender = [];

      for (let i = 1; i <= pagination.size; i++) {
        const page = i.toString(10);
        if (i === 1 || pagination.has(page)) {
          if (!alsoIndexingComments) {
            const index = i === 1 ? "none" : pagination.get(page);
            tempRender.push(
              <option key={i} value={page + "_" + index}>
                {page}
              </option>
            );
          } else {
            const index = i === 1 ? "none" : pagination.get(page).index;
            const commentNumberIndex =
              i === 1 ? "none" : pagination.get(page).commentNumberIndex;
            tempRender.push(
              <option
                key={i}
                value={page + "_" + index + "_" + commentNumberIndex}
              >
                {page}
              </option>
            );
          }
        } else {
          break;
        }
      }
      // We don't want to show the dropdown with only one page
      if (tempRender.length > 1) {
        setRenderedPageDropdown(tempRender);
      } else {
        setRenderedPageDropdown(null);
      }
      setTotalPages(pagination.size);
      // if (pagination?.size > totalPages) {
      // }
    }
  }, [pagination, forceRefreshNav]);

  useEffect(() => {
    if (pagination && pageNum) {
      const pageString = pageNum.toString(10);
      if (pagination.has(pageString)) {
        if (!alsoIndexingComments) {
          const index = pagination.get(pageString);

          let valueToCheck = pageString + "_" + index;
          if (renderedPageDropdown?.length) {
            valueToCheck = checkRenderedOptionsForKey(valueToCheck, pageString);
          }

          setPageDropdownSelection(valueToCheck);
        } else {
          const index = pagination.get(pageString).index;
          const commentNumberIndex =
            pagination.get(pageString).commentNumberIndex;

          let valueToCheck =
            pageString + "_" + index + "_" + commentNumberIndex;

          if (renderedPageDropdown?.length) {
            valueToCheck = checkRenderedOptionsForKey(valueToCheck, pageString);
          }

          setPageDropdownSelection(valueToCheck);
        }
      }
    }
    if ((!pagination || pagination?.size < pageNum) && pageNum > totalPages) {
      setTotalPages(pageNum);
    }
  }, [pageNum]);

  function checkRenderedOptionsForKey(key, backupKey) {
    let backupValue;
    if (renderedPageDropdown?.length) {
      for (let i = 0; i < renderedPageDropdown.length; i++) {
        const rendered = renderedPageDropdown[i];
        if (rendered?.props?.value && rendered.props.value === key) {
          return key;
        } else if (rendered?.props?.value) {
          const splitValue = rendered.props.value.split("_");
          if (splitValue.length >= 1 && splitValue[0] === backupKey) {
            backupValue = rendered.props.value;
          }
        }
      }
    }
    if (!backupValue) {
      backupValue = key;
    }
    return backupValue;
  }

  const handlePageDropdownSelect = (event) => {
    setPageDropdownSelection(event.target.value);
    const valueSplit = event.target.value.split("_");
    const page = valueSplit[0];
    const index = valueSplit[1];
    if (!alsoIndexingComments) {
      goToPageHandler(page, index);
    } else {
      const commentNumberIndex = valueSplit[2];
      goToPageHandler(page, index, commentNumberIndex);
    }
  };

  let searchCurrentPage;
  let searchMaxPages;
  if (searchList) {
    // indexToStart,
    // size: arrayOfDocs.length,
    // pageSize: DEFAULT_STORIES_LIST_SIZE,
    searchCurrentPage =
      Math.floor((searchList.indexToStart + 1) / searchList.pageSize) + 1;

    if (searchList.size <= searchList.pageSize) {
      searchMaxPages = 1;
    } else {
      searchMaxPages = Math.floor(searchList.size / searchList.pageSize) + 1;
    }
  }

  return (
    <div className="flex flex--gap-small align-items--center justify-content--end margin-top-bigger">
      {renderedPageDropdown && !searchList && pageNum ? (
        <select
          value={pageDropdownSelection}
          className="join-battle-select page-dropdown"
          onChange={handlePageDropdownSelect}
        >
          {renderedPageDropdown}
        </select>
      ) : null}
      {(pageNum > 1 || (searchCurrentPage && searchCurrentPage !== 1)) &&
      !isWorking ? (
        <div
          onClick={searchList ? searchDecrement : decrementPageHandler}
          style={{ cursor: "pointer" }}
        >
          <IonIcon className="social-icon" icon={arrowBackCircleOutline} />
        </div>
      ) : (
        <div className="social-icon"></div>
      )}
      <p className="description">
        {(searchCurrentPage || pageNum ? "Page " : "") +
          (searchCurrentPage && !isNaN(searchCurrentPage)
            ? searchCurrentPage.toString(10)
            : pageNum && !isNaN(pageNum)
            ? pageNum.toString(10)
            : "") +
          ((pageNum && totalPages && pagination?.size) || searchMaxPages
            ? " of " +
              (searchMaxPages
                ? searchMaxPages.toString(10)
                : totalPages.toString(10))
            : "")}
      </p>
      {((!noMorePages && !searchList) ||
        (searchList && searchCurrentPage < searchMaxPages)) &&
      !isWorking ? (
        <div
          onClick={searchList ? searchIncrement : incrementPageHandler}
          style={{ cursor: "pointer" }}
        >
          <IonIcon className="social-icon" icon={arrowForwardCircleOutline} />
        </div>
      ) : (
        <div className="social-icon"></div>
      )}
    </div>
  );
}

export default BasicPageNav;
