import { useNavigate } from "react-router-dom";
import Participant1 from "../../img/participants/participant1_128.jpg";
import Participant3 from "../../img/participants/participant3_128.jpg";
import Participant4 from "../../img/participants/participant4_128.jpg";
import Participant5 from "../../img/participants/participant5_128.jpg";
import Participant6 from "../../img/participants/participant6_128.jpg";
import Participant7 from "../../img/participants/participant7_128.jpg";
import Reveal from "../animations/Reveal";
import {
  AUTUMN,
  SPRING,
  SUMMER,
  WINTER,
  getBattleSwatchByDate,
} from "../../publicFunctions/publicHelpers/homeHelper";
import { useState } from "react";

function FirstCTA({ isUnderHero, userInfo, openLogInHandler, now }) {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const colorSwatch = getBattleSwatchByDate(now);

  return (
    <Reveal type="fromBottom" fadeIn={true}>
      <div className={isUnderHero ? "cta-under-hero" : "cta-on-hero"}>
        <p className="hero-description--landscape">
          A competitive writing platform for storytellers around the world. Join
          our growing community!
        </p>
        <div className="first-cta-buttons">
          <button
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
            style={{
              backgroundColor: hover
                ? colorSwatch.colorDarkest
                : colorSwatch.color,
            }}
            onClick={
              !userInfo.isLoggedIn()
                ? () => {
                    openLogInHandler("register");
                    navigate("/join-battle");
                  }
                : () => navigate("/join-battle")
            }
            className={"btn btn--full margin-right-sm"}
          >
            Join the Battle
          </button>
          <button
            onClick={(event) => {
              event.preventDefault();
              const section = document.querySelector("#howItWorks");
              section.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }}
            className="btn btn--outline"
          >
            Learn more &darr;
          </button>
        </div>
        <div className="current-participants">
          <div className="participant-imgs">
            <img
              className="participant-img participant-img--black-border"
              src={Participant1}
              alt="Participant photo"
            />
            <img
              className="participant-img participant-img--black-border"
              src={Participant7}
              alt="Participant photo"
            />
            <img
              className="participant-img participant-img--black-border"
              src={Participant3}
              alt="Participant photo"
            />
            <img
              className="participant-img participant-img--black-border"
              src={Participant4}
              alt="Participant photo"
            />
            <img
              className="participant-img participant-img--black-border"
              src={Participant5}
              alt="Participant photo"
            />
            <img
              className="participant-img participant-img--black-border"
              src={Participant6}
              alt="Participant photo"
            />
          </div>
          <p className="participants-text">
            The <span>coolest</span> and most <span>supportive</span> writing
            community ever.
          </p>
        </div>
      </div>
    </Reveal>
  );
}

export default FirstCTA;
