import { useEffect, useState } from "react";
import NotificationItem from "./NotificationItem";

function NotificationList({ notificationObject }) {
  const [renderedList, setRenderedList] = useState(null);

  useEffect(() => {
    if (notificationObject?.notificationsList?.length) {
      const dateOfFirst = notificationObject.dateOfFirstNewNotification
        ? notificationObject.dateOfFirstNewNotification
        : new Date();
      const TWO_MINUTES = 120000;
      const notificationsList = notificationObject.notificationsList;
      const theseAreBrandNew = notificationObject.newNotifications;
      const tempRender = [];
      const documentIdToArrayOfNewNotifications = new Map();
      const documentIdToArrayOfOldNotifications = new Map();

      // Alright, great! Now let's separate the new stuff from the old stuff

      // first loop to separate them
      for (let i = 0; i < notificationsList.length; i++) {
        const notification = notificationsList[i];
        let mapToUse;
        if (
          theseAreBrandNew &&
          dateOfFirst.getTime() - notification.date.getTime() < TWO_MINUTES
        ) {
          // new
          mapToUse = documentIdToArrayOfNewNotifications;
        } else {
          // old
          mapToUse = documentIdToArrayOfOldNotifications;
        }

        // get key
        let key = notification.documentId;
        if (notification.type === "forumMessage" && notification.isTaggedUser) {
          key += "_" + notification.userId;
        }

        // set map
        if (mapToUse.has(key)) {
          const array = mapToUse.get(key);
          array.push(notification);
        } else {
          mapToUse.set(key, [notification]);
        }
      }

      const newArray = Array.from(documentIdToArrayOfNewNotifications.values());
      const oldArray = Array.from(documentIdToArrayOfOldNotifications.values());

      // next to render new
      for (let i = 0; i < newArray.length; i++) {
        const notification = joinListIntoNewNotificationObject(newArray[i]);
        tempRender.push(
          <NotificationItem
            key={notification.id}
            notificationObj={notification}
            isNew={theseAreBrandNew}
          />
        );
      }
      // then to reader old
      for (let i = 0; i < oldArray.length; i++) {
        const notification = joinListIntoNewNotificationObject(oldArray[i]);
        tempRender.push(
          <NotificationItem
            key={notification.id}
            notificationObj={notification}
            isNew={false}
          />
        );
      }

      setRenderedList(tempRender);
    }
  }, [notificationObject]);

  return renderedList ? (
    <div className="notification-list">{renderedList}</div>
  ) : null;
}

function joinListIntoNewNotificationObject(notificationArray) {
  if (!notificationArray || !notificationArray.length) {
    return null;
  } else if (notificationArray.length === 1) {
    return notificationArray[0];
  } else {
    // this is a list that we have to join
    const mainItem = notificationArray[0];

    // right now, we just want to check the name
    let newName = mainItem.name;
    if (notificationArray.length === 2) {
      if (!mainItem.name.includes(notificationArray[1].name)) {
        newName = mainItem.name + " and " + notificationArray[1].name;
      }
    } else {
      if (!mainItem.name.includes(notificationArray[1].name)) {
        newName =
          mainItem.name + ", " + notificationArray[1].name + ", and others";
      } else {
        newName = mainItem.name + " and others";
      }
    }
    mainItem.name = newName;
    return mainItem;
  }
}

export default NotificationList;
