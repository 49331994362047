import { IonIcon } from "@ionic/react";
import { numberWithCommas } from "../../publicFunctions/publicHelpers/helpers";
import { cashOutline } from "ionicons/icons";

function Prizes({ battleInfo, isLegacy }) {
  return (
    <div className="prompts-section margin--none green-background">
      <div className="flex justify-content--center flex--direction-column">
        <div className="flex justify-content--center align-items--center flex--gap-smaller margin-top-bigger margin-bottom-sm">
          <IonIcon className="battle-icon" icon={cashOutline} />
          <h2 className="heading-secondary center-text margin--none">
            Cash Prizes
          </h2>
        </div>
        <p className="description--large center-text">
          Total Prize Pool:{" "}
          <span className="description--larger bold">
            {"$" + numberWithCommas(battleInfo.rawTuple.cashPrize) + " USD"}
          </span>
        </p>
        <div className="simple-divider--dotted simple-divider-blue margin-top-bottom-sm"></div>
        <p className="description center-text">
          Genre Winner (x4):{" "}
          <span className="description--large bold">
            {"$" +
              numberWithCommas(
                Math.floor(
                  battleInfo.rawTuple.cashPrize * (isLegacy ? 0.2 : 0.17)
                )
              ) +
              " USD"}
          </span>
        </p>
        <p className="description center-text">
          Genre Runner-up (x4):{" "}
          <span className="description--large bold">
            {"$" +
              numberWithCommas(
                Math.floor(
                  battleInfo.rawTuple.cashPrize * (isLegacy ? 0.05 : 0.04)
                )
              ) +
              " USD"}
          </span>
        </p>
        {!isLegacy ? (
          <p className="description center-text">
            Genre Semi-Finalists (x8):{" "}
            <span className="description--large bold">
              {"$" +
                numberWithCommas(
                  Math.floor(battleInfo.rawTuple.cashPrize * 0.02)
                ) +
                " USD"}
            </span>
          </p>
        ) : null}
        <br />
        <br />
        <br />
        <br />
        <p className="description--small center-text">
          This is an international contest.
          <br />
          Prize money will be converted to the currency of your choice.
        </p>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default Prizes;
