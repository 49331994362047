import {
  skullOutline,
  personOutline,
  languageOutline,
  walkOutline,
  hammerOutline,
  earthOutline,
  flashOutline,
  starOutline,
} from "ionicons/icons";

export const DEFAULT_DRAWS = 6;

// DESCRIPTIONS
const SUBJECT_DESCRIPTION =
  "The subject should be an integral part of the story, not just a superficial\
   addition, and should serve a purpose in driving the narrative forward.";
const CHARACTER_DESCRIPTION =
  "The character must appear in your story. The character does not have to be the main character and you do not have to use the text as it is written on the prompt card.";
const WORD_DESCRIPTION =
  "Incorporate the given word into your writing, ensuring that it appears within the body of the story. While the word may also be present in the title, it must be specifically mentioned within the story itself. You can include it as a standalone word or as part of a longer word. For example, if your word is auspicious, then you could also use inauspicious or auspiciously.";
const ACTION_DESCRIPTION =
  "The action must happen somewhere in your story, but you do not have to use the text as it is written on the prompt card.";
const OBJECT_DESCRIPTION =
  "The object must appear in your story, but you do not have to use the text as it is written on the prompt card.";
const SETTING_DESCRIPTION =
  "The setting must appear in your story. There are no constraints on how long your characters have to spend there and you do not have to use the text as it is written on the prompt card.";

// ICONS
const SUBJECT_ICON = skullOutline;
const CHARACTER_ICON = personOutline;
const WORD_ICON = languageOutline;
const ACTION_ICON = walkOutline;
const OBJECT_ICON = hammerOutline;
const SETTING_ICON = earthOutline;
const DEFAULT_ICON = flashOutline;

export function getGenreIcon() {
  return starOutline;
}

/* Subject, Character, Word, Action, Object, Setting */
export function getPromptTypeDescriptionAndIcon(promptType) {
  const prompt = promptType.toLowerCase();
  switch (prompt) {
    case "subject":
      return new PromptTypeInfo(promptType, SUBJECT_DESCRIPTION, SUBJECT_ICON);
    case "character":
      return new PromptTypeInfo(
        promptType,
        CHARACTER_DESCRIPTION,
        CHARACTER_ICON
      );
    case "word":
      return new PromptTypeInfo(promptType, WORD_DESCRIPTION, WORD_ICON);
    case "action":
      return new PromptTypeInfo(promptType, ACTION_DESCRIPTION, ACTION_ICON);
    case "object":
      return new PromptTypeInfo(promptType, OBJECT_DESCRIPTION, OBJECT_ICON);
    case "setting":
      return new PromptTypeInfo(promptType, SETTING_DESCRIPTION, SETTING_ICON);
    default:
      return new PromptTypeInfo(
        "Error",
        "Unknown prompt type. Please contact admin.",
        DEFAULT_ICON
      );
  }
}

function PromptTypeInfo(type, description, icon) {
  this.type = type;
  this.description = description;
  this.icon = icon;
}
