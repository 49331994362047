import "../styles/pastWinners.css";
import "../styles/pastWinnersQueries.css";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getPastWinnersBattleWithParams } from "../publicFunctions/pastWinnersHandler";
import PastWinnerCard from "../components/pastWinners/PastWinnerCard";
import PastWinnerFeatured from "../components/pastWinners/PastWinnerFeatured";
import PastWinnersNav from "../components/pastWinners/PastWinnersNav";
import PricingSection from "../components/PricingSection";
import SEO from "../components/SEO";

const years = ["2024", "2023", "2022", "2021"];
const battles2021 = [
  "Select Battle",
  "November Screenwriting",
  "May Screenwriting",
  "March Screenwriting",
  "February Screenwriting",
];
const battles2022 = [
  "Select Battle",
  "Autumn Short Story",
  "Summer Flash Fiction",
  "May Screenwriting",
  "February Flash Fiction",
];
const battles2023 = [
  "Select Battle",
  "Autumn Short Story",
  "Summer Nanofiction",
  "Spring Microfiction",
  "Winter Flash Fiction",
];
const battles2024 = [
  "Select Battle",
  "Summer Nanofiction",
  "Spring Microfiction",
  "Winter Flash Fiction",
];
const battlesOptions = [battles2024, battles2023, battles2022, battles2021];

function PastWinners({
  battles,
  openRegisterOverlay,
  userInfo,
  alreadyJoinedMap,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  // STATES
  // we go by the params. These are just used for the dropdowns
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedBattle, setSelectedBattle] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [featuredInfo, setFeaturedInfo] = useState(
    new Featured(
      new FeaturedCard("", "", ""),
      new FeaturedBody("", "", [], "", "", [], "", "", "", "")
    )
  );
  const [pastWinnersLoaded, setPastWinnersLoaded] = useState(null);

  useEffect(() => {
    const tempYearParam = searchParams.get("year");
    const tempBattleParam = searchParams.get("battle");
    const featuredIndex = searchParams.get("featured");
    const alreadyLoadedTheseParams =
      pastWinnersLoaded &&
      tempYearParam &&
      tempBattleParam &&
      pastWinnersLoaded.formattedTitle.includes(tempYearParam) &&
      pastWinnersLoaded.formattedTitle.includes(tempBattleParam);
    if (
      (!featuredIndex || !pastWinnersLoaded) &&
      tempYearParam &&
      tempBattleParam &&
      !alreadyLoadedTheseParams
    ) {
      // get past winners
      setIsLoading(true);
      getPastWinnersBattleWithParams(tempYearParam, tempBattleParam).then(
        (tuples) => {
          if (tuples && tuples.length === 1) {
            setPastWinnersLoaded(tuples[0]);
            setSelectedYear(tuples[0].formattedTitle.split(" ")[1]);
            setSelectedBattle(tuples[0].dropdownText);
            setIsLoading(false);
            if (featuredIndex)
              featureSelectedHandler(parseInt(featuredIndex), tuples[0]);
          }
        }
      );
    } else if (!featuredIndex && alreadyLoadedTheseParams) {
      // close featured
      featureCloseHandler();
    }
  }, [searchParams]);

  // initially set the params if not given
  useEffect(() => {
    if (!searchParams.get("year") || !searchParams.get("battle")) {
      setSearchParams({
        year: years[0],
        battle: battlesOptions[0][1].split(" ")[0],
      });
    }
  }, []);

  // OTHER FUNCTIONS
  const featureSelectedHandler = (index, loadedInfo = null) => {
    const setFeaturedParamsAgain = !loadedInfo ? true : false;
    if (!loadedInfo) loadedInfo = pastWinnersLoaded;
    if (loadedInfo && index !== -1) {
      const winner = loadedInfo.winners[index];
      const name = winner.firstLine + " " + winner.secondLine;
      const incomingFeaturedCard = new FeaturedCard(
        name,
        winner.title,
        loadedInfo.winnerPictures[index],
        index,
        true
      );

      let judgePic1, judgePic2, judgeQuote1, judgeQuote2;
      if (loadedInfo.industryJudges) {
        judgePic1 = loadedInfo.industryJudges[index].judgePicUrl1;
        judgePic2 = loadedInfo.industryJudges[index].judgePicUrl2;
        judgeQuote1 = loadedInfo.industryJudges[index].judgeQuote1;
        judgeQuote2 = loadedInfo.industryJudges[index].judgeQuote2;
      }
      const cards = winner?.cards ? winner.cards : null;
      const incomingFeaturedBody = new FeaturedBody(
        name,
        winner.title,
        winner.prompts,
        winner.logline,
        winner.storyUrl,
        loadedInfo["interview" + (index + 1).toString()],
        judgePic1,
        judgeQuote1,
        judgePic2,
        judgeQuote2,
        winner.location,
        cards
      );

      const incomingFeatured = new Featured(
        incomingFeaturedCard,
        incomingFeaturedBody
      );
      setFeaturedInfo(incomingFeatured);
      searchParams.set("featured", index.toString(10));
      if (setFeaturedParamsAgain) setSearchParams(searchParams);
    }
  };

  function featureCloseHandler() {
    setFeaturedInfo(
      new Featured(
        new FeaturedCard("", "", ""),
        new FeaturedBody("", "", [], "", "", [], "", "", "", "", "")
      )
    );
    searchParams.delete("featured");
    setSearchParams(searchParams);
  }

  const yearHandler = (e) => {
    const dropdownText = e.target.value;
    setSelectedYear(dropdownText);
    setSelectedBattle("Select Battle");
  };

  const battleHandler = (e) => {
    const dropdownText = e.target.value;
    setSelectedBattle(dropdownText);
    if (dropdownText === "Select Battle") return;
    if (searchParams.get("featured")) {
      featureCloseHandler();
    }
    const newSearchYear = selectedYear;
    const newSearchBattle = dropdownText.split(" ")[0];
    setSearchParams({
      year: newSearchYear,
      battle: newSearchBattle,
    });
  };

  return (
    <div>
      <SEO
        title="Past Winners | Writing Battle"
        description="Read the winning short stories and interviews from the authors of past Writing Battles."
        name="Writing Battle"
        type="website"
      />
      <div className="hero-background">
        <PastWinnersNav
          isLoading={isLoading}
          selectedYearHandler={yearHandler}
          selectedBattleHandler={battleHandler}
          selectedYear={selectedYear}
          selectedBattle={selectedBattle}
          years={years}
          battles={battlesOptions}
        />
        <div
          id="pastWinnersSection"
          className="container section-past-winners grid grid--4-cols"
        >
          {!featuredInfo.card.selected ? (
            <PastWinnerCard
              cardIndex={"0"}
              cardImage={pastWinnersLoaded?.winnerPictures[0]}
              isLoading={isLoading}
              name={
                pastWinnersLoaded
                  ? pastWinnersLoaded?.winners[0].firstLine +
                    " " +
                    pastWinnersLoaded?.winners[0].secondLine
                  : ""
              }
              storyTitle={pastWinnersLoaded?.winners[0].title}
              featureSelectedTrigger={featureSelectedHandler}
            />
          ) : null}
          <PastWinnerCard
            cardIndex={"1"}
            cardImage={pastWinnersLoaded?.winnerPictures[1]}
            isLoading={isLoading}
            name={
              pastWinnersLoaded
                ? pastWinnersLoaded?.winners[1].firstLine +
                  " " +
                  pastWinnersLoaded?.winners[1].secondLine
                : ""
            }
            storyTitle={pastWinnersLoaded?.winners[1].title}
            featureSelectedTrigger={featureSelectedHandler}
            isHidden={featuredInfo.card.selected}
          />
          {!pastWinnersLoaded || pastWinnersLoaded.winners.length > 2 ? (
            <>
              <PastWinnerCard
                cardIndex={"2"}
                cardImage={pastWinnersLoaded?.winnerPictures[2]}
                isLoading={isLoading}
                name={
                  pastWinnersLoaded
                    ? pastWinnersLoaded?.winners[2].firstLine +
                      " " +
                      pastWinnersLoaded?.winners[2].secondLine
                    : ""
                }
                storyTitle={pastWinnersLoaded?.winners[2].title}
                featureSelectedTrigger={featureSelectedHandler}
                isHidden={featuredInfo.card.selected}
              />
              <PastWinnerCard
                cardIndex={"3"}
                cardImage={pastWinnersLoaded?.winnerPictures[3]}
                isLoading={isLoading}
                name={
                  pastWinnersLoaded
                    ? pastWinnersLoaded?.winners[3].firstLine +
                      " " +
                      pastWinnersLoaded?.winners[3].secondLine
                    : ""
                }
                storyTitle={pastWinnersLoaded?.winners[3].title}
                featureSelectedTrigger={featureSelectedHandler}
                isHidden={featuredInfo.card.selected}
              />
            </>
          ) : null}

          <PastWinnerFeatured
            key="featuredBody"
            closeClicked={featureCloseHandler}
            isHidden={featuredInfo.card.selected ? false : true}
            featured={featuredInfo.body}
            featuredCard={
              featuredInfo.card.selected ? (
                <PastWinnerCard
                  key="featuredCard"
                  isFeatured={true}
                  isLoading={false}
                  cardImage={featuredInfo.card.image}
                  name={featuredInfo.card.name}
                  storyTitle={featuredInfo.card.title}
                />
              ) : null
            }
          />
        </div>
      </div>
      <PricingSection
        battles={battles}
        hideTitle={true}
        openLogInHandler={openRegisterOverlay}
        userInfo={userInfo}
        alreadyJoinedMap={alreadyJoinedMap}
      />
    </div>
  );
}

function Featured(card, body) {
  this.card = card;
  this.body = body;
}

function FeaturedCard(name, title, image, index = -1, selected = false) {
  this.name = name;
  this.title = title;
  this.image = image;
  this.index = index;
  this.selected = selected;
}

function FeaturedBody(
  name,
  title,
  prompts,
  logline,
  storyUrl,
  interview,
  judgePic1,
  judgeQuote1,
  judgePic2,
  judgeQuote2,
  location,
  cards = null
) {
  this.name = name;
  this.title = title;
  this.prompts = prompts;
  this.logline = logline;
  this.storyUrl = storyUrl;
  this.interview = interview;
  this.judgePic1 = judgePic1;
  this.judgeQuote1 = judgeQuote1;
  this.judgePic2 = judgePic2;
  this.judgeQuote2 = judgeQuote2;
  this.location = location;
  this.cards = cards;
}

export default PastWinners;
