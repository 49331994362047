class PublicProfile {
  constructor() {
    this.userId = "";
    this.name = "";
    this.pageName = "";
    this.trophiesDisplayed = [];
    this.profileTrophyImage = "";
    this.featuredStories = [];
    this.profilePic = "";
    this.bio = {
      text: "",
      guiltyPleasure: "",
      everyoneShould: "",
    };
    this.location = {
      city: "",
      country: "",
    };
    this.socials = {
      facebook: "",
      twitter: "",
      instagram: "",
      website: "",
    };
  }

  setPublicProfile(publicProfile) {
    this.userId = publicProfile.userId;
    this.name = publicProfile.name;
    this.pageName = publicProfile.pageName;
    this.trophiesDisplayed = publicProfile.trophiesDisplayed;
    this.profileTrophyImage = publicProfile.profileTrophyImage;
    this.featuredStories = publicProfile.featuredStories;
    this.profilePic = publicProfile.profilePic;
    this.bio = publicProfile.bio;
    this.location = publicProfile.location;
    this.socials = publicProfile.socials;
  }
}

export default PublicProfile;
