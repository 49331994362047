class TopicObject {
  constructor(opUserId, topicTitle, topicId, pagination) {
    this.opUserId = opUserId;
    this.topicTitle = topicTitle;
    this.topicId = topicId;
    this.pagination = pagination;
  }
}

export default TopicObject;
