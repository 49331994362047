import { useState } from "react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/simpleLoading.json";
import {
  emailAuthProvider,
  auth,
  reauthWithCredential,
  updatePass,
} from "../../config/firebase";

function ChangePassword({ userInfo, showMe, handleChangePage }) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isWorking, setIsWorking] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    const tempOldPassword = oldPassword;
    const tempNewPassword = newPassword;
    if (!tempOldPassword || !tempNewPassword) {
      setMessage(
        "Error: you must enter both your current password and new password"
      );
      return;
    }
    setIsWorking(true);
    const credential = emailAuthProvider.credential(
      auth.currentUser.email,
      tempOldPassword
    );
    reauthWithCredential(auth.currentUser, credential)
      .then(() => {
        updatePass(auth.currentUser, tempNewPassword)
          .then(() => {
            setMessage("You have successfully changed your password!");
          })
          .catch(() => {
            setMessage(
              "Something went wrong. Please try again in a few minutes."
            );
          })
          .finally(() => {
            setOldPassword("");
            setNewPassword("");
            setIsWorking(false);
          });
      })
      .catch((err) => {
        let messageToShow = err.message;
        if (messageToShow.includes("wrong-password")) {
          setMessage(
            "Wrong current password. " +
              "Please re-enter the same password you used to log in."
          );
        } else {
          setMessage(messageToShow);
        }
        setOldPassword("");
        setNewPassword("");
        setIsWorking(false);
      });
  };

  const onOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
    setMessage("");
  };

  const onNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
    setMessage("");
  };

  return (
    <div style={{ display: showMe ? "block" : "none" }}>
      <form className="register-form change-password" onSubmit={onSubmit}>
        <div>
          <label htmlFor="oldPassword" disabled={isWorking}>
            Current Password
          </label>
          <input
            disabled={isWorking}
            id="oldPassword"
            type="password"
            required
            value={oldPassword}
            onChange={onOldPasswordChange}
          />
        </div>
        <div>
          <label htmlFor="newPassword" disabled={!oldPassword || isWorking}>
            New Password
          </label>
          <input
            disabled={!oldPassword || isWorking}
            id="newPassword"
            type="password"
            required
            value={newPassword}
            onChange={onNewPasswordChange}
          />
        </div>

        {message ? <p className="description error-text">{message}</p> : null}
        <button
          className="btn btn--register"
          disabled={isWorking || !oldPassword || !newPassword}
        >
          Submit Password Change
          <div className={isWorking ? "simple-loader" : "hidden"}>
            <Lottie
              loop
              animationData={loadingAnimation}
              speed={1.5}
              play
            ></Lottie>
          </div>
        </button>
      </form>
      <p
        onClick={() => handleChangePage("private")}
        style={{ display: "inline-block" }}
        className="description clickable-text center-text margin-top-bigger"
      >
        Go Back
      </p>
    </div>
  );
}

export default ChangePassword;
