import { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/simpleLoading.json";
import { sendForgotPasswordEmail } from "../../publicFunctions/registerHandler";
import { useSearchParams } from "react-router-dom";

function ForgotPassword({ userInfo }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [email, setEmail] = useState(
    userInfo && userInfo.isLoggedIn() ? userInfo.email : ""
  );
  const [errorMessage, setErrorMessage] = useState(
    "Please try the Password Reset, and if you do not receive an email, " +
      "then let us know at admin@writingbattle.com and we will give you a temporary " +
      "password."
  );

  useEffect(() => {
    const resetPasswordEmail = searchParams.get("resetPasswordEmail");
    if (resetPasswordEmail) {
      const decoded = decodeURIComponent(resetPasswordEmail);
      setEmail(decoded);
      setSearchParams({}); // reset search params
    }
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await sendForgotPasswordEmail(email);
      setIsComplete(true);
      setErrorMessage(
        "A link to reset your password was just sent to your email address. \
        Please allow a few minutes for it to arrive in your email inbox. \
        Be sure to check your spam folder if you don't see it."
      );
    } catch (err) {
      let message = "";
      if (err && err.message.includes("user-not-found")) {
        message =
          "Error. There does not seem to be an account with that email address.";
      } else {
        message =
          "Hmmm that didn't seem to work. Please try again in a few minutes.";
      }
      setErrorMessage(message);
      setIsLoading(false);
    }
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div>
      <form className="register-form" onSubmit={onSubmit}>
        <div>
          <label htmlFor="forgotEmail">Email</label>
          <input
            autoFocus
            id="forgotEmail"
            type="email"
            placeholder="me@example.com"
            required
            value={email}
            onChange={onEmailChange}
          />
        </div>
        {errorMessage ? (
          <p className="description error-text">{errorMessage}</p>
        ) : null}
        <button
          className="btn btn--register"
          disabled={isComplete || isLoading}
        >
          Send Password Reset Email
          <div
            className={isLoading && !isComplete ? "simple-loader" : "hidden"}
          >
            <Lottie
              loop
              animationData={loadingAnimation}
              speed={1.5}
              play
            ></Lottie>
          </div>
        </button>
      </form>
    </div>
  );
}

export default ForgotPassword;
