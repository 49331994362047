import { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline, caretDownOutline } from "ionicons/icons";
import SearchUsers from "./SearchUsers";
import TaggedUsersList from "./TaggedUsersList";
import SelectUsers from "./SelectUsers";

function TagUsers({
  myUserId,
  suggestedUserIds,
  listOfTaggedUserIds,
  handleTagUser,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [listOfSelectableUserIds, setListOfSelectableUserIds] = useState([]);
  const [doNotRenderTheseUserIdsMap, setDoNotRenderTheseUserIdsMap] = useState(
    new Map()
  );
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const tempMap = new Map();
    if (myUserId) {
      tempMap.set(myUserId, true);
    }
    if (listOfTaggedUserIds?.length) {
      for (let i = 0; i < listOfTaggedUserIds.length; i++) {
        tempMap.set(listOfTaggedUserIds[i], true);
      }
    }
    setDoNotRenderTheseUserIdsMap(tempMap);
  }, [myUserId, listOfTaggedUserIds]);

  const handleReceiveUsersToSelect = (userList) => {
    if (userList?.length) {
      const tempUserList = [];
      for (let i = 0; i < userList.length; i++) {
        tempUserList.push(userList[i].documentId);
      }
      setListOfSelectableUserIds(tempUserList);
    }
  };

  return (
    <div className="tag-users search-bar-container--less-padding">
      <TaggedUsersList
        listOfTaggedUserIds={listOfTaggedUserIds}
        handleUserSelectedToBeRemoved={handleTagUser}
      />
      {!listOfTaggedUserIds?.length ? (
        <div
          role="button"
          onClick={(event) => {
            event.preventDefault();
            setIsExpanded(!isExpanded);
          }}
          className="flex justify-content--center align-items--center flex--gap-normal expand-outline"
        >
          <IonIcon
            className="battle-icon--small"
            icon={isExpanded ? caretDownOutline : caretForwardOutline}
          ></IonIcon>
          <p role="button" className="description bold">
            Tag up to 5 users to notify them of this post
          </p>
        </div>
      ) : null}
      {isExpanded || listOfTaggedUserIds?.length ? (
        <>
          <SearchUsers
            handleReceiveUsersToSelect={handleReceiveUsersToSelect}
            handleIsLoading={(tempLoading) => {
              setIsLoading(tempLoading);
            }}
          />
          <SelectUsers
            isLoading={isLoading}
            listOfSelectableUserIds={listOfSelectableUserIds}
            suggestedUserIds={suggestedUserIds}
            doNotRenderTheseUserIdsMap={doNotRenderTheseUserIdsMap}
            handleUserSelectedToBeTagged={handleTagUser}
          />
        </>
      ) : null}
    </div>
  );
}

export default TagUsers;
