function MyAccountNav({ selectedPage }) {
  let displayedTitle = "";
  if (selectedPage === "private") {
    displayedTitle = "My Account";
  } else if (selectedPage === "changePassword") {
    displayedTitle = "Change Password";
  }

  return (
    <div className="my-account-nav">
      <div className="flex">
        <p className="description--larger bold center-text margin-auto">
          {displayedTitle}
        </p>
      </div>
    </div>
  );
}

export default MyAccountNav;
