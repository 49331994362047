import blueBack from "../../img/tarot/blue_back.webp";
import redBack from "../../img/tarot/red_back.webp";
import silverFoil from "../../img/tarot/silver_foil_design.svg";
import { IonIcon } from "@ionic/react";
import { repeatOutline } from "ionicons/icons";
import Reveal from "../animations/Reveal";

function LargeTarotCard({
  imgUrl,
  tarotInfo,
  reversed,
  isMajor,
  flipped,
  position,
}) {
  return (
    <div className="large-tarot-wrapper">
      {!flipped ? (
        <div className="large-tarot-img">
          <img className="large-tarot" src={isMajor ? blueBack : redBack} />
          <img className="large-tarot-foil" src={silverFoil} />
        </div>
      ) : (
        <>
          {reversed ? (
            <IonIcon className="tarot-reversed-icon" icon={repeatOutline} />
          ) : null}
          <Reveal
            type="fromRight"
            rotate={true}
            delay={0.2 + position * 0.1}
            fadeIn={true}
          >
            <img className="large-tarot" src={imgUrl} />
          </Reveal>
        </>
      )}
    </div>
  );
}

export default LargeTarotCard;
