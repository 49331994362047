import { auth, db } from "../config/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

export async function getMyGameStates() {
  if (!auth || !auth.currentUser || !auth.currentUser.uid) return [];
  const gameStatesRef = collection(db, "gameStates");
  const q = query(gameStatesRef, where("userId", "==", auth.currentUser.uid));
  const snapshot = await getDocs(q);
  if (!snapshot.size) return [];
  return snapshot.docs.map((gameState) => gameState.data());
}

export async function getGameState(battleId) {
  if (!auth || !auth.currentUser || !auth.currentUser.uid) return null;
  const gameStatesRef = collection(db, "gameStates");
  const q = query(
    gameStatesRef,
    where("userId", "==", auth.currentUser.uid),
    where("battleId", "==", battleId)
  );
  const snapshot = await getDocs(q);
  if (!snapshot.size) return null;
  else {
    return snapshot.docs[0].data();
  }
}

export function injectUpdatedGameStateIntoCurrentStates(
  currentStates,
  updatedState
) {
  if (!currentStates || !updatedState || !currentStates.length) {
    return {
      success: false,
    };
  }

  // Find index in currentStates
  let foundIndex = -1;
  for (let i = 0; i < currentStates.length; i++) {
    if (currentStates[i].battleId === updatedState.battleId) {
      foundIndex = i;
      break;
    }
  }

  if (foundIndex === -1) {
    return {
      success: false,
    };
  } else {
    const newGameStatesArray = [...currentStates];
    newGameStatesArray.splice(foundIndex, 1, updatedState);
    return {
      success: true,
      newGameStatesArray: newGameStatesArray,
    };
  }
}
