import { IonIcon } from "@ionic/react";
import { menuOutline, closeOutline } from "ionicons/icons";

function MobileNav({ onClick }) {
  return (
    <button onClick={onClick} className="btn-mobile-nav">
      <IonIcon className="icon-mobile-nav menu-outline" icon={menuOutline} />
      <IonIcon className="icon-mobile-nav close-outline" icon={closeOutline} />
    </button>
  );
}

export default MobileNav;
