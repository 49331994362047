import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

function Reveal({
  children,
  type = "fromBottom",
  rotate = false,
  withBounce = true,
  delay = 0.1,
  fadeIn = false,
  animated = true,
}) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  const visible = withBounce
    ? {
        y: 0,
        x: 0,
        rotate: 0,
        transition: {
          type: "spring",
          bounce: 0.4,
          duration: 0.8,
          delay,
        },
        opacity: 1,
      }
    : {
        y: 0,
        x: 0,
        rotate: 0,
        opacity: 1,
      };

  let hidden = { opacity: fadeIn ? 0 : 1 };
  if (type === "fromLeft") {
    hidden = { rotate: rotate ? -20 : 0, x: -100 };
  } else if (type === "fromRight") {
    hidden = { rotate: rotate ? 20 : 0, x: 100 };
  } else {
    // assume fromBottom
    hidden = { rotate: rotate ? 20 : 0, y: 300 };
  }

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  if (!animated) {
    return <div>{children}</div>;
  }

  return (
    <div ref={ref}>
      <motion.div
        variants={{
          hidden,
          visible,
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.5, delay }}
      >
        {children}
      </motion.div>
    </div>
  );
}

export default Reveal;
