import TestimonialImage4 from "../../img/participants/testimonial4.webp";
import Participant2Image from "../../img/participants/participant5_128.jpg";
import Participant3Image from "../../img/participants/participant1_128.jpg";
import Participant4Image from "../../img/participants/participant6_128.jpg";
import GalleryImage1 from "../../img/participants/gallery/gallery-img1.webp";
import GalleryImage2 from "../../img/participants/gallery/gallery-img2.webp";
import GalleryImage3 from "../../img/participants/gallery/gallery-img3.webp";
import GalleryImage4 from "../../img/participants/gallery/gallery-img9.webp";
import GalleryImage5 from "../../img/participants/gallery/gallery-img5.webp";
import GalleryImage6 from "../../img/participants/gallery/gallery-img6.webp";
import GalleryImage7 from "../../img/participants/gallery/gallery-img7.webp";
import GalleryImage8 from "../../img/participants/gallery/gallery-img8.webp";
import GalleryImage9 from "../../img/participants/gallery/gallery-img13.webp";
import GalleryImage10 from "../../img/participants/gallery/gallery-img10.webp";
import GalleryImage11 from "../../img/participants/gallery/gallery-img11.webp";
import GalleryImage12 from "../../img/participants/gallery/gallery-img12.webp";

import Testimonial from "./Testimonial";

function TestimonialsSection() {
  return (
    <div className="section-testimonials">
      <div className="testimonials-container">
        <span className="subheading">Testimonials</span>
        <h2 className="heading-secondary">
          A thriving and supportive community
        </h2>
        <div className="testimonials">
          <Testimonial
            participantImage={TestimonialImage4}
            participantName="Terrye Turpin"
            quote="This contest is a bargain and the feedback received, even if you don't end up a $ winner, is valuable and worth the cost of entry."
          />
          <Testimonial
            participantImage={Participant2Image}
            participantName="Karen Mitani"
            quote="I opted not to draw different prompts and stuck with the original 
            three I was given."
          />
          <Testimonial
            participantImage={Participant3Image}
            participantName="Matt Gamarra"
            quote="This is a growing community with a mix of experienced and 
            curious writers. Sharing your work and feedback in the forums is 
            invaluable."
          />
          <Testimonial
            participantImage={Participant4Image}
            participantName="Aly K"
            quote="I felt so rewarded. For the peer-judged Battles, judging the duels is a highlight of my day."
          />
        </div>
      </div>
      <div className="gallery">
        <figure className="gallery-item">
          <img src={GalleryImage1} alt="" />
        </figure>
        <figure className="gallery-item">
          <img src={GalleryImage2} alt="" />
        </figure>
        <figure className="gallery-item">
          <img src={GalleryImage3} alt="" />
        </figure>
        <figure className="gallery-item">
          <img src={GalleryImage4} alt="" />
        </figure>
        <figure className="gallery-item">
          <img src={GalleryImage5} alt="" />
        </figure>
        <figure className="gallery-item">
          <img src={GalleryImage6} alt="" />
        </figure>
        <figure className="gallery-item">
          <img src={GalleryImage7} alt="" />
        </figure>
        <figure className="gallery-item">
          <img src={GalleryImage8} alt="" />
        </figure>
        <figure className="gallery-item">
          <img src={GalleryImage9} alt="" />
        </figure>
        <figure className="gallery-item">
          <img src={GalleryImage10} alt="" />
        </figure>
        <figure className="gallery-item">
          <img src={GalleryImage11} alt="" />
        </figure>
        <figure className="gallery-item">
          <img src={GalleryImage12} alt="" />
        </figure>
      </div>
    </div>
  );
}

export default TestimonialsSection;
