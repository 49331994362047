import { IonIcon } from "@ionic/react";
import { pencilOutline } from "ionicons/icons";
import { useEffect, useState } from "react";

function EditAThing({ toggleEditHandler, argEditing, justifySelfEnd }) {
  const [editing, setEditing] = useState(argEditing ? argEditing : false);

  useEffect(() => {
    if (!argEditing) {
      setEditing(false);
    } else {
      setEditing(true);
    }
  }, [argEditing]);

  return (
    <div
      style={
        justifySelfEnd ? { justifySelf: "flex-end" } : { justifySelf: "center" }
      }
      className="delete-thing"
    >
      {editing ? (
        <p
          onClick={() => {
            setEditing(false);
            toggleEditHandler(false);
          }}
          className="description underline cursor--pointer margin-top-bigger"
        >
          Cancel Edit
        </p>
      ) : (
        <div
          onClick={() => {
            setEditing(true);
            toggleEditHandler(true);
          }}
          className="delete-thing-icon"
        >
          <IonIcon className="social-icon" icon={pencilOutline} />
        </div>
      )}
    </div>
  );
}

export default EditAThing;
