import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BasicPageNavControls from "../../publicFunctions/clientClasses/BasicPageNavControls";
import BasicPageNav from "../BasicPageNav";
import LoadingSection from "../LoadingSection";
import { Timestamp } from "firebase/firestore";
import { fetchTopics } from "../../publicFunctions/forumHandler";
import { getPublicProfileByUserId } from "../../publicFunctions/publicProfileHandler";
import BasicUserInfo from "../BasicUserInfo";
import {
  DEFAULT_FORUM_LIST_SIZE,
  convertTimestampToObject,
  getSimpleTimeInUserTimeZone,
  mapStalePagination,
  pageScrollSettings,
} from "../../publicFunctions/publicHelpers/helpers";
import DefaultProfilePicture from "../../img/profile_picture.png";

const TOPIC_TITLE_LENGTH_MAX = 100;

function TopicList({
  searchParams,
  setSearchParams,
  forceRefresh,
  handleNotActive,
  stalePaginationArray,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const topRef = useRef(null);

  const [fullReset, setFullReset] = useState(false);
  const [forceRefreshNav, setForceRefreshNav] = useState(false);
  const [noMoreTopics, setNoMoreTopics] = useState(false);
  const [renderedTopics, setRenderedTopics] = useState([]);
  const [isWorking, setIsWorking] = useState(false);
  const [pageNavControls, setPageNavControls] = useState(
    new BasicPageNavControls(
      "/forum",
      "topicsNum",
      "topicsIndex",
      null,
      mapStalePagination(stalePaginationArray)
    )
  );

  useEffect(() => {
    if (searchParams.get("page") === "topics") {
      loadTopics();
    }
  }, [searchParams, forceRefresh]);

  async function loadTopics() {
    setIsWorking(true);
    if (topRef && searchParams?.get("topicsIndex")) {
      topRef.current.scrollIntoView(pageScrollSettings);
    }
    try {
      let lastReplyDate = null;
      const currentIndex = searchParams.get("topicsIndex");
      if (currentIndex && currentIndex !== "none") {
        lastReplyDate = Timestamp.fromMillis(parseInt(currentIndex));
      }

      const topics = await fetchTopics(lastReplyDate);

      if (topics.length === DEFAULT_FORUM_LIST_SIZE + 1) {
        const pagination = pageNavControls.pagination;
        const lastReplyDate = topics[DEFAULT_FORUM_LIST_SIZE - 1].lastReplyDate;
        const milliseconds = Math.floor(
          lastReplyDate.seconds * 1000 +
            Math.ceil(lastReplyDate.nanoseconds / 1000000)
        );
        const nextIndex = milliseconds.toString(10);
        const numString = searchParams.get("topicsNum");
        if (currentIndex && currentIndex !== "none" && numString) {
          pagination.set(numString, currentIndex);
        }

        // since we're here, we know that there IS more pagination.
        if (pagination?.size && pagination.size === parseInt(numString)) {
          // add another page
          pagination.set((pagination.size + 1).toString(10), nextIndex);
          setForceRefreshNav(!forceRefreshNav);
        }

        setPageNavControls(
          new BasicPageNavControls(
            pageNavControls.defaultNavigateString,
            pageNavControls.numParamName,
            pageNavControls.indexParamName,
            nextIndex,
            pagination
          )
        );
        setNoMoreTopics(false);
      } else if (topics.length <= DEFAULT_FORUM_LIST_SIZE) {
        setNoMoreTopics(true);
      }

      if (topics?.length) {
        const tempRender = [];
        for (let i = 0; i < topics.length && i < DEFAULT_FORUM_LIST_SIZE; i++) {
          const topic = topics[i];
          const title =
            topic.topicTitle.length <= TOPIC_TITLE_LENGTH_MAX + 3
              ? topic.topicTitle
              : topic.topicTitle.slice(0, TOPIC_TITLE_LENGTH_MAX) + "...";

          // get public profile for this user
          const publicUser = await getPublicProfileByUserId(topic.userId);

          // get the last reply user (if one exists)
          let lastReplyUser;
          if (
            topic.lastReplyUserId &&
            topic.createdDate &&
            topic.lastReplyDate
          ) {
            if (
              Math.abs(
                topic.createdDate.seconds - topic.lastReplyDate.seconds
              ) > 25
            ) {
              lastReplyUser = await getPublicProfileByUserId(
                topic.lastReplyUserId
              );
            }
          }
          tempRender.push(
            <div
              key={topic.topicId}
              className="topic-container--new"
              role="button"
            >
              <div className="flex justify-content--center align-items--center">
                {publicUser ? (
                  <BasicUserInfo
                    publicUserInfo={publicUser}
                    isTiny={true}
                    truncateName={false}
                  />
                ) : (
                  <div></div>
                )}
              </div>
              <a
                className="topic-clickable-container align-self--center"
                role="button"
                href={"/forum?page=topic&topicId=" + topic.topicId}
              >
                <p className="description--large bold">{title}</p>
                {topic?.numberOfComments &&
                topic.numberOfComments > 1 &&
                lastReplyUser ? (
                  <p className="description--smaller text-align-left mobile-smaller-text align-self--start">
                    {"Comments: " + (topic.numberOfComments - 1).toString(10)}
                  </p>
                ) : null}
              </a>
              <div
                className={
                  "cursor--pointer flex flex--direction-column forum-last-comment " +
                  "justify-content--end align-items--end break-long-words"
                }
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  // normal left click
                  const params = searchParams;
                  searchParams.set("topicId", topic.topicId);
                  searchParams.set("page", "topic");
                  if (topic.pagination?.length) {
                    const lastPage = topic.pagination.length.toString(10);
                    const index =
                      topic.pagination[topic.pagination.length - 1].index;
                    searchParams.set("repliesNum", lastPage);
                    searchParams.set("repliesIndex", index);
                  } else {
                    searchParams.set("repliesNum", "1");
                  }
                  setSearchParams(params);
                }}
              >
                {lastReplyUser ? (
                  <>
                    <p className="description--smallest text-align-right">
                      Last comment by:{" "}
                    </p>

                    <img
                      className="basic-user-info--img-smaller"
                      src={
                        lastReplyUser?.profilePic
                          ? lastReplyUser.profilePic
                          : DefaultProfilePicture
                      }
                    />
                    <p className="description--smallest description--smaller-set-width text-align-right mobile-smaller-text">
                      {lastReplyUser?.name ? lastReplyUser?.name : ""}
                    </p>
                    {topic.lastReplyDate ? (
                      <p className="description--smaller text-align-right mobile-smaller-text">
                        {getSimpleTimeInUserTimeZone(
                          convertTimestampToObject(topic.lastReplyDate)
                        )}
                      </p>
                    ) : null}
                  </>
                ) : (
                  <p className="description--smaller text-align-right">
                    Be the first to join this discussion!
                  </p>
                )}
              </div>
            </div>
          );
        }
        setRenderedTopics(tempRender);
      }
    } catch (err) {
      console.log(err);
      setNoMoreTopics(true);
      if (err?.message?.includes("insufficient permissions")) {
        handleNotActive();
      }
    } finally {
      setIsWorking(false);
    }
  }

  return (
    <div>
      <div ref={topRef} className="simple-divider"></div>

      {/* TOP NAV */}
      {renderedTopics?.length ? (
        <BasicPageNav
          pageNum={
            searchParams.has("topicsNum")
              ? parseInt(searchParams.get("topicsNum"))
              : 1
          }
          noMorePages={noMoreTopics}
          incrementPageHandler={() => {
            pageNavControls.incrementPageHandler(searchParams, setSearchParams);
          }}
          decrementPageHandler={() => {
            pageNavControls.decrementPageHandler(
              location,
              navigate,
              searchParams,
              setSearchParams
            );
          }}
          goToPageHandler={(page, index) => {
            pageNavControls.goToPage(
              page,
              index,
              searchParams,
              setSearchParams
            );
          }}
          isWorking={isWorking}
          pagination={pageNavControls.pagination}
          showPageDropdown={true}
          forceRefreshNav={forceRefreshNav}
        />
      ) : null}

      {/* PAYLOAD */}
      <div key={fullReset} className="topics-list">
        {!isWorking ? renderedTopics : <LoadingSection />}
      </div>

      {/* BOTTOM NAV */}
      {renderedTopics?.length ? (
        <BasicPageNav
          pageNum={
            searchParams.has("topicsNum")
              ? parseInt(searchParams.get("topicsNum"))
              : 1
          }
          noMorePages={noMoreTopics}
          incrementPageHandler={() => {
            pageNavControls.incrementPageHandler(searchParams, setSearchParams);
          }}
          decrementPageHandler={() => {
            pageNavControls.decrementPageHandler(
              location,
              navigate,
              searchParams,
              setSearchParams
            );
          }}
          goToPageHandler={(page, index) => {
            pageNavControls.goToPage(
              page,
              index,
              searchParams,
              setSearchParams
            );
          }}
          isWorking={isWorking}
          pagination={pageNavControls.pagination}
          showPageDropdown={true}
          forceRefreshNav={forceRefreshNav}
        />
      ) : null}
    </div>
  );
}

export default TopicList;
