import "../styles/home.css";
import "../styles/homeQueries.css";
import HowItWorksSection from "../components/landing/HowItWorksSection";
import JudgesSection from "../components/landing/JudgesSection";
import TestimonialsSection from "../components/landing/TestimonialsSections";
import PricingSection from "../components/PricingSection";
import FeatureSection from "../components/landing/FeatureSection";
import CTASection from "../components/landing/CTASection";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import HomePageCountdown from "../components/landing/HomePageCountdown";
import MarkYourCalendar from "../components/landing/MarkYourCalendary";
import SEO from "../components/SEO";
import Hero from "../components/landing/Hero";
import { useState } from "react";
import FirstCTA from "../components/landing/FirstCTA";
import MVWSection from "../components/landing/MVWSection";
import StillHaveNotJoined from "../components/landing/StillHaveNotJoined";
import PagePreview from "../components/preview/PagePreview";
import FinalTestimonialSection from "../components/landing/FinalTestimonialSection";
import PeerVSPro from "../components/landing/PeerVSPro";

const now = new Date();

function Home({
  battles,
  openLogInHandler,
  userInfo,
  alreadyJoinedMap,
  removeSEO = false,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [now] = useState(new Date());

  useEffect(() => {
    const resetPasswordEmail = searchParams.get("resetPasswordEmail");
    if (resetPasswordEmail) {
      setTimeout(function () {
        openLogInHandler("forgotPassword");
      }, 1500);
    }
  }, []);

  // 3 weeks out
  const showTheHomePageCountdown =
    battles?.length &&
    userInfo &&
    !userInfo.isStillCheckingLogin() &&
    battles[0].submissionDate.getTime() > now.getTime() &&
    battles[0].submissionDate.getTime() - now.getTime() < 1814400000 &&
    alreadyJoinedMap;

  return (
    <div>
      {!removeSEO ? (
        <SEO
          title="Writing Battle"
          description="The writer's colosseum. 
          A competitive writing platform for storytellers around the world. 
          Every story receives oodles of feedback. 
          Join the most supportive writing community on the web. You up for the challenge?"
          name="Writing Battle"
          type="website"
        />
      ) : null}
      <main>
        {showTheHomePageCountdown ? (
          <HomePageCountdown
            battle={battles[0]}
            alreadyJoinedMap={alreadyJoinedMap}
            userInfo={userInfo}
            openLogInHandler={openLogInHandler}
          />
        ) : null}
        <Hero
          openLogInHandler={openLogInHandler}
          userInfo={userInfo}
          now={now}
          battles={battles}
          alreadyJoinedMap={alreadyJoinedMap}
        />
        <FirstCTA
          isUnderHero={true}
          userInfo={userInfo}
          openLogInHandler={openLogInHandler}
          now={now}
        />
        <HowItWorksSection now={now} />
        <JudgesSection />
        <TestimonialsSection />
        <PricingSection
          battles={battles}
          openLogInHandler={openLogInHandler}
          userInfo={userInfo}
          alreadyJoinedMap={alreadyJoinedMap}
        />
        <PeerVSPro battles={battles} />
        <FeatureSection />
        <CTASection />
        <MarkYourCalendar battles={battles} />
        <MVWSection />
        <PagePreview
          userInfo={userInfo}
          hideLogInButtons={true}
          openLogInHandler={openLogInHandler}
          animated={true}
          page="Tavern"
        />
        <PagePreview
          userInfo={userInfo}
          hideLogInButtons={true}
          openLogInHandler={openLogInHandler}
          animated={true}
          page="Forum"
        />
        <PagePreview
          userInfo={userInfo}
          hideLogInButtons={true}
          openLogInHandler={openLogInHandler}
          animated={true}
          page="Debrief"
        />
        <FinalTestimonialSection />
        <StillHaveNotJoined
          userInfo={userInfo}
          openLogInHandler={openLogInHandler}
          battles={battles}
        />
      </main>
    </div>
  );
}

export default Home;
