import Reveal from "../animations/Reveal";
import CreateAccountOrLogin from "./CreateAccountOrLogin";
import { getPreviewContents } from "./PreviewHelper";

function PagePreview({
  userInfo,
  isActive,
  hideLogInButtons,
  openLoginHandler,
  animated,
  page,
}) {
  const contents = getPreviewContents(page);

  return (
    <div className="container margin-bottom-large">
      <div className="how-container center-text">
        <Reveal type="fromBottom" fadeIn={true} animated={animated}>
          <span className="subheading">{contents.firstTitle}</span>
        </Reveal>
        <Reveal type="fromBottom" fadeIn={true} animated={animated}>
          <h2 className="heading-secondary">{contents.secondTitle}</h2>
        </Reveal>
      </div>
      <div className="preview-flex flex--gap-normal flex--justify-space-between">
        <img src={contents.firstImage} className="preview-img" alt="" />
        <Reveal type="fromBottom" fadeIn={true} animated={animated}>
          {contents.previewText}
        </Reveal>
        <img src={contents.secondImage} className="preview-img--left" alt="" />
      </div>
      {!hideLogInButtons ? (
        <CreateAccountOrLogin
          userInfo={userInfo}
          openLogInHandler={openLoginHandler}
          isActive={isActive}
        />
      ) : null}
    </div>
  );
}

export default PagePreview;
