import { db } from "../config/firebase";
import { collection, query, where, limit, orderBy } from "firebase/firestore";
import {
  getQueryAsArray,
  convertAllTimestampsToDatesInArray,
} from "./publicHelpers/helpers";

export async function getMostRecentPastWinners() {
  const q = query(
    collection(db, "pastWinners"),
    orderBy("dateCompleted", "desc"),
    limit(1)
  );
  const tuples = await getQueryAsArray(q);
  convertAllTimestampsToDatesInArray(tuples);
  return tuples;
}

export async function getPastWinnersBattleWithParams(year, battle) {
  // create formatted title with year and battle
  const formattedTitle = battle + " " + year;

  const q = query(
    collection(db, "pastWinners"),
    where("formattedTitle", "==", formattedTitle),
    limit(1)
  );
  const tuples = await getQueryAsArray(q);
  convertAllTimestampsToDatesInArray(tuples);
  return tuples;
}
