//import DefaultProfilePicture from "../../img/profile_picture.png";
import TitleAndDate from "./TitleAndDate";
import FirstStage from "./FirstStage";
import {
  getSocialShareLink,
  getSuperSimpleEstWithTime,
  getTitleAndDateToShow,
  stateNames,
} from "../../publicFunctions/publicHelpers/helpers";
import { useEffect, useState } from "react";
import { giveMeAnExtraDraw } from "../../config/firebase";
import SecondStage from "./SecondStage";
import { getBattleSwatchByBattleId } from "../../publicFunctions/publicHelpers/homeHelper";

function defaultLockedInInfo(battleInfo) {
  return {
    isLockedIn: false,
    dateText: battleInfo
      ? getSuperSimpleEstWithTime(battleInfo.rawTuple.lockInDate)
      : "",
    showText:
      battleInfo && battleInfo.rawTuple.state === stateNames.INITIALIZED
        ? true
        : false,
    battleNotStarted:
      !battleInfo || battleInfo.rawTuple.state === stateNames.NOT_STARTED
        ? true
        : false,
  };
}

function BattleBody({
  battleInfo,
  loadedGameState,
  userInfo,
  refreshGameState,
  openLogInHandler,
}) {
  const [lockedInInfo, setLockedInInfo] = useState(
    defaultLockedInInfo(battleInfo)
  );
  const { title, countdownDate } = getTitleAndDateToShow(
    battleInfo,
    loadedGameState
  );
  const [prematureSubmit, setPrematureSubmit] = useState(false);

  useEffect(() => {
    if (battleInfo && loadedGameState) {
      const tempLockedInInfo = defaultLockedInInfo(battleInfo);

      if (battleInfo.rawTuple.state !== stateNames.NOT_STARTED) {
        tempLockedInInfo.isLockedIn =
          battleInfo.rawTuple.state !== stateNames.INITIALIZED ||
          loadedGameState.storyId ||
          (!loadedGameState.draws && !loadedGameState.genreDraws
            ? true
            : false);
      }

      if (tempLockedInInfo.isLockedIn) {
        tempLockedInInfo.showText = false;
      } else if (prematureSubmit) {
        // however, if the user requested to submit story, just shot cards
        tempLockedInInfo.isLockedIn = true;
        tempLockedInInfo.showText = true;
        tempLockedInInfo.dateText =
          "You selected to submit a story before prompt Lock-In. " +
          "As soon as you submit a draft, your " +
          "prompts will be locked-in and you will no longer be able to redraw. " +
          "If this was done by mistake, do NOT submit a draft, and " +
          "refresh the page.";
      } else if (battleInfo.rawTuple.state === stateNames.INITIALIZED) {
        tempLockedInInfo.showText = true;
      }
      setLockedInInfo(tempLockedInInfo);
    } else if (battleInfo) {
      // reset the locked in info because maybe the user changed
      setLockedInInfo(defaultLockedInInfo(battleInfo));
    }
  }, [battleInfo, loadedGameState, prematureSubmit]);

  const handlePrematureSubmit = () => {
    setPrematureSubmit(true);
  };

  const socialShare = (platform) => {
    // todo: share stuff
    const socialShareLink = getSocialShareLink(battleInfo, platform);
    if (socialShareLink) {
      window.open(socialShareLink, "_blank", "noreferrer");
    }
    setTimeout(() => {
      giveMeAnExtraDraw({ battleId: battleInfo.rawTuple.battleId })
        .then((results) => {
          if (results.data.success) {
            if (results.data.updatedGameState) {
              refreshGameState(results.data.updatedGameState);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 4000);
  };

  return (
    <div className="battle-section">
      <div className="battle-container">
        {/* <div className="battle-profile fade-in">
          <div className="profile-img-container">
            <img
              className="battle-profile-img"
              src={DefaultProfilePicture}
            ></img>
          </div>
          <p className="description center-text">
            <a className="battle-profile--text" href="#">
              <strong>Please Log In</strong>
            </a>
          </p>
        </div> */}
        <TitleAndDate
          countdownDate={countdownDate}
          halfLandscape={
            getBattleSwatchByBattleId(battleInfo.rawTuple.battleId)
              .halfLandscape
          }
          title={title}
          isOut={loadedGameState?.status?.outOfGame ? true : false}
        />
        {loadedGameState &&
        (battleInfo.rawTuple.state === stateNames.STORIES_SUBMITTED ||
          battleInfo.rawTuple.state === stateNames.FIRST_DUELS) ? (
          <SecondStage
            battleInfo={battleInfo}
            loadedGameState={loadedGameState}
            refreshGameState={refreshGameState}
          />
        ) : (
          <FirstStage
            promptTypes={battleInfo ? battleInfo.rawTuple.promptTypes : []}
            loadedGameState={loadedGameState}
            userInfo={userInfo}
            state={battleInfo.rawTuple.state}
            refreshGameState={refreshGameState}
            lockedInInfo={lockedInInfo}
            handlePrematureSubmit={handlePrematureSubmit}
            wordLimit={battleInfo.rawTuple.maxWords}
            socialShare={socialShare}
            openLogInHandler={openLogInHandler}
          />
        )}
      </div>
    </div>
  );
}

export default BattleBody;
