import {
  animal_text,
  cA_text,
  childLoss_text,
  domesticViolence_text,
  eating_text,
  gore_text,
  phobia_text,
  racism_text,
  sA_text,
  sC_text,
  substanceAbuse_text,
  suicide_text,
  violence_text,
} from "../debriefHandler";

class ContentWarning {
  constructor(
    spoiler,
    sA,
    cA,
    suicide,
    childLoss,
    substanceAbuse,
    racism,
    phobia,
    violence,
    gore,
    sC,
    eating,
    animal,
    domesticViolence
  ) {
    this.spoiler = spoiler ? spoiler : false;
    this.sA = sA ? sA : false;
    this.cA = cA ? cA : false;
    this.suicide = suicide ? suicide : false;
    this.childLoss = childLoss ? childLoss : false;
    this.substanceAbuse = substanceAbuse ? substanceAbuse : false;
    this.racism = racism ? racism : false;
    this.phobia = phobia ? phobia : false;
    this.violence = violence ? violence : false;
    this.gore = gore ? gore : false;
    this.sC = sC ? sC : false;
    this.eating = eating ? eating : false;
    this.animal = animal ? animal : false;
    this.domesticViolence = domesticViolence ? domesticViolence : false;
  }

  clone() {
    const cloned = new ContentWarning(
      this.spoiler,
      this.sA,
      this.cA,
      this.suicide,
      this.childLoss,
      this.substanceAbuse,
      this.racism,
      this.phobia,
      this.violence,
      this.gore,
      this.sC,
      this.eating,
      this.animal,
      this.domesticViolence
    );
    return cloned;
  }

  print() {
    let toReturn = "";
    try {
      const arrayOfStrings = [];
      if (this.sA) {
        arrayOfStrings.push(sA_text);
      }
      if (this.cA) {
        arrayOfStrings.push(cA_text);
      }
      if (this.suicide) {
        arrayOfStrings.push(suicide_text);
      }
      if (this.childLoss) {
        arrayOfStrings.push(childLoss_text);
      }
      if (this.substanceAbuse) {
        arrayOfStrings.push(substanceAbuse_text);
      }
      if (this.racism) {
        arrayOfStrings.push(racism_text);
      }
      if (this.phobia) {
        arrayOfStrings.push(phobia_text);
      }
      if (this.violence) {
        arrayOfStrings.push(violence_text);
      }
      if (this.gore) {
        arrayOfStrings.push(gore_text);
      }
      if (this.sC) {
        arrayOfStrings.push(sC_text);
      }
      if (this.eating) {
        arrayOfStrings.push(eating_text);
      }
      if (this.animal) {
        arrayOfStrings.push(animal_text);
      }
      if (this.domesticViolence) {
        arrayOfStrings.push(domesticViolence_text);
      }
      for (let i = 0; i < arrayOfStrings.length; i++) {
        toReturn += arrayOfStrings[i];
        if (arrayOfStrings.length > 1 && i === arrayOfStrings.length - 2) {
          toReturn += ", and ";
        } else if (i !== arrayOfStrings.length - 1) {
          toReturn += ", ";
        }
      }
    } catch (err) {
      console.log(err);
    }
    return toReturn;
  }
}

export default ContentWarning;
