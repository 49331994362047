import { db, deleteUploadedStoryComment } from "../config/firebase";
import {
  collection,
  query,
  limit,
  where,
  orderBy,
  startAfter,
  setDoc,
  doc,
  Timestamp,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import {
  DEFAULT_BETA_READ_LIST_SIZE,
  DEFAULT_COMMENTS_SIZE,
  DEFAULT_FORUM_LIST_SIZE,
  DEFAULT_REPLY_LIST_SIZE,
  createUUID,
  getQueryAsArray,
} from "./publicHelpers/helpers";

export async function getForumMeta() {
  const refForumMeta = doc(db, "forumMeta", "general");
  const obj = await getDoc(refForumMeta);
  if (obj.exists()) {
    return obj.data();
  } else {
    return null;
  }
}

export async function fetchSingleComment(commentId) {
  const refSingleComment = doc(db, "uploadedStoryComments", commentId);
  const obj = await getDoc(refSingleComment);
  if (obj.exists()) {
    return obj.data();
  } else {
    return null;
  }
}

export async function fetchComments(lastCreatedDate, storyId) {
  let q;
  if (lastCreatedDate) {
    q = query(
      collection(db, "uploadedStoryComments"),
      where("deleted", "==", false),
      where("uploadedStoryId", "==", storyId),
      orderBy("createdDate", "desc"),
      startAfter(lastCreatedDate),
      limit(DEFAULT_COMMENTS_SIZE + 1)
    );
  } else {
    q = query(
      collection(db, "uploadedStoryComments"),
      where("deleted", "==", false),
      where("uploadedStoryId", "==", storyId),
      orderBy("createdDate", "desc"),
      limit(DEFAULT_COMMENTS_SIZE + 1)
    );
  }
  const tempArray = await getQueryAsArray(q);
  if (tempArray?.length) {
    return tempArray;
  } else {
    return [];
  }
}

export async function leaveAComment(uploadedStoryId, userId, comment) {
  const commentId = createUUID();
  const ref = doc(db, "uploadedStoryComments", commentId);
  const now = Timestamp.now();
  await setDoc(ref, {
    commentId,
    uploadedStoryId,
    userId,
    comment,
    createdDate: now,
    editedDate: now,
    deleted: false,
  });
}

export async function getListOfBetaReadObjects(lastCreatedDate, battleGenre) {
  let q;
  if (!battleGenre) {
    battleGenre = "";
  }
  if (lastCreatedDate) {
    q = query(
      collection(db, "betaReadSignUp"),
      where("deleted", "==", false),
      where("battleGenre", "==", battleGenre),
      orderBy("createdDate", "desc"),
      startAfter(lastCreatedDate),
      limit(DEFAULT_BETA_READ_LIST_SIZE + 1)
    );
  } else {
    q = query(
      collection(db, "betaReadSignUp"),
      where("deleted", "==", false),
      where("battleGenre", "==", battleGenre),
      orderBy("createdDate", "desc"),
      limit(DEFAULT_BETA_READ_LIST_SIZE + 1)
    );
  }
  const tempArray = await getQueryAsArray(q);
  if (tempArray?.length) {
    return tempArray;
  } else {
    return [];
  }
}

export async function getBetaReadObject(userId) {
  const refBetaRead = doc(db, "betaReadSignUp", userId);
  const obj = await getDoc(refBetaRead);
  if (obj.exists()) {
    return obj.data();
  } else {
    return null;
  }
}

export async function setBetaRead(
  battleId,
  userId,
  name,
  email,
  contest,
  timezone,
  genre,
  feedbackType,
  deleted = false
) {
  const refBetaRead = doc(db, "betaReadSignUp", userId);
  await setDoc(refBetaRead, {
    userId,
    name,
    email,
    contest,
    timezone,
    genre,
    feedbackType,
    battleGenre: battleId ? battleId + "_" + genre : "",
    createdDate: Timestamp.now(),
    deleted,
  });
}

export async function updateComment(commentId, comment) {
  const refComment = doc(db, "uploadedStoryComments", commentId);
  await updateDoc(refComment, {
    comment: JSON.parse(JSON.stringify(comment)),
    editedDate: Timestamp.now(),
  });
}

export async function deleteComment(
  commentId,
  notMyStoryButICanDelete,
  storyId,
  reason
) {
  try {
    if (notMyStoryButICanDelete) {
      await deleteUploadedStoryComment({ commentId, storyId });
    } else {
      const refComment = doc(db, "uploadedStoryComments", commentId);
      if (reason) {
        await updateDoc(refComment, {
          deleted: true,
          reason,
        });
      } else {
        await updateDoc(refComment, {
          deleted: true,
        });
      }
      return true;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function createForumDiscussion(
  userId,
  topicTitle,
  messageContent,
  listOfTaggedUsers
) {
  const topicId = createUUID();
  const now = Timestamp.now();
  const refForumTopic = doc(db, "forumTopics", topicId);
  await setDoc(refForumTopic, {
    topicId,
    userId,
    topicTitle,
    createdDate: now,
    deleted: false,
    lastReplyDate: now,
  });
  await createForumMessage(userId, topicId, messageContent, listOfTaggedUsers);
  return topicId;
}

export async function createForumMessage(
  userId,
  parentId,
  messageContent,
  listOfTaggedUsers,
  replyToMessageId = "",
  isInlineReply = false
) {
  const messageId = createUUID();
  const now = Timestamp.now();
  const refForumMessage = doc(db, "forumMessages", messageId);
  await setDoc(refForumMessage, {
    parentId,
    messageId,
    userId,
    createdDate: now,
    editedDate: now,
    deleted: false,
    replyToMessageId,
    isInlineReply,
    taggedUsers: listOfTaggedUsers,
    content: JSON.parse(JSON.stringify(messageContent)),
    hasReplies: false,
  });
}

export async function getTopic(topicId) {
  const snap = await getDoc(doc(db, "forumTopics", topicId));
  if (snap.exists) {
    return snap.data();
  } else {
    return null;
  }
}

export async function fetchTopics(lastReplyDate) {
  let q;
  if (lastReplyDate) {
    q = query(
      collection(db, "forumTopics"),
      where("deleted", "==", false),
      orderBy("lastReplyDate", "desc"),
      startAfter(lastReplyDate),
      limit(DEFAULT_FORUM_LIST_SIZE + 1)
    );
  } else {
    q = query(
      collection(db, "forumTopics"),
      where("deleted", "==", false),
      orderBy("lastReplyDate", "desc"),
      limit(DEFAULT_FORUM_LIST_SIZE + 1)
    );
  }
  const tempArray = await getQueryAsArray(q);
  if (tempArray?.length) {
    return tempArray;
  } else {
    return [];
  }
}

export async function getReply(messageId) {
  const snap = await getDoc(doc(db, "forumMessages", messageId));
  if (snap.exists) {
    return snap.data();
  } else {
    return null;
  }
}

export async function fetchReplies(topicId, createdDate, fetchInline = false) {
  let q;
  if (createdDate) {
    q = query(
      collection(db, "forumMessages"),
      where("deleted", "==", false),
      where("parentId", "==", topicId),
      where("isInlineReply", "==", fetchInline),
      orderBy("createdDate", "asc"),
      startAfter(createdDate),
      limit(DEFAULT_REPLY_LIST_SIZE + 1)
    );
  } else {
    q = query(
      collection(db, "forumMessages"),
      where("deleted", "==", false),
      where("parentId", "==", topicId),
      where("isInlineReply", "==", fetchInline),
      orderBy("createdDate", "asc"),
      limit(DEFAULT_REPLY_LIST_SIZE + 1)
    );
  }
  const tempArray = await getQueryAsArray(q);
  if (tempArray?.length) {
    return tempArray;
  } else {
    return [];
  }
}

export async function deleteReply(messageId, reason) {
  const refForumMessage = doc(db, "forumMessages", messageId);
  if (reason) {
    await updateDoc(refForumMessage, {
      deleted: true,
      reason,
    });
  } else {
    await updateDoc(refForumMessage, {
      deleted: true,
    });
  }
}

export async function updateReply(messageId, content) {
  const refForumMessage = doc(db, "forumMessages", messageId);
  await updateDoc(refForumMessage, {
    content: JSON.parse(JSON.stringify(content)),
    editedDate: Timestamp.now(),
  });
}

export async function deleteTopic(topicId, topicTitle, reason) {
  const refForumTopic = doc(db, "forumTopics", topicId);
  // note: because of lazy rules, we must pass in the topic title
  // as well as the deleted flag
  if (reason) {
    await updateDoc(refForumTopic, {
      topicTitle,
      deleted: true,
      reason,
    });
  } else {
    await updateDoc(refForumTopic, {
      topicTitle,
      deleted: true,
    });
  }
}

export async function updateTopicTitle(topicId, topicTitle) {
  const refForumTopic = doc(db, "forumTopics", topicId);
  // note: because of lazy rules, we must pass in the topic title
  // as well as the deleted flag
  await updateDoc(refForumTopic, {
    topicTitle,
    deleted: false,
  });
}
