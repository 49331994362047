import "../../styles/everythingQuill.css";
import "../../styles/everythingQuillQueries.css";
import React, { useEffect, useRef, useState } from "react";
import MyQuillWrapper from "./MyQuillWrapper";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/loadinganimation.json";
import {
  attemptToSubmitStory,
  requestToReadBattleStory,
} from "../../config/firebase";
import { cloneContentWarningObjectFromServer } from "../../publicFunctions/debriefHandler";
import ContentWarningSelector from "../ContentWarningSelector";

const MAXIMUM_TITLE_CHARACTERS = 110;

function getMinimumWordCount(maxWordCount) {
  if (maxWordCount === 250) {
    return 150;
  } else if (maxWordCount === 500) {
    return 300;
  } else {
    return Math.floor(maxWordCount / 2);
  }
}

function SubmitStory({
  lockedInInfo,
  wordLimit,
  savedStoryId,
  battleId,
  refreshGameState,
}) {
  const [storyContent, setStoryContent] = useState(null);
  const [storyTitle, setStoryTitle] = useState("");
  const [contentWarningObject, setContentWarningObject] = useState(null);
  const [isWorking, setIsWorking] = useState(savedStoryId ? true : false);
  const [changesMade, setChangesMade] = useState(false);
  const [message, setMessage] = useState(
    savedStoryId ? "Please wait while we fetch your story..." : ""
  );
  const [storyId, setStoryId] = useState("");

  const myCounter = useRef(null);
  //console.log(getMyWordCount(myCounter));

  const refreshWithSavedStoryDetails = (
    refreshedTitle,
    refreshedStoryContent,
    refreshedContentWarningObject
  ) => {
    setStoryTitle(refreshedTitle);
    setStoryContent(refreshedStoryContent);
    setContentWarningObject(
      refreshedContentWarningObject
        ? cloneContentWarningObjectFromServer(refreshedContentWarningObject)
        : null
    );
    setStoryId(savedStoryId);
    setChangesMade(false);
    setIsWorking(false);
  };

  // we want to be careful about not wiping the peron's story from the browser
  // and only potentionally do after a save and the storyId has changed
  useEffect(() => {
    if (savedStoryId !== storyId) {
      // new story, so refresh contents
      requestToReadBattleStory({ battleId, storyId: savedStoryId })
        .then((results) => {
          if (results.data.success) {
            const tempContentWarningObject = results.data.story
              .contentWarningObject
              ? results.data.story.contentWarningObject
              : null;
            refreshWithSavedStoryDetails(
              results.data.story.storyTitle,
              results.data.story.storyContent,
              tempContentWarningObject
            );
          }
        })
        .catch(() => {
          // try again
          requestToReadBattleStory({ battleId, storyId: savedStoryId })
            .then((results) => {
              if (results.data.success) {
                const tempContentWarningObject = results.data.story
                  .contentWarningObject
                  ? results.data.story.contentWarningObject
                  : null;
                refreshWithSavedStoryDetails(
                  results.data.story.storyTitle,
                  results.data.story.storyContent,
                  tempContentWarningObject
                );
              }
            })
            .catch((err) => {
              const currentMessage = message;
              if (storyContent) {
                // user was submitting story and not trying to load for first time
                setMessage(
                  currentMessage +
                    " ... After successfully " +
                    "submitting your story, we attempted to retrieve your " +
                    "saved story and failed. Just in case, maybe copy + paste " +
                    "your story into another document before refreshing the page." +
                    " If you refresh the page and your story is there with any " +
                    "changes you made, then you can ignore this message. " +
                    "Otherwise, email us at admin@writingbattle.com and we " +
                    "will investigate. Thank you."
                );
              } else {
                setMessage(
                  "Sorry, we attempted to retriev your story and something went wrong. " +
                    "Please try refreshing the page in a few minutes."
                );
              }
            });
        });
    }
  }, [savedStoryId]);

  const onStoryTitleChange = (event) => {
    setChangesMade(true);
    setStoryTitle(event.target.value);
    setMessage("");
  };

  const onContentWarningChange = (event) => {
    setChangesMade(true);
    setMessage("");
  };

  const storyContentHandler = (newValue) => {
    if (newValue) {
      // changes aren't made on load
      if (storyContent) setChangesMade(true);
      setStoryContent(newValue);
      setMessage("");
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    // check word count
    const tempWordCount = getMyWordCount(myCounter);
    const minWords = getMinimumWordCount(wordLimit);
    if (tempWordCount < minWords) {
      const underBy = minWords - tempWordCount;
      setMessage(
        "Error. Your story was not submitted. " +
          "As stated in the Official Rules, the minimum word count is " +
          minWords +
          " words. You are under by " +
          underBy.toString(10) +
          " words. You must be at or above the minimum count " +
          "in order to submit a story. Please add at least " +
          underBy.toString(10) +
          " words and try submitting again."
      );
      return;
    } else if (tempWordCount > wordLimit) {
      const overBy = tempWordCount - wordLimit;
      setMessage(
        "Error. Your story was not submitted. You are over by " +
          overBy.toString(10) +
          " words. You must be at or under the word limit " +
          "in order to submit a story. You can be exactly " +
          wordLimit +
          " words. Please trim at least " +
          overBy.toString(10) +
          " words and try submitting again."
      );
      return;
    }
    // check story min and max title length
    const tempStoryTitle = storyTitle;
    if (!tempStoryTitle) {
      setMessage(
        "Error. Your story was not submitted. " +
          "You must enter a title for your story."
      );
      return;
    } else if (tempStoryTitle.length > MAXIMUM_TITLE_CHARACTERS) {
      setMessage(
        "Error. Your story was not submitted. " +
          "Your title must not exceed " +
          MAXIMUM_TITLE_CHARACTERS.toString(10) +
          " characters."
      );
      return;
    }

    // check if there is any content
    const tempStoryContent = storyContent;
    if (!tempStoryContent) {
      setMessage(
        "Error. Your story was not submitted. " +
          "There's seems to be something wrong. " +
          "Please copy and paste your story into another document, " +
          "refresh the page, then try again. If you continue to " +
          "experience these issues, then please email " +
          "admin@writingbattle.com with the title of your story " +
          "and its content. Sorry for the inconvenience."
      );
      return;
    }
    setIsWorking(true);
    let tempContentWarning = "";
    if (contentWarningObject) {
      tempContentWarning = contentWarningObject.print();
    }
    const tempContentWarningObject = contentWarningObject
      ? contentWarningObject
      : null;
    attemptToSubmitStory({
      battleId,
      storyTitle: tempStoryTitle,
      storyContent: tempStoryContent,
      contentWarning: tempContentWarning,
      contentWarningObject: tempContentWarningObject,
    })
      .then((results) => {
        if (results.data.success) {
          refreshGameState(results.data.updatedGameState)
            .then(() => {
              setMessage(
                "Fantastic work! Your story was successfully submitted in the Battle. " +
                  "Good luck :) You may re-submit as many times as you like before the deadline." +
                  "We will always use the last draft that you submit."
              );
            })
            .catch((err) => {
              setMessage(
                "Hmmm your story seemed to be submitted but " +
                  "something went wrong trying to refresh your page. Try " +
                  "refreshing your page and just to be safe, please re-submit your story."
              );
              setIsWorking(false);
            });
        } else {
          setMessage("Error. Your story was not submitted. Please try again.");
          setIsWorking(false);
        }
      })
      .catch((err) => {
        setMessage(
          "Error. Your story was not submitted. " +
            err.message +
            ". Please try again."
        );
        setIsWorking(false);
      });
  };

  return (
    <div className="container">
      {lockedInInfo && lockedInInfo.showText ? (
        <p className="description center-text margin-bottom-sm">
          {lockedInInfo.dateText}
        </p>
      ) : (
        <div></div>
      )}
      <div className="submit-story-notes">
        <h2 className="heading-tertiary submit-story-before">
          Before you submit...
        </h2>
        <p className="description">
          &ensp;&ensp; All Word Counting algorithms differ slightly. We are
          going by the word counting algorithm implemented on this site. You
          must make sure your word limit is at or below{" "}
          <strong>{wordLimit.toString(10)}</strong> words on the{" "}
          <em>Official Word Count.</em>
        </p>
        <p className="description">
          &ensp;&ensp;Your readers will be able to see your title and prompts.
          Do not put that information in the body of your story.
          <br />
          &ensp;&ensp;
          <strong>
            Do <u>not</u> put your name anywhere.{" "}
          </strong>
          You must remain anonymous. The admins of the site know which story is
          yours based on your account information.
          <br />
          &ensp;&ensp; Lastly, by popular demand, returning participants are
          advised that loglines or brief synopses are no longer required.
        </p>
        <h2 className="heading-secondary center-text submit-story-header">
          Submit Story
        </h2>
      </div>
      <div className="editor-wrapper">
        <p className="description--smaller text-align-right">
          You may submit multiple times, but we will only consider your last
          submission.
        </p>
        <p className="description center-text submission-message bold">
          {message}
        </p>

        <div className="padding-top-bot--smaller max-width--larger">
          <ContentWarningSelector
            contentWarningObject={contentWarningObject}
            setContentWarningObject={setContentWarningObject}
            setChangesMade={setChangesMade}
            isLoading={isWorking}
          />
        </div>
        <div className="story-submit-controls register-form flex">
          <div className="flex flex--direction-column flex--gap-normal">
            <div>
              <label htmlFor="storyTitleEl" disabled={isWorking}>
                Title (*required)
              </label>
              <input
                disabled={isWorking}
                id="storyTitleEl"
                type="text"
                required
                value={storyTitle}
                onChange={onStoryTitleChange}
                className="story-title-input"
                maxLength={MAXIMUM_TITLE_CHARACTERS}
              />
            </div>
          </div>
          <button
            onClick={() =>
              window.open("/story/" + battleId + "/" + storyId, "_blank")
            }
            className="btn btn--register min-width--larger story-submission-btn"
            disabled={isWorking || changesMade || !storyId}
          >
            Preview
          </button>
          <button
            className="btn btn--register min-width--larger story-submission-btn"
            disabled={isWorking || !changesMade}
            onClick={onSubmit}
          >
            Submit
            <div className={isWorking ? "simple-loader" : "hidden"}>
              <Lottie
                loop
                animationData={loadingAnimation}
                speed={1.5}
                play
              ></Lottie>
            </div>
          </button>
        </div>
        <MyQuillWrapper
          storyContent={storyContent}
          storyContentHandler={storyContentHandler}
          isReadOnly={isWorking}
        />
        <p className="description">
          <em>Official Word Count: </em>
          <strong>
            <span ref={myCounter} id="counter">
              0
            </span>
          </strong>
        </p>
      </div>
    </div>
  );
}

function getMyWordCount(myCounter) {
  if (myCounter && myCounter.current && myCounter.current.innerHTML) {
    const rawInnerHtml = myCounter.current.innerHTML;
    const possibleNumber = parseInt(rawInnerHtml);
    if (!isNaN(possibleNumber)) {
      return possibleNumber;
    } else {
      return 0;
    }
  }
}

export default SubmitStory;
