import Reveal from "../animations/Reveal";
import JudgeCard from "./JudgeCard";

function MVWSection() {
  return (
    <>
      <div className="container">
        <Reveal type="fromBottom" rotate={true}>
          <span className="subheading">
            Win More Money By Joining More Battles
          </span>
        </Reveal>
        <Reveal type="fromBottom" rotate={true}>
          <h2 className="heading-secondary margin-bottom-md">MVW 2023 (tie)</h2>
        </Reveal>
      </div>
      <div className="container">
        <Reveal type="fromBottom" fadeIn={true}>
          <div className="flex flex--direction-column">
            <div className="mvw-grid">
              <JudgeCard
                cardImage={
                  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/pastWinners%2Fmvw2023%2Ftrey_on_card.webp?alt=media"
                }
                judgeName={"Trey Dowell"}
                accoladeImage={
                  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/trophyImages%2Fmvw_c.png?alt=media"
                }
                goToType={"Website"}
                goToLink={"https://www.treydowell.com/"}
                accolade1={"Writing Battle MVW 2023 (tie)"}
                accolade2={"The Protectors"}
                learnMoreLink={"https://www.treydowell.com/"}
                isMVW={true}
              />
              <JudgeCard
                cardImage={
                  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/pastWinners%2Fmvw2023%2Fnikki_on_card.webp?alt=media"
                }
                judgeName={"Nikki Marks"}
                accoladeImage={
                  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/trophyImages%2Fmvw_c.png?alt=media"
                }
                goToType={"Story"}
                goToLink={
                  "https://writingbattle.com/story/uploads/theoriesOfAttraction?uploadedStory&public"
                }
                accolade1={"Writing Battle MVW 2023 (tie)"}
                accolade2={"2023 Nanofiction Winner"}
                learnMoreLink={
                  "https://writingbattle.com/story/uploads/theoriesOfAttraction?uploadedStory&public"
                }
                isMVW={true}
              />
              <p className="description mvw-description">
                Trey Dowell and Nikki Marks each won $500 cash by earning the
                most points across the Battles in 2023.
                <br />
                <br />
                Writers accumulate points depending on how well their stories do
                in each Battle. We sum up each writer's best three Battles in a
                Calendar year. The "MVW" of the year gets $1,000 in cash.
                <br />
                <br />
                In 2024, an additional $1,000 will be split between the TOP TEN
                writers that were never "in the money." This is to reward
                writers that wrote some awesome stories, but just missed out on
                the cash prizes.
              </p>
            </div>
          </div>
        </Reveal>
      </div>
    </>
  );
}

export default MVWSection;
