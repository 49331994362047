import { useEffect, useState } from "react";
import SiteRule from "../../publicFunctions/clientClasses/SiteRule";
import { IonIcon } from "@ionic/react";
import { caretDownOutline, caretForwardOutline } from "ionicons/icons";
function ForumAndDebriefRules({
  rulesType,
  readOnly = true,
  changeSelectedReason,
}) {
  const [rulesArray, setRulesArray] = useState(null);
  const [renderedReadOnlyRules, setRenderedReadOnlyRules] = useState(null);
  const [renderedSelectableRules, setRenderedSelectableRules] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (rulesType) {
      const tempRulesArray = [];
      tempRulesArray.push(new SiteRule("Respect your fellow writers"));
      if (rulesType === "forum") {
        tempRulesArray.push(
          new SiteRule(
            "If currently in a Battle, you cannot reveal anything about your story (besides Genre) before Debrief opens"
          )
        );
        tempRulesArray.push(
          new SiteRule(
            "No preaching. Talk about anything other than contentious personal ideologies (politics, religion, etc.)"
          )
        );
        tempRulesArray.push(
          new SiteRule(
            "Don't single out a specific story you read or judged in a negative light"
          )
        );
        tempRulesArray.push(
          new SiteRule(
            "Don't talk about details of stories you judged (the author should not be able to guess that it's their story) before Debrief"
          )
        );
        tempRulesArray.push(
          new SiteRule(
            "Don't mention the Genre when criticizing writing/story elements on the site that you judged"
          )
        );
        tempRulesArray.push(
          new SiteRule(
            "Please look through the last couple of pages of discussions before posting a new topic because it may have been recently discussed"
          )
        );
        tempRulesArray.push(
          new SiteRule(
            "If you're going to self-promote, then the content cannot be behind a paywall or subscription/sign-up (even if it's free)"
          )
        );
      } else if (rulesType === "debrief") {
        tempRulesArray.push(
          new SiteRule(
            "Please respect the type of feedback that the author requested"
          )
        );
      }
      setRulesArray(tempRulesArray);
    }
  }, [rulesType]);

  useEffect(() => {
    if (rulesArray?.length) {
      if (readOnly) {
        const tempReadOnly = [];
        for (let i = 0; i < rulesArray.length; i++) {
          tempReadOnly.push(
            <li key={"rule" + i.toString(10)} className="description--small">
              {rulesArray[i].rule}
            </li>
          );
        }
        setRenderedReadOnlyRules(tempReadOnly);
      } else {
        const tempSelectable = [];
        for (let i = 0; i < rulesArray.length; i++) {
          const rule = rulesArray[i].rule;
          tempSelectable.push(
            <button
              key={"rule" + i.toString(10)}
              className="description--small cursor--pointer text-align-right"
              onClick={(event) => {
                event.preventDefault();
                changeSelectedReason(rule);
              }}
            >
              {rule}
            </button>
          );
        }
        setRenderedSelectableRules(tempSelectable);
      }
    }
  }, [rulesArray]);

  if (renderedReadOnlyRules?.length) {
    return (
      <div className="flex flex--direction-column padding-bottom--sm">
        <div
          role="button"
          onClick={(event) => {
            event.preventDefault();
            setIsExpanded(!isExpanded);
          }}
          className="expand-outline"
        >
          <div className="flex flex--gap-normal">
            <IonIcon
              className="battle-icon--small"
              icon={isExpanded ? caretDownOutline : caretForwardOutline}
            ></IonIcon>
            <p role="button" className="description bold">
              Forum Rules
            </p>
          </div>
          {isExpanded ? (
            <ul className="margin-left-md padding--small">
              {renderedReadOnlyRules}
            </ul>
          ) : null}
        </div>
      </div>
    );
  } else if (renderedSelectableRules?.length) {
    return (
      <div className="flex flex--direction-column flex--gap-tiny">
        <p className="description bold">Select a reason:</p>
        {renderedSelectableRules}
      </div>
    );
  } else {
    return null;
  }
}

export default ForumAndDebriefRules;
