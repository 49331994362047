function Testimonial({ participantImage, participantName, quote }) {
  return (
    <figure className="testimonial">
      <img className="testimonial-img" src={participantImage} />
      <blockquote
        className="testimonial-text"
        alt={"Photo of customer " + participantName}
      >
        {quote}
      </blockquote>
      <p className="testimonial-name">
        <>&mdash; {" " + participantName}</>
      </p>
    </figure>
  );
}

export default Testimonial;
