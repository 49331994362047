class BasicPageNavControls {
  constructor(
    defaultNavigateString,
    numParamName,
    indexParamName,
    nextIndex = null,
    pagination = null,
    alsoIndexingComments = false,
    nextCommentNumberIndex = -1
  ) {
    this.defaultNavigateString = defaultNavigateString;
    this.numParamName = numParamName;
    this.indexParamName = indexParamName;
    this.nextIndex = nextIndex;
    this.pagination = !pagination ? new Map() : pagination; // key value pairs {num, index}
    this.alsoIndexingComments = alsoIndexingComments;
    this.nextCommentNumberIndex = nextCommentNumberIndex;
  }

  incrementPageHandler(searchParams, setSearchParams) {
    const tempSearchParams = searchParams;
    if (this.nextIndex) {
      tempSearchParams.set(this.indexParamName, this.nextIndex);
    } else if (!searchParams.has(this.indexParamName)) {
      tempSearchParams.set(this.indexParamName, "none");
    }
    const currentPageNum = searchParams.has(this.numParamName)
      ? parseInt(searchParams.get(this.numParamName))
      : 1;
    const nextPageString = (currentPageNum + 1).toString(10);
    tempSearchParams.set(this.numParamName, nextPageString);

    // for the case that we're also indexing comments (for Debrief and such)
    if (this.alsoIndexingComments) {
      if (this.nextCommentNumberIndex !== -1) {
        tempSearchParams.set(
          "commentNumberIndex",
          this.nextCommentNumberIndex.toString(10)
        );
      } else if (!searchParams.has("commentNumberIndex")) {
        tempSearchParams.set("commentNumberIndex", "none");
      }
    }

    setSearchParams(tempSearchParams);
  }

  decrementPageHandler(location, navigate, searchParams, setSearchParams) {
    if (location.key === "default") {
      navigate(this.defaultNavigateString);
    } else {
      const tempSearchParams = searchParams;
      const currentPageNum = tempSearchParams.has(this.numParamName)
        ? parseInt(tempSearchParams.get(this.numParamName))
        : 1;

      // check if we are past 1
      if (currentPageNum > 1) {
        const prevPageNum = currentPageNum - 1;
        const prevPageString = prevPageNum.toString(10);
        tempSearchParams.set(this.numParamName, prevPageString);
        // check if pagination exists, and if it does then check the map
        if (this.pagination.has(prevPageString)) {
          const prevIndex = !this.alsoIndexingComments
            ? this.pagination.get(prevPageString)
            : this.pagination.get(prevPageString).index;
          tempSearchParams.set(this.indexParamName, prevIndex);
          if (this.alsoIndexingComments) {
            tempSearchParams.set(
              "commentNumberIndex",
              this.pagination.get(prevPageString).commentNumberIndex
            );
          }
        } else {
          tempSearchParams.set(this.numParamName, "1");
          tempSearchParams.set(this.indexParamName, "none");
          if (this.alsoIndexingComments) {
            tempSearchParams.set("commentNumberIndex", "none");
          }
        }
      } else {
        tempSearchParams.set(this.numParamName, "1");
        tempSearchParams.set(this.indexParamName, "none");
        if (this.alsoIndexingComments) {
          tempSearchParams.set("commentNumberIndex", "none");
        }
      }
      setSearchParams(tempSearchParams);
    }
  }

  goToPage(page, index, searchParams, setSearchParams, commentNumberIndex) {
    const tempSearchParams = searchParams;
    tempSearchParams.set(this.numParamName, page);
    tempSearchParams.set(this.indexParamName, index);
    if (this.alsoIndexingComments) {
      tempSearchParams.set("commentNumberIndex", commentNumberIndex);
    }
    setSearchParams(tempSearchParams);
  }

  mapStalePaginationThenClone(stalePaginationArray) {
    const tempMap = new Map();
    tempMap.set("1", "none");
    for (let i = 1; i < stalePaginationArray.length; i++) {
      const pageObj = stalePaginationArray[i];
      tempMap.set(pageObj.page, pageObj.index);
    }

    return new BasicPageNavControls(
      this.defaultNavigateString,
      this.numParamName,
      this.indexParamName,
      this.nextIndex,
      tempMap
    );
  }

  mapStaleDebriefPaginationThenClone(stalePaginationArray) {
    const tempMap = new Map();
    for (let i = 0; i < stalePaginationArray.length; i++) {
      const pageObj = stalePaginationArray[i];
      tempMap.set(pageObj.page, pageObj);
    }

    return new BasicPageNavControls(
      this.defaultNavigateString,
      this.numParamName,
      this.indexParamName,
      this.nextIndex,
      tempMap
    );
  }
}

export default BasicPageNavControls;
