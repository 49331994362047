import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  capturePaymentforOrder,
  createPayPalOrder,
  createStripePaymentIntent,
} from "../../config/firebase";
import "../../styles/joinBattle.css";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { formatPriceWithCurrency } from "../../publicFunctions/publicHelpers/helpers";
import { useEffect, useState } from "react";
import { extractItemsToPurchaseFromBattles } from "../../publicFunctions/publicBattleHandler";
import LoadingSection from "../LoadingSection";
import StripeCheckout from "./StripeCheckout";

function Checkout({ checkoutInfo, closeCheckout }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if (
      checkoutInfo?.publishableStripeKey &&
      checkoutInfo.isStripe &&
      checkoutInfo.selectedBattles?.length &&
      !stripePromise
    ) {
      setStripePromise(loadStripe(checkoutInfo.publishableStripeKey));
      createStripePaymentIntent({
        itemsToPurchase: extractItemsToPurchaseFromBattles(
          checkoutInfo.selectedBattles
        ),
        amountFromClient: checkoutInfo.amount,
        creditToUseFromClient: checkoutInfo.creditToUse,
        currency: checkoutInfo.currency,
        thisIsAGift: checkoutInfo.thisIsAGift,
      })
        .then(({ data }) => {
          setClientSecret(data.clientSecret);
        })
        .catch((err) => {
          console.log(err); // should maybe try again?
        });
    } else if (
      stripePromise &&
      checkoutInfo &&
      (!checkoutInfo.isStripe || !checkoutInfo.publishableStripeKey)
    ) {
      setStripePromise(null);
      setClientSecret("");
    }
  }, [checkoutInfo]);

  return (
    <div className="checkout-wrapper fade-in">
      <div className="checkout-frame">
        <button
          onClick={() => closeCheckout(false)}
          className="general-icon-box general-icon-box--close"
        >
          <IonIcon className="general-icon gray" icon={closeOutline} />
        </button>
        <div className="checkout-title-and-price flex">
          <h1 className="heading-secondary checkout-title">Checkout</h1>
          <p className="jb-description">
            {formatPriceWithCurrency(
              checkoutInfo.currency,
              checkoutInfo.amount
            )}
          </p>
        </div>
        {errorMessage ? (
          <p className="jb-description red">Error. {errorMessage}</p>
        ) : null}
        {!checkoutInfo?.isStripe ? (
          <>
            <p className="jb-description center-text margin-bottom-sm">
              <strong>Please select one of the following options:</strong>
              <br />
              <span>
                <em>
                  Note: Venmo and some debit cards may not work. Please only use
                  PayPal or credit card.
                </em>
              </span>
            </p>
            <div className="paypal-wrapper">
              <PayPalScriptProvider
                options={{
                  "client-id": checkoutInfo.clientId,
                  "data-client-token": checkoutInfo.clientToken,
                  currency: checkoutInfo.currency,
                }}
              >
                <PayPalButtons
                  createOrder={(data, actions) => {
                    return createPayPalOrder({
                      itemsToPurchase: extractItemsToPurchaseFromBattles(
                        checkoutInfo.selectedBattles
                      ),
                      amountFromClient: checkoutInfo.amount,
                      creditToUseFromClient: checkoutInfo.creditToUse,
                      currency: checkoutInfo.currency,
                      thisIsAGift: checkoutInfo.thisIsAGift,
                    })
                      .then((result) => {
                        return result.data.id;
                      })
                      .catch((err) => {
                        setErrorMessage(err.message);
                      });
                  }}
                  onApprove={(data, actions) => {
                    return capturePaymentforOrder({ orderId: data.orderID })
                      .then((result) => {
                        closeCheckout(true, result.data);
                      })
                      .catch((err) => {
                        setErrorMessage(err.message);
                      });
                  }}
                />
              </PayPalScriptProvider>
            </div>
          </>
        ) : stripePromise && clientSecret ? (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <StripeCheckout closeCheckout={closeCheckout} />
          </Elements>
        ) : (
          <LoadingSection />
        )}
      </div>
    </div>
  );
}

export default Checkout;
