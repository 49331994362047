import { auth, createAccount, db } from "../config/firebase";
import {
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getQueryAsArray } from "./publicHelpers/helpers";

export async function logInWithEmailAndPassword(email, password) {
  await signInWithEmailAndPassword(auth, email, password);
}

export async function logOut() {
  await signOut(auth);
}

export async function sendForgotPasswordEmail(email) {
  await sendPasswordResetEmail(auth, email);
}

export async function createUserAccount(
  firstName,
  lastName,
  email,
  password,
  whereHeard
) {
  await createAccount({ firstName, lastName, email, password, whereHeard });
}

export async function getMyPrivateUserInfo(userId) {
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    throw { name: "WBError", message: "Could not find private user info" };
  }
}

export async function getPublicUserInfo(userId) {
  const docRef = doc(db, "publicUsers", userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    throw { name: "WBError", message: "Could not find user" };
  }
}

export async function updateMyPrivateUserInfo(userId, updateMap) {
  try {
    const docRef = doc(db, "users", userId);
    await updateDoc(docRef, updateMap);
    return true; // success
  } catch (err) {
    return false; // not success
  }
}

export async function getLatestCurrencyConversions() {
  try {
    const docRef = doc(db, "currencies", "latest");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().conversions;
    } else {
      throw { name: "WBError", message: "Could not find user" };
    }
  } catch (err) {
    return false; // not success
  }
}

export async function getMyKnockoutPoints(year, userId) {
  const q = query(
    collection(db, "knockOutPoints"),
    where("year", "==", year),
    where("userId", "==", userId),
    limit(1)
  );
  const tempArray = await getQueryAsArray(q);
  if (tempArray?.length) {
    return tempArray[0];
  } else {
    return null;
  }
}
