import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

function ProfileDisplayTrophy({
  editable,
  selected,
  trophyImg,
  trophyClickedHandler,
  trophyBankItem,
  unselectable,
}) {
  let trophyClass =
    unselectable || selected ? "trophy" : "trophy cursor--pointer";

  if (selected && (!trophyBankItem || trophyImg === "null-trophy")) {
    trophyClass += " trophy-selected";
  } else if (selected && trophyBankItem) {
    trophyClass += " trophy-selected green-background";
  } else if (editable) {
    trophyClass += " trophy-editable";
  } else if (trophyBankItem) {
    trophyClass += " trophy-bank-item";
  }

  // background
  if (trophyImg === "null-trophy") {
    trophyClass += " red-background";
  } else if (unselectable) {
    trophyClass += " gray-background";
  }

  return (
    <div onClick={trophyClickedHandler} className={trophyClass}>
      {trophyImg !== "null-trophy" ? (
        <img className="trophy-img" src={trophyImg} />
      ) : (
        <IonIcon className="null-trophy white" icon={closeOutline} />
      )}
    </div>
  );
}

export default ProfileDisplayTrophy;
