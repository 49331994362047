function BattleTypeExplanation({
  image,
  title,
  listOfItems,
  listOfBattleDescriptions,
}) {
  return (
    <div className="container">
      <div className="battle-type-container">
        <img className="battle-type-img" src={image} />
        <p className="description--larger bold center-text underline">
          {title}
        </p>
        <ul className="battle-type-list description text-dec--none">
          {listOfItems}
        </ul>
        <div className="battle-face-description-list">
          {listOfBattleDescriptions}
        </div>
      </div>
    </div>
  );
}

export default BattleTypeExplanation;
