import { getBattleSwatchByBattleId } from "../publicFunctions/publicHelpers/homeHelper";

function BattleHeading({ battleId, battleTitle }) {
  const battleSwatch = getBattleSwatchByBattleId(battleId);
  return (
    <div className="battle-heading--wrapper">
      <div
        style={{
          backgroundImage: `url(${battleSwatch.halfLandscape})`,
        }}
        className="battle-heading"
      >
        <div className="battle-heading--text ">
          <h2
            style={{ color: battleSwatch.colorDarkest }}
            className="heading-secondary white-shadow--huge margin--none"
          >
            {battleTitle}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default BattleHeading;
