class CheckoutInfo {
  constructor() {
    this.clientId = "";
    this.clientToken = "";
    this.currency = "";
    this.selectedBattles = [];
    this.amount = 0;
    this.creditToUse = 0;
    this.finishedCheckout = false;
    this.successfulCheckout = false;
    this.thisIsAGift = false;
    this.isStripe = false;
    this.publishableStripeKey = "";
  }

  showCheckout() {
    return (this.isStripe &&
      this.currency &&
      this.selectedBattles.length > 0 &&
      this.amount &&
      this.publishableStripeKey) ||
      (this.clientId &&
        this.clientToken &&
        this.currency &&
        this.selectedBattles.length > 0 &&
        this.amount)
      ? true
      : false;
  }
}

export default CheckoutInfo;
