import "../styles/battle.css";
import "../styles/battleQueries.css";
import { useEffect, useState } from "react";
import LoadingSection from "../components/LoadingSection";
import PricingSection from "../components/PricingSection";
import BattleBody from "../components/battle/BattleBody";
import {
  determineBattleToLoad,
  getBattleInfo,
} from "../publicFunctions/publicHelpers/helpers";
import SEO from "../components/SEO";

function Battle({
  battles,
  openLogInHandler,
  userInfo,
  gameStates,
  alreadyJoinedMap,
  refreshGameState,
}) {
  const [loadedBattle, setLoadedBattle] = useState({});
  const [loadedGameState, setLoadedGameState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (battles && battles.length) {
      const battle = determineBattleToLoad(battles, alreadyJoinedMap);
      if (alreadyJoinedMap.has(battle.battleId) && gameStates?.length) {
        for (let i = 0; i < gameStates.length; i++) {
          if (battle.battleId === gameStates[i].battleId) {
            if (battle.battleId.includes("SANDBOX")) {
              // then also inject the battle with the pseudo game state
              battle.state = gameStates[i].pseudoState;
            }
            setLoadedGameState(gameStates[i]);
            break;
          }
        }
      } else if (!alreadyJoinedMap.has(battle.battleId) && loadedGameState) {
        // the person likely logged out, so unset the loadedgame state
        setLoadedGameState(null);
      }
      const battleInfo = getBattleInfo(battle);
      setLoadedBattle(battleInfo);
      setIsLoading(false);
    }
  }, [battles, alreadyJoinedMap]);

  return (
    <div>
      <SEO
        title="Battle | Writing Battle"
        description="Redraw prompts, submit your short story, and judge Duels."
        name="Writing Battle"
        type="website"
      />
      <div className="hero-background">
        {!isLoading ? (
          <BattleBody
            battleInfo={loadedBattle}
            loadedGameState={loadedGameState}
            userInfo={userInfo}
            refreshGameState={refreshGameState}
            openLogInHandler={openLogInHandler}
          />
        ) : (
          <LoadingSection />
        )}
      </div>
      <PricingSection
        battles={battles}
        hideTitle={true}
        openLogInHandler={openLogInHandler}
        userInfo={userInfo}
        alreadyJoinedMap={alreadyJoinedMap}
      />
    </div>
  );
}

export default Battle;
