import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/simpleLoading.json";

function StripeCheckout({ closeCheckout }) {
  const [message, setMessage] = useState("");
  const [cardFieldsFilledOut, setCardFieldsFilledOut] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements || !cardFieldsFilledOut) {
      return;
    }
    setIsProcessing(true);
    console.log(`${window.location.origin}/thank-you`);

    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/thank-you`,
      },
      redirect: "if_required",
    });
    if (response?.error) {
      setMessage(response.error.message);
      setIsProcessing(false);
    } else if (response?.paymentIntent?.status === "succeeded") {
      setMessage("Payment Succeeded!");
      // todo: show thank you page after timer
      closeCheckout(true, true);
    } else {
      setMessage(
        "Hmmmm something may have gone wrong. Please check your card statement to see if the payment went through before trying again."
      );
      closeCheckout(true, false);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement
        onChange={(event) => {
          if (event?.complete) {
            setCardFieldsFilledOut(true);
          } else {
            setCardFieldsFilledOut(false);
          }
        }}
      />
      <br />
      <p className="description center-text">{message}</p>
      <button
        className="btn btn--register checkout-btn"
        disabled={isProcessing || !cardFieldsFilledOut}
      >
        Pay Now
        <div className={isProcessing ? "simple-loader" : "hidden"}>
          <Lottie loop animationData={loadingAnimation} speed={1.5} play />
        </div>
      </button>
    </form>
  );
}

export default StripeCheckout;
