import {
  convertTimestampToObject,
  getSimpleTimeInUserTimeZone,
} from "../../publicFunctions/publicHelpers/helpers";
import {
  getCardImageUrlByCard,
  getFlagImageUrlByFlagFileName,
} from "../../publicFunctions/publicHelpers/imageUrlBuilder";
import BasicUserInfo from "../BasicUserInfo";
import DefaultProfilePicture from "../../img/profile_picture.png";

function DebriefStory({ story, lastCommentUser, publicUser, debriefMeta, i }) {
  // cards
  const renderedCards = [];
  for (let j = 0; j < story.tinyCardImages?.length; j++) {
    renderedCards.push(
      <img
        className="debrief-genre-title-card"
        key={i.toString + "_" + j.toString(10) + "_card"}
        src={story.tinyCardImages[j]}
      />
    );
  }
  if (!renderedCards.length && story.cards) {
    renderedCards.push(
      <img
        className="debrief-genre-title-card"
        key={"genre_" + story.storyId + "_img"}
        src={getCardImageUrlByCard(story.cards.genre, true)}
      />,
      <img
        className="debrief-genre-title-card"
        key={"prompt1_" + story.storyId + "_img"}
        src={getCardImageUrlByCard(story.cards.prompt1, true)}
      />,
      <img
        className="debrief-genre-title-card"
        key={"prompt2_" + story.storyId + "_img"}
        src={getCardImageUrlByCard(story.cards.prompt2, true)}
      />
    );
  }

  return (
    <div className="debrief-story-container" role="button">
      {publicUser ? (
        <div className="flex flex--direction-column align-items--center">
          <BasicUserInfo publicUserInfo={publicUser} />
          {story.houseId && story.flagFileName ? (
            <a
              role="button"
              href={
                "/debrief/?page=houses&houseId=" +
                story.houseId +
                "&battleId=" +
                debriefMeta.battleId
              }
            >
              <img
                className="debrief-flag--small"
                src={getFlagImageUrlByFlagFileName(story.flagFileName)}
              />
            </a>
          ) : null}
        </div>
      ) : (
        <div></div>
      )}
      <a
        className="debrief-clickable-container"
        role="button"
        href={
          "/story/" +
          (story.battleId === "tavern" ? "tavern" : "debrief") +
          "/" +
          story.storyId +
          "?uploadedStory"
        }
      >
        <div className="flex flex--direction-column">
          <p className="description--small">{story.genre}</p>
          <div className="flex align-items--center">{renderedCards}</div>
          <p className="description--large bold">{story.storyTitle}</p>
          {publicUser?.name ? (
            <p className="description">{"by " + publicUser.name}</p>
          ) : null}
        </div>
        <div
          className={
            "flex flex--direction-column " +
            "align-items--end break-long-words justify-content--end"
          }
        >
          {lastCommentUser ? (
            <div className="flex flex--direction-column align-items--end justify-content--end">
              {story.numberOfComments && story.numberOfComments > 1 ? (
                <p className="description--smaller text-align-right">
                  {story.numberOfComments.toString(10)} comments
                </p>
              ) : story.numberOfComments && story.numberOfComments === 1 ? (
                <p className="description--smaller text-align-right">
                  {story.numberOfComments.toString(10)} comment
                </p>
              ) : null}
              <p className="description--smaller text-align-right">
                Last comment by:{" "}
              </p>

              <img
                className="basic-user-info--img-small"
                src={
                  lastCommentUser?.profilePic
                    ? lastCommentUser.profilePic
                    : DefaultProfilePicture
                }
              />
              <p className="description--smaller text-align-right mobile-smaller-text">
                {lastCommentUser?.name ? lastCommentUser?.name : ""}
              </p>
              {story.lastCommentDate ? (
                <p className="description--smaller text-align-right mobile-smaller-text">
                  {getSimpleTimeInUserTimeZone(
                    convertTimestampToObject(story.lastCommentDate)
                  )}
                </p>
              ) : null}
            </div>
          ) : (
            <p className="description--smaller text-align-right">
              Be the first to comment on this story!
            </p>
          )}
        </div>
      </a>
    </div>
  );
}

export default DebriefStory;
