import { useEffect, useState } from "react";
import { subscribeToTopic } from "../../config/firebase";

function Subscribe({ topicId, userId }) {
  const [loading, setLoading] = useState(true);
  const [subscribed, setSubscribed] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    // We want to check if the user is currently subscribed
    subscribeToTopic({ topicId, checkingIfSubscribed: true })
      .then(({ data }) => {
        const tempSubscribed = data.currentySubscribed ? true : false;
        setSubscribed(tempSubscribed);
        setLoading(false);
        setMessage("");
      })
      .catch((err) => {
        console.log(err);
        setMessage("Hmmm... Please refresh page.");
      });
  }, []);

  const toggleSubscribe = async (subscribeMe) => {
    if (loading) {
      return;
    }
    setLoading(true);
    subscribeToTopic({
      topicId,
      checkingIfSubscribed: false,
      iWantToBeSubscribed: subscribeMe,
    })
      .then(({ data }) => {
        const tempSubscribed = data.currentySubscribed ? true : false;
        setSubscribed(tempSubscribed);
        setMessage("");
      })
      .catch((err) => {
        console.log(err);
        setMessage("Hmmm... That didn't seem to work.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex flex--gap-smaller padding">
      <input
        required
        id="subscribedCheckbox"
        className="checkbox"
        type="checkbox"
        value="subscribed"
        checked={subscribed}
        disabled={loading}
        onClick={(event) => {
          toggleSubscribe(!subscribed);
        }}
      />
      <label htmlFor="subscribedCheckbox" className="description--small">
        {loading && !message
          ? "One sec..."
          : message
          ? message
          : subscribed
          ? "Subscribed!"
          : "Subscribe to Discussion"}
      </label>
    </div>
  );
}

export default Subscribe;
