import { useState } from "react";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline, caretDownOutline } from "ionicons/icons";

function QuestionAndAnswer({ question, answer, widthOverride }) {
  const [isOpen, setIsOpen] = useState(false);

  let arrow;

  if (isOpen)
    arrow = <IonIcon className="faq-arrow-icon" icon={caretDownOutline} />;
  else
    arrow = <IonIcon className="faq-arrow-icon" icon={caretForwardOutline} />;

  const clickedHandler = (e) => {
    setIsOpen(!isOpen);
  };

  let answerClass = "answer ";
  if (widthOverride) {
    answerClass += "answer--width-override ";
  }

  return (
    <div onClick={clickedHandler} className="question-and-answer">
      <div className={isOpen ? "description bold" : "description question"}>
        <div className="question-container">
          {arrow}
          <p>{question}</p>
        </div>
      </div>
      <div
        className={
          isOpen ? answerClass + "italic description" : answerClass + "hidden"
        }
      >
        {answer}
      </div>
    </div>
  );
}

export default QuestionAndAnswer;
