class UserInfo {
  constructor() {
    this.displayName = "";
    this.photoURL = "";
    this.userId = "";
    this.isActive = false;
    this.email = "";
    this.pending = true;
  }

  clone() {
    const tempUserInfo = new UserInfo();
    tempUserInfo.displayName = this.displayName;
    tempUserInfo.photoURL = this.photoURL;
    tempUserInfo.userId = this.userId;
    tempUserInfo.isActive = this.isActive;
    tempUserInfo.email = this.email;
    tempUserInfo.pending = this.pending;
    return tempUserInfo;
  }

  isInitialized() {
    return this.userId ? true : false;
  }

  isLoggedIn() {
    return this.userId ? true : false;
  }

  isStillCheckingLogin() {
    return this.pending;
  }
}

export default UserInfo;
