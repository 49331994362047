import { IonIcon } from "@ionic/react";
import Reveal from "../animations/Reveal";
import { openOutline } from "ionicons/icons";

function SimpleCallToAction({
  imageUrl1,
  imageUrl2,
  titleText,
  whiteFont,
  buttonText,
  bodyText,
  goToLink,
}) {
  return (
    <div className="container padding-top-bot--sm">
      <div className="flex flex--gap-normal justify-content--center align-items--center simple-cta-direction-mobile">
        <img src={imageUrl1} className="simple-cta-img--left" alt="" />
        <br />
        <Reveal type="fromBottom" fadeIn={true} animated={true}>
          <div className="flex flex--direction-column align-items--center center-text">
            {titleText ? (
              <p className="description--large bold">{titleText}</p>
            ) : null}
            {bodyText ? (
              <p className={"description" + (whiteFont ? " white" : "")}>
                {bodyText}
              </p>
            ) : null}
            <br />
            <br />
            <a href={goToLink} target="_blank" className="btn btn--outline">
              <div className="flex justify-conent--center align-items--center flex--gap-tiny">
                {buttonText}
                <IonIcon icon={openOutline} />
              </div>
            </a>
            <br />
            <br />
          </div>
        </Reveal>
        <img src={imageUrl2} className="simple-cta-img" alt="" />
      </div>
    </div>
  );
}

export default SimpleCallToAction;
