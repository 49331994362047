import { useEffect, useRef, useState } from "react";
import { IonIcon } from "@ionic/react";
import { notificationsOutline } from "ionicons/icons";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/loadinganimation.json";
import NotificationList from "./NotificationsList";
import { notificationsClicked } from "../../publicFunctions/publicProfileHandler";

function Notifications({
  userInfo,
  hideOnMobile,
  isLoggedIn,
  notificationsObject,
  newNotifications,
  setNewNotifications,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const notificationRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !notificationRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (newNotifications) {
      // we want to update the notifications
      notificationsClicked(userInfo.userId).catch((err) => {
        console.log(err);
      });
      setNewNotifications(false);
    }
  };
  return (
    <div
      className={
        "notifications-bell" +
        (hideOnMobile
          ? " notifications-bell--hideMobile"
          : " notifications-bell--onlyShowMobile")
      }
    >
      <div
        className="bell-wrapper"
        ref={notificationRef}
        onClick={toggleDropdown}
      >
        <IonIcon className="social-icon" icon={notificationsOutline} />
        {newNotifications ? <div className="bell-new-notifications" /> : null}
      </div>
      {isOpen ? (
        <div className="relative-wrapper">
          <div ref={dropdownRef} className="notifications">
            {isLoggedIn === true ? (
              notificationsObject ? (
                <NotificationList notificationObject={notificationsObject} />
              ) : (
                <p className="description--smaller">No new notifications...</p>
              )
            ) : isLoggedIn === "Unknown" ||
              (isLoggedIn === true && notificationsObject === null) ? (
              <div className="flex center-flex justify-content--center">
                <Lottie
                  loop
                  animationData={loadingAnimation}
                  play
                  style={{ width: 150, height: 150 }}
                ></Lottie>
              </div>
            ) : (
              <p className="description--smaller">Please Log In</p>
            )}
          </div>
          <div className="notifications-pointer" />
        </div>
      ) : null}
    </div>
  );
}

export default Notifications;
