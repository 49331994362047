import { IonIcon } from "@ionic/react";
import { closeOutline, openOutline } from "ionicons/icons";
import BackOfCard from "../../img/back_of_card_full.webp";
import { modifyStoryLink } from "../../publicFunctions/publicHelpers/helpers";
import BattleCard from "../battle/BattleCard";
import CardState from "../../publicFunctions/clientClasses/CardState";

function PastWinnerFeatured({
  isHidden,
  closeClicked,
  featured,
  featuredCard,
}) {
  let classTemp = "past-winner-featured-section";
  if (isHidden) classTemp += " hidden";

  // prompts
  let promptImage1, promptImage2, promptImage3;
  let genreText, promptText1, promptText2;
  let cards;
  if (featured && featured.prompts && featured.prompts.length >= 3) {
    const prompts = featured.prompts;
    promptImage1 = prompts[0].image.image.url;
    genreText = prompts[0].text.toUpperCase();
    promptImage2 = prompts[1].image.image.url;
    promptText1 = prompts[1].text.toUpperCase();
    promptImage3 = prompts[2].image.image.url;
    promptText2 = prompts[2].text.toUpperCase();
  } else if (featured?.cards) {
    cards = featured.cards;
  }
  let storyUrl = featured && featured.storyUrl ? featured.storyUrl : "";
  const name = featured ? featured.name : "";
  const interview = featured ? featured.interview : [];
  const location = featured?.location ? featured.location : "";

  function formatInterview(interviewArray, name) {
    if (!interviewArray) return "";
    return interviewArray.map((qAndA, index) => {
      let followAuthorDiv = null;
      if (!qAndA.question) {
        followAuthorDiv = createAuthorInfo(qAndA, name);
      }

      return (
        <div key={index}>
          {qAndA.question ? (
            <>
              <strong>
                <p className="description">{qAndA.question}</p>
              </strong>
              <br />
              <p className="description pre-wrap">{qAndA.answer}</p>
            </>
          ) : (
            followAuthorDiv
          )}
          <br />
          <br />
          <br />
        </div>
      );
    });
  }

  // Card stuff
  const genreState = new CardState(cards?.genre?.type ? cards.genre.type : "");
  if (cards?.genre) {
    genreState.injectCardWithDataFromServer(cards.genre);
  }
  const prompt1State = new CardState(
    cards?.prompt1?.type ? cards.prompt1.type : ""
  );
  if (cards?.prompt1) {
    prompt1State.injectCardWithDataFromServer(cards.prompt1);
  }
  const prompt2State = new CardState(
    cards?.prompt2?.type ? cards.prompt2.type : ""
  );
  if (cards?.prompt2) {
    prompt2State.injectCardWithDataFromServer(cards.prompt2);
  }

  return (
    <div className={classTemp}>
      <button
        onClick={closeClicked}
        className="general-icon-box general-icon-box--close"
      >
        <IonIcon className="general-icon" icon={closeOutline} />
      </button>
      {/* featured-content-container */}
      <div className="first-stage-container padding--none">
        {/* We need to check if there are straight-up the cards here */}
        <br />
        {cards ? (
          <div className="margin-top-bottom-sm">
            <div className="battle-cards">
              <BattleCard
                cardState={genreState}
                position={0}
                requestUnlock={null}
                isWorking={false}
                cardIsBeingRedrawn={false}
                forceLockCards={true}
                makeThisAButton={true}
              />
              <BattleCard
                cardState={prompt1State}
                position={1}
                requestUnlock={null}
                isWorking={false}
                cardIsBeingRedrawn={false}
                forceLockCards={true}
                makeThisAButton={true}
              />
              <BattleCard
                cardState={prompt2State}
                position={2}
                requestUnlock={null}
                isWorking={false}
                cardIsBeingRedrawn={false}
                forceLockCards={true}
                makeThisAButton={true}
              />
            </div>
            <div className="heading-secondary margin--none center-text">
              {featured.title}
            </div>
            <div className="flex justify-content--center margin-top-bottom-sm">
              <a
                target={"_blank"}
                href={modifyStoryLink(storyUrl)}
                className="btn btn--lightBlue btn--read-story read-content"
              >
                <div className="read-content">
                  Read Story
                  <IonIcon icon={openOutline} />
                </div>
              </a>
            </div>
          </div>
        ) : (
          <>
            <div className="prompt-text-grid">
              <p className="description">{genreText}</p>
              <p className="description">&nbsp; &#x2022; &nbsp;</p>
              <p className="description">{promptText1}</p>
              <p className="description">&nbsp; &#x2022; &nbsp;</p>
              <p className="description">{promptText2}</p>
            </div>
            <div className="featured-header-wrapper">
              <div className="three-cards-wrapper">
                <img
                  className="prompt-card-img"
                  src={promptImage1 ? promptImage1 : BackOfCard}
                />
                <img
                  className="prompt-card-img"
                  src={promptImage2 ? promptImage2 : BackOfCard}
                />
                <img
                  className="prompt-card-img"
                  src={promptImage3 ? promptImage3 : BackOfCard}
                />
              </div>
              <div className="past-winners-title fade-in">
                <div className="heading-secondary close">{featured.title}</div>
                <a
                  target={"_blank"}
                  href={modifyStoryLink(storyUrl)}
                  className="btn btn--lightBlue btn--read-story read-content"
                >
                  <div className="read-content">
                    Read Story
                    <IonIcon icon={openOutline} />
                  </div>
                </a>
              </div>
            </div>
          </>
        )}
        {featuredCard ? (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginBottom: "1rem",
            }}
          >
            {featuredCard}
          </div>
        ) : null}
        <p className="heading-tertiary">
          Interview with {name}
          <span className="description">
            {location ? " (" + location + ")" : ""}
          </span>
        </p>

        <div className="featured-content-interview">
          <div
            className={
              !interview
                ? "past-winner-interview hidden"
                : "past-winner-interview"
            }
          >
            <div className="past-winner-interview-content padding--small">
              {formatInterview(interview, name)}
            </div>
          </div>
          {featured.judgePic1 ? (
            <div className="past-winner-judges">
              <div className="past-winner-judge-container">
                <img
                  className="past-winner-judge-pic"
                  src={featured.judgePic1}
                />
                <br />
                <p className="description--smaller center-text">
                  <em>{featured.judgeQuote1}</em>
                </p>
              </div>
              <div className="past-winner-judge-container">
                <img
                  className="past-winner-judge-pic"
                  src={featured.judgePic2}
                />
                <br />
                <p className="description--smaller center-text">
                  <em>{featured.judgeQuote2}</em>
                </p>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}

function createAuthorInfo({ socials, website }, name) {
  return (
    <div className="bold">
      {socials ? (
        <p className="description">You can follow {name} on:</p>
      ) : null}
      {socials
        ? socials.map((social, index) => (
            <p className="description" key={index}>
              <a target="_blank" href={social}>
                {getSocialName(social)}
              </a>
            </p>
          ))
        : null}
      {website && (
        <p className="description">
          Visit the author's website at:{" "}
          <a target="_blank" href={website}>
            {website}
          </a>
        </p>
      )}
    </div>
  );
}

function getSocialName(websiteUrl) {
  if (websiteUrl.includes("facebook")) {
    return "Facebook";
  } else if (websiteUrl.includes("twitter")) {
    return "Twitter";
  } else if (websiteUrl.includes("instagram")) {
    return "Instagram";
  } else {
    return "HERE";
  }
}

export default PastWinnerFeatured;
