import {
  pencilOutline,
  bookOutline,
  paperPlaneOutline,
  trophyOutline,
  barChartOutline,
  starOutline,
  lockClosedOutline,
  flagOutline,
  analyticsOutline,
  homeOutline,
} from "ionicons/icons";
import DetailedContainer from "./DetailedContainer";

const DUEL_EVENT_DESCRIPTION =
  "- An email will be sent to you when your Duel is ready since it is often available much earlier. We always try to have the Duel available to you as soon as possible, so you have more time.";

function DetailedBreakdown({ battleInfo, peerJudged }) {
  return (
    <div className="container">
      <div className="glance-section">
        <div className="flex flex--direction-column flex--gap-normal">
          {/* WRITE */}
          <DetailedContainer
            battleInfo={battleInfo}
            icon={pencilOutline}
            title={"Write"}
            description={
              "Receive and redraw your prompts, and write an up-to " +
              battleInfo.rawTuple.maxWords.toString(10) +
              " word short story using the prompts."
            }
            calEventStartDate={battleInfo.rawTuple.initialDate}
            calEventEndDate={battleInfo.rawTuple.submissionDate}
            calEventTitle={"Write " + battleInfo.battleTitle}
            eventDescription={battleInfo.shortDescription}
            isSubEvent={false}
            subEvents={[
              <DetailedContainer
                key="promptReveal"
                battleInfo={battleInfo}
                icon={starOutline}
                title={"Prompt Reveal"}
                description={
                  "Receive and redraw your prompts, and start writing!"
                }
                calEventStartDate={battleInfo.rawTuple.initialDate}
                calEventEndDate={null}
                isSubEvent={true}
              />,
              <DetailedContainer
                key="lockIn"
                battleInfo={battleInfo}
                icon={lockClosedOutline}
                title={"Lock-In"}
                description={
                  "Your prompts can no longer be redrawn. Last chance to join the Battle as a late entry."
                }
                calEventStartDate={battleInfo.rawTuple.lockInDate}
                calEventEndDate={null}
                isSubEvent={true}
              />,
              <DetailedContainer
                key="submitStory"
                battleInfo={battleInfo}
                icon={paperPlaneOutline}
                title={"Submit Story Deadline"}
                description={
                  "Submit an up-to " +
                  battleInfo.rawTuple.maxWords.toString(10) +
                  " word short story before the deadline."
                }
                calEventStartDate={battleInfo.rawTuple.submissionDate}
                calEventEndDate={null}
                isSubEvent={true}
              />,
            ]}
          />

          {/* READ */}
          {peerJudged ? (
            <DetailedContainer
              battleInfo={battleInfo}
              icon={bookOutline}
              title={"Read"}
              description={
                "Read a total of 10 short stories over a 3 week period. The stories will be assigned to you two-at-a-time throughout the reading period."
              }
              calEventStartDate={battleInfo.duelDates.duel1}
              calEventEndDate={
                new Date(
                  new Date(battleInfo.duelDates.duel5.getTime()).setDate(
                    battleInfo.duelDates.duel5.getDate() + 2
                  )
                )
              }
              isSubEvent={false}
              subEvents={[
                <DetailedContainer
                  key="duel1"
                  battleInfo={battleInfo}
                  icon={null}
                  title={"Duel #1"}
                  description={
                    "Read two stories and give a bit of feedback to each, then pick a winner out of the two."
                  }
                  calEventStartDate={battleInfo.duelDates.duel1}
                  calEventEndDate={
                    new Date(
                      new Date(battleInfo.duelDates.duel1.getTime()).setDate(
                        battleInfo.duelDates.duel1.getDate() + 2
                      )
                    )
                  }
                  isSubEvent={true}
                  calEventTitle={"Judge Duel #1"}
                  eventDescription={"Duel #1 " + DUEL_EVENT_DESCRIPTION}
                />,
                <DetailedContainer
                  key="duel2"
                  battleInfo={battleInfo}
                  icon={null}
                  title={"Duel #2"}
                  description={
                    "Read two stories and give a bit of feedback to each, then pick a winner out of the two."
                  }
                  calEventStartDate={battleInfo.duelDates.duel2}
                  calEventEndDate={
                    new Date(
                      new Date(battleInfo.duelDates.duel2.getTime()).setDate(
                        battleInfo.duelDates.duel2.getDate() + 2
                      )
                    )
                  }
                  isSubEvent={true}
                  calEventTitle={"Judge Duel #2"}
                  eventDescription={"Duel #2 " + DUEL_EVENT_DESCRIPTION}
                />,
                <DetailedContainer
                  key="duel3"
                  battleInfo={battleInfo}
                  icon={null}
                  title={"Duel #3"}
                  description={
                    "Read two stories and give a bit of feedback to each, then pick a winner out of the two."
                  }
                  calEventStartDate={battleInfo.duelDates.duel3}
                  calEventEndDate={
                    new Date(
                      new Date(battleInfo.duelDates.duel3.getTime()).setDate(
                        battleInfo.duelDates.duel3.getDate() + 2
                      )
                    )
                  }
                  isSubEvent={true}
                  calEventTitle={"Judge Duel #3"}
                  eventDescription={"Duel #3 " + DUEL_EVENT_DESCRIPTION}
                />,
                <DetailedContainer
                  key="duel4"
                  battleInfo={battleInfo}
                  icon={null}
                  title={"Duel #4"}
                  description={
                    "Read two stories and give a bit of feedback to each, then pick a winner out of the two."
                  }
                  calEventStartDate={battleInfo.duelDates.duel4}
                  calEventEndDate={
                    new Date(
                      new Date(battleInfo.duelDates.duel4.getTime()).setDate(
                        battleInfo.duelDates.duel4.getDate() + 2
                      )
                    )
                  }
                  isSubEvent={true}
                  calEventTitle={"Judge Duel #4"}
                  eventDescription={"Duel #4 " + DUEL_EVENT_DESCRIPTION}
                />,
                <DetailedContainer
                  key="duel5"
                  battleInfo={battleInfo}
                  icon={null}
                  title={"Duel #5"}
                  description={
                    "Read two stories and give a bit of feedback to each, then pick a winner out of the two."
                  }
                  calEventStartDate={battleInfo.duelDates.duel5}
                  calEventEndDate={
                    new Date(
                      new Date(battleInfo.duelDates.duel5.getTime()).setDate(
                        battleInfo.duelDates.duel5.getDate() + 2
                      )
                    )
                  }
                  isSubEvent={true}
                  calEventTitle={"Judge Duel #5"}
                  eventDescription={"Duel #5 " + DUEL_EVENT_DESCRIPTION}
                />,
              ]}
            />
          ) : null}

          {/* VICTORY */}
          <DetailedContainer
            battleInfo={battleInfo}
            icon={analyticsOutline}
            title={"Results"}
            description={
              "Choose to share your story on the Debrief page and get even more peer feedback while you wait for the winners to be announced and the feedback to be released."
            }
            calEventStartDate={battleInfo.debriefDate}
            calEventEndDate={battleInfo.rawTuple.winnersAnnounced}
            isSubEvent={false}
            subEvents={[
              <DetailedContainer
                key="debriefOpens"
                battleInfo={battleInfo}
                icon={homeOutline}
                title={"Debrief Opens"}
                description={
                  "Keep an eye out for an email about Debrief opening and the results reveal schedule. Writers also get to see which House their stories are in." +
                  (peerJudged
                    ? " Note that the start date is often much earlier and it will begin as soon as all the peer judging has wrapped up."
                    : "")
                }
                calEventStartDate={battleInfo.debriefDate}
                calEventEndDate={null}
                isSubEvent={true}
              />,
              !peerJudged ? (
                <DetailedContainer
                  key="houseResults_part1"
                  battleInfo={battleInfo}
                  icon={barChartOutline}
                  title={"Results Rounds 1-5"}
                  description={
                    "The results from House Rounds 1 through 5 are rolled out live as they come in. Check Debrief every day to stay up-to-date! The results stop updating live after Round 5. Please note that these dates are approximate."
                  }
                  isSubEvent={true}
                  calEventStartDate={battleInfo.debriefDate}
                  calEventEndDate={battleInfo.proJudgedHouseRevealEndDate}
                />
              ) : null,
              <DetailedContainer
                key="houseResults"
                battleInfo={battleInfo}
                icon={barChartOutline}
                title={
                  peerJudged ? "House Results Reveal" : "Results Rounds 6-8"
                }
                description={
                  (peerJudged
                    ? "The results from the Duels are rolled out"
                    : "The results from House Rounds 6 through 8 are rolled out") +
                  ", unveiling the stories that qualify for the Final Showdown from each House."
                }
                calEventStartDate={
                  new Date(
                    new Date(
                      battleInfo.houseResultsRevealDate.getTime()
                    ).setHours(battleInfo.houseResultsRevealDate.getHours() - 1)
                  )
                }
                calEventEndDate={battleInfo.houseResultsRevealDate}
                isSubEvent={true}
                calEventTitle={"House Results Reveal"}
                eventDescription={
                  "Visit the Debrief page for results from the peer-judged House phase of the contest as the results are rolled out over an hour."
                }
                eventLocation={"https://www.writingbattle.com/debrief"}
              />,
              <DetailedContainer
                key="finalShowdownReveal"
                battleInfo={battleInfo}
                icon={barChartOutline}
                title={"Final Showdown Reveal"}
                description={
                  "The results from the Final Showdown are rolled out, unveiling the winners of the brackets for the Final Showdown."
                }
                calEventStartDate={
                  new Date(
                    new Date(
                      battleInfo.rawTuple.winnersAnnounced.getTime()
                    ).setHours(
                      battleInfo.rawTuple.winnersAnnounced.getHours() - 1
                    )
                  )
                }
                calEventEndDate={battleInfo.rawTuple.winnersAnnounced}
                isSubEvent={true}
                calEventTitle={"Final Showdown Reveal"}
                eventDescription={
                  "Visit the Debrief page for results from the Official Judge phase of the contest as the results are rolled out over an hour."
                }
                eventLocation={"https://www.writingbattle.com/debrief"}
              />,
              <DetailedContainer
                key="winnersAnnounced"
                battleInfo={battleInfo}
                icon={trophyOutline}
                title={"Winners Announced"}
                description={
                  "Winners are announced and all of the results from the Final Showdown are revealed. Feedback is released to everyone."
                }
                calEventStartDate={battleInfo.rawTuple.winnersAnnounced}
                calEventEndDate={
                  new Date(
                    new Date(
                      battleInfo.rawTuple.winnersAnnounced.getTime()
                    ).setHours(
                      battleInfo.rawTuple.winnersAnnounced.getHours() + 1
                    )
                  )
                }
                isSubEvent={true}
                calEventTitle={"Winners Announced"}
                eventDescription={
                  "Visit the Debrief page for results from the Battle and feedback for your story."
                }
                hideEndDate={true}
                eventLocation={"https://www.writingbattle.com/debrief"}
              />,
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default DetailedBreakdown;
