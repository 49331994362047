import { useNavigate } from "react-router-dom";

function CreateAccountOrLogin({ userInfo, isActive, openLogInHandler }) {
  const navigate = useNavigate();
  return (
    <div className="hp-buttons-wrapper">
      {!userInfo?.isLoggedIn() ? (
        <>
          <button
            onClick={() => {
              navigate("/join-battle");
            }}
            className="login-btn"
          >
            Join Battle
          </button>
          <p className="description center-text">- OR -</p>
          <button
            onClick={() => openLogInHandler("login")}
            className="login-btn"
          >
            Log In
          </button>
        </>
      ) : null}
      {!isActive && userInfo?.isLoggedIn() ? (
        <button
          onClick={() => {
            navigate("/join-battle");
          }}
          className="login-btn"
        >
          Join Today!
        </button>
      ) : null}
    </div>
  );
}

export default CreateAccountOrLogin;
