import { useEffect, useRef, useState } from "react";
import { getMyUploads } from "../../publicFunctions/tavernHandler";
import SelectMyUploads from "./SelectMyUploads";
import UploadTavernStoryEditor from "./UploadTavernStoryEditor";

function UploadTavernStory({
  myProfile,
  myCards,
  collectionMeta,
  requestCollectionMeta,
  requestMyCards,
  loadedStory,
  setLoadedStory,
}) {
  const [myUploads, setMyUploads] = useState([]);
  const [refreshMyUploads, setRefreshMyUploads] = useState(true);
  const [isWorking, setIsWorking] = useState(false);

  useEffect(() => {
    if (myProfile?.userId) {
      getMyUploads(myProfile.userId).then((tempUploads) => {
        setMyUploads(tempUploads);
      });
    }
  }, [refreshMyUploads, myProfile]);

  const handleUploadRefresh = (updatedStory = null) => {
    if (updatedStory) {
      setLoadedStory(updatedStory);
      setRefreshMyUploads(!refreshMyUploads);
    } else {
      setRefreshMyUploads(!refreshMyUploads);
    }
  };

  return (
    <div>
      <SelectMyUploads
        loadedStory={loadedStory}
        setLoadedStory={setLoadedStory}
        myUploads={myUploads}
        isWorking={isWorking}
        handleUploadRefresh={handleUploadRefresh}
        refreshMyUploads={refreshMyUploads}
      />
      {loadedStory ? (
        <UploadTavernStoryEditor
          myProfile={myProfile}
          myCards={myCards}
          collectionMeta={collectionMeta}
          requestCollectionMeta={requestCollectionMeta}
          requestMyCards={requestMyCards}
          handleUploadRefresh={handleUploadRefresh}
          loadedStory={loadedStory}
          isWorking={isWorking}
          setIsWorking={setIsWorking}
          raidObject={loadedStory?.raidObject ? loadedStory.raidObject : null}
        />
      ) : null}
    </div>
  );
}

export default UploadTavernStory;
