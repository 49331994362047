import { useEffect, useState } from "react";
import {
  animal_text,
  cA_text,
  childLoss_text,
  domesticViolence_text,
  eating_text,
  gore_text,
  phobia_text,
  racism_text,
  sA_text,
  sC_text,
  spoiler_text,
  substanceAbuse_text,
  suicide_text,
  violence_text,
} from "../publicFunctions/debriefHandler";
import ContentWarning from "../publicFunctions/clientClasses/ContentWarning";
import { IonIcon } from "@ionic/react";
import { caretDownOutline, caretForwardOutline } from "ionicons/icons";

function ContentWarningSelector({
  contentWarningObject,
  setContentWarningObject,
  setChangesMade,
  isLoading,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [spoiler, setSpoiler] = useState(
    contentWarningObject?.spoiler ? true : false
  );
  const [sA, setSA] = useState(contentWarningObject?.sA ? true : false);
  const [cA, setCA] = useState(contentWarningObject?.cA ? true : false);
  const [suicide, setSuicide] = useState(
    contentWarningObject?.suicide ? true : false
  );
  const [childLoss, setChildLoss] = useState(
    contentWarningObject?.childLoss ? true : false
  );
  const [substanceAbuse, setSubstanceAbuse] = useState(
    contentWarningObject?.substanceAbuse ? true : false
  );
  const [racism, setRacism] = useState(
    contentWarningObject?.racism ? true : false
  );
  const [phobia, setPhobia] = useState(
    contentWarningObject?.phobia ? true : false
  );
  const [violence, setViolence] = useState(
    contentWarningObject?.violence ? true : false
  );
  const [gore, setGore] = useState(contentWarningObject?.gore ? true : false);
  const [sC, setSC] = useState(contentWarningObject?.sC ? true : false);
  const [eating, setEating] = useState(
    contentWarningObject?.eating ? true : false
  );
  const [animal, setAnimal] = useState(
    contentWarningObject?.animal ? true : false
  );
  const [domesticViolence, setDomesticViolence] = useState(
    contentWarningObject?.domesticViolence ? true : false
  );

  useEffect(() => {
    if (contentWarningObject) {
      setSpoiler(contentWarningObject.spoiler ? true : false);
      setSA(contentWarningObject.sA ? true : false);
      setCA(contentWarningObject.cA ? true : false);
      setSuicide(contentWarningObject.suicide ? true : false);
      setChildLoss(contentWarningObject.childLoss ? true : false);
      setSubstanceAbuse(contentWarningObject.substanceAbuse ? true : false);
      setRacism(contentWarningObject.racism ? true : false);
      setPhobia(contentWarningObject.phobia ? true : false);
      setViolence(contentWarningObject.violence ? true : false);
      setGore(contentWarningObject.gore ? true : false);
      setSC(contentWarningObject?.sC ? true : false);
      setEating(contentWarningObject.eating ? true : false);
      setAnimal(contentWarningObject?.animal ? true : false);
      setDomesticViolence(
        contentWarningObject?.domesticViolence ? true : false
      );
    }
  }, [contentWarningObject]);

  const onCheckChange = (id) => {
    let tempObject;
    if (contentWarningObject) {
      tempObject = contentWarningObject.clone();
    } else {
      tempObject = new ContentWarning();
    }

    if (id === "spoiler") {
      const toChange = !spoiler;
      setSpoiler(toChange);
      tempObject.spoiler = toChange;
    } else if (id === "sA") {
      const toChange = !sA;
      setSA(toChange);
      tempObject.sA = toChange;
    } else if (id === "cA") {
      const toChange = !cA;
      setCA(toChange);
      tempObject.cA = toChange;
    } else if (id === "suicide") {
      const toChange = !suicide;
      setSuicide(toChange);
      tempObject.suicide = toChange;
    } else if (id === "childLoss") {
      const toChange = !childLoss;
      setChildLoss(toChange);
      tempObject.childLoss = toChange;
    } else if (id === "substanceAbuse") {
      const toChange = !substanceAbuse;
      setSubstanceAbuse(toChange);
      tempObject.substanceAbuse = toChange;
    } else if (id === "racism") {
      const toChange = !racism;
      setRacism(toChange);
      tempObject.racism = toChange;
    } else if (id === "phobia") {
      const toChange = !phobia;
      setPhobia(toChange);
      tempObject.phobia = toChange;
    } else if (id === "violence") {
      const toChange = !violence;
      setViolence(toChange);
      tempObject.violence = toChange;
    } else if (id === "gore") {
      const toChange = !gore;
      setGore(toChange);
      tempObject.gore = toChange;
    } else if (id === "sC") {
      const toChange = !sC;
      setSC(toChange);
      tempObject.sC = toChange;
    } else if (id === "eating") {
      const toChange = !eating;
      setEating(toChange);
      tempObject.eating = toChange;
    } else if (id === "animal") {
      const toChange = !animal;
      setAnimal(toChange);
      tempObject.animal = toChange;
    } else if (id === "domesticViolence") {
      const toChange = !domesticViolence;
      setDomesticViolence(toChange);
      tempObject.domesticViolence = toChange;
    }
    setChangesMade(true);
    setContentWarningObject(tempObject);
  };

  return (
    <div className="flex flex--direction-column justify-content--start align-items--start border-thin">
      <div
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
        role="button"
        className="flex flex--gap-smaller align-items--center cursor--pointer"
      >
        <IonIcon
          className="battle-icon--small"
          icon={isExpanded ? caretDownOutline : caretForwardOutline}
        />
        <p className="description--small">
          {isExpanded ? "Collapse" : "Add or update"} Content Warnings
        </p>
      </div>
      <br />
      {isExpanded ? (
        <>
          <div className="flex flex--gap-smaller">
            <input
              required
              id="spoiler"
              className="checkbox"
              type="checkbox"
              value="spoiler"
              checked={spoiler}
              disabled={isLoading}
              onChange={() => {
                onCheckChange("spoiler");
              }}
            />
            <label htmlFor="spoiler" className="description--small">
              {spoiler_text}
            </label>
          </div>
          <div className="flex flex--gap-smaller">
            <input
              required
              id="sA"
              className="checkbox"
              type="checkbox"
              value="sA"
              checked={sA}
              disabled={isLoading}
              onChange={() => {
                onCheckChange("sA");
              }}
            />
            <label htmlFor="sA" className="description--small">
              {sA_text}
            </label>
          </div>
          <div className="flex flex--gap-smaller">
            <input
              required
              id="cA"
              className="checkbox"
              type="checkbox"
              value="cA"
              checked={cA}
              disabled={isLoading}
              onChange={() => {
                onCheckChange("cA");
              }}
            />
            <label htmlFor="cA" className="description--small">
              {cA_text}
            </label>
          </div>
          <div className="flex flex--gap-smaller">
            <input
              required
              id="suicide"
              className="checkbox"
              type="checkbox"
              value="suicide"
              checked={suicide}
              disabled={isLoading}
              onChange={() => {
                onCheckChange("suicide");
              }}
            />
            <label htmlFor="suicide" className="description--small">
              {suicide_text}
            </label>
          </div>
          <div className="flex flex--gap-smaller">
            <input
              required
              id="childLoss"
              className="checkbox"
              type="checkbox"
              value="childLoss"
              checked={childLoss}
              disabled={isLoading}
              onChange={() => {
                onCheckChange("childLoss");
              }}
            />
            <label htmlFor="childLoss" className="description--small">
              {childLoss_text}
            </label>
          </div>
          <div className="flex flex--gap-smaller">
            <input
              required
              id="substanceAbuse"
              className="checkbox"
              type="checkbox"
              value="substanceAbuse"
              checked={substanceAbuse}
              disabled={isLoading}
              onChange={() => {
                onCheckChange("substanceAbuse");
              }}
            />
            <label htmlFor="substanceAbuse" className="description--small">
              {substanceAbuse_text}
            </label>
          </div>
          <div className="flex flex--gap-smaller">
            <input
              required
              id="racism"
              className="checkbox"
              type="checkbox"
              value="racism"
              checked={racism}
              disabled={isLoading}
              onChange={() => {
                onCheckChange("racism");
              }}
            />
            <label htmlFor="racism" className="description--small">
              {racism_text}
            </label>
          </div>
          <div className="flex flex--gap-smaller">
            <input
              required
              id="phobia"
              className="checkbox"
              type="checkbox"
              value="phobia"
              checked={phobia}
              disabled={isLoading}
              onChange={() => {
                onCheckChange("phobia");
              }}
            />
            <label htmlFor="phobia" className="description--small">
              {phobia_text}
            </label>
          </div>
          <div className="flex flex--gap-smaller">
            <input
              required
              id="violence"
              className="checkbox"
              type="checkbox"
              value="violence"
              checked={violence}
              disabled={isLoading}
              onChange={() => {
                onCheckChange("violence");
              }}
            />
            <label htmlFor="violence" className="description--small">
              {violence_text}
            </label>
          </div>
          <div className="flex flex--gap-smaller">
            <input
              required
              id="gore"
              className="checkbox"
              type="checkbox"
              value="gore"
              checked={gore}
              disabled={isLoading}
              onChange={() => {
                onCheckChange("gore");
              }}
            />
            <label htmlFor="gore" className="description--small">
              {gore_text}
            </label>
          </div>
          <div className="flex flex--gap-smaller">
            <input
              required
              id="sC"
              className="checkbox"
              type="checkbox"
              value="sC"
              checked={sC}
              disabled={isLoading}
              onChange={() => {
                onCheckChange("sC");
              }}
            />
            <label htmlFor="sC" className="description--small">
              {sC_text}
            </label>
          </div>
          <div className="flex flex--gap-smaller">
            <input
              required
              id="eating"
              className="checkbox"
              type="checkbox"
              value="eating"
              checked={eating}
              disabled={isLoading}
              onChange={() => {
                onCheckChange("eating");
              }}
            />
            <label htmlFor="eating" className="description--small">
              {eating_text}
            </label>
          </div>
          <div className="flex flex--gap-smaller">
            <input
              required
              id="animal"
              className="checkbox"
              type="checkbox"
              value="animal"
              checked={animal}
              disabled={isLoading}
              onChange={() => {
                onCheckChange("animal");
              }}
            />
            <label htmlFor="animal" className="description--small">
              {animal_text}
            </label>
          </div>
          <div className="flex flex--gap-smaller">
            <input
              required
              id="domesticViolence"
              className="checkbox"
              type="checkbox"
              value="domesticViolence"
              checked={domesticViolence}
              disabled={isLoading}
              onChange={() => {
                onCheckChange("domesticViolence");
              }}
            />
            <label htmlFor="domesticViolence" className="description--small">
              {domesticViolence_text}
            </label>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ContentWarningSelector;
