import { useState } from "react";
import SearchBar from "../search/SearchBar";
import { search } from "../../publicFunctions/searchHandler";

function SearchUsers({ handleReceiveUsersToSelect, handleIsLoading }) {
  const [searchErrorMessage, setSearchErrorMessage] = useState("");

  const handleSubmitSearch = async (value, type = "author") => {
    if (!value || !value.length || value.length < 3) {
      setSearchErrorMessage("Please try a longer search.");
      return;
    }

    try {
      setSearchErrorMessage("");
      handleIsLoading(true);
      const arrayOfDocs = await search(value, type, ["userId"], "");
      handleReceiveUsersToSelect(arrayOfDocs);
      handleIsLoading(false);
    } catch (err) {
      console.log(err);
      setSearchErrorMessage("Hmmm... please try that again.");
      handleIsLoading(false);
    }
  };
  const clearSearchHandler = () => {};

  return (
    <div className="search-users search-bar-container--less-padding">
      <SearchBar
        hasFilters={false}
        hasSortBy={false}
        handleSubmitSearchQuery={handleSubmitSearch}
        searchErrorMessage={searchErrorMessage}
        clearSearchHandler={clearSearchHandler}
        isUserSearch={true}
      />
    </div>
  );
}

export default SearchUsers;
