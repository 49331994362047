import { doc, getDoc } from "firebase/firestore";
import { db, searchForSomething } from "../config/firebase";
export const DEBRIEF_STORY_TYPE = "debriefStory";

export async function search(
  value,
  searchType,
  requestedTypes = [],
  battleId = ""
) {
  try {
    const { data } = await searchForSomething({
      value,
      searchType,
      requestedTypes,
      battleId,
    });
    return data.searchList;
  } catch (err) {
    console.log(err);
    return [];
  }

  // for testing only.
  // const fakeReturn = [
  //   {
  //     type: DEBRIEF_STORY_TYPE,
  //     documentId: "2d96c41a-92b2-4e1f-b4e2-ac092616b2c5",
  //   },
  //   {
  //     type: DEBRIEF_STORY_TYPE,
  //     documentId: "997632c0-54de-47eb-800b-8fd8acb500a4",
  //   },
  //   {
  //     type: DEBRIEF_STORY_TYPE,
  //     documentId: "7dfbdd78-d6dd-4e65-976c-a77cf42cab96",
  //   },
  //   {
  //     type: DEBRIEF_STORY_TYPE,
  //     documentId: "9b1e2224-9247-4e0f-a91c-a31af97abf19",
  //   },
  //   {
  //     type: DEBRIEF_STORY_TYPE,
  //     documentId: "b3229b76-b07c-4c7b-8446-f9153c790247",
  //   },
  //   {
  //     type: DEBRIEF_STORY_TYPE,
  //     documentId: "5f6c91ec-d4cb-4d84-ac48-f3891270b937",
  //   },
  //   {
  //     type: DEBRIEF_STORY_TYPE,
  //     documentId: "d7c6d8a2-35fb-4e0c-aa94-bba956a12362",
  //   },
  //   {
  //     type: DEBRIEF_STORY_TYPE,
  //     documentId: "e0657737-867d-43ac-a553-7d70f3549958",
  //   },
  //   {
  //     type: DEBRIEF_STORY_TYPE,
  //     documentId: "28952de8-24c6-4e4e-9252-e26054bc4691",
  //   },
  //   {
  //     type: DEBRIEF_STORY_TYPE,
  //     documentId: "76819145-6da2-4383-aabc-3baecda0f0a8",
  //   },
  //   {
  //     type: DEBRIEF_STORY_TYPE,
  //     documentId: "98bfcf3b-e202-4f0d-b29d-b38376e052b6",
  //   },
  //   {
  //     type: DEBRIEF_STORY_TYPE,
  //     documentId: "88ba3163-602c-481a-9db6-04a99cab16fa",
  //   },
  //   {
  //     type: DEBRIEF_STORY_TYPE,
  //     documentId: "6fd9b947-59a1-4f69-b309-aaf979e781b1",
  //   },
  // ];
  // return fakeReturn;
}

export async function getSearchDocumentsFromServer(array, indexToStart, limit) {
  if (!array?.length) {
    return [];
  }
  const arrayToReturn = [];
  let count = 0;
  for (let i = indexToStart; i < array.length && count < limit; i++, count++) {
    const item = array[i];
    if (!item.documentId) {
      continue;
    }
    if (item.type === DEBRIEF_STORY_TYPE) {
      const snap = await getDoc(doc(db, "debriefStories", item.documentId));
      if (snap.exists()) {
        arrayToReturn.push(snap.data());
      }
    }
  }
  return arrayToReturn;
}
