function BattleDate({ image, dateText, yearText, battleId }) {
  return (
    <div
      role="button"
      style={{
        overflow: "hidden",
        backgroundSize: "auto 100%",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${image})`,
        backgroundPosition: "top left",
      }}
      className="battle-date"
      onClick={() => window.open("/join-battle?autoSelectBattleId=" + battleId)}
    >
      <div className="margin-right-tiny">
        <div className="flex flex--direction-column align-items--end bold white-shadow--huge">
          <p className="description--large">{dateText}</p>
          <p className="description--small italic">{yearText}</p>
        </div>
      </div>
    </div>
  );
}

export default BattleDate;
