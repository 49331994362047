import { useEffect, useState } from "react";
import { getMyKnockoutPoints } from "../../publicFunctions/registerHandler";
import { getIconByBattleId } from "../../publicFunctions/publicHelpers/homeHelper";
import { IonIcon } from "@ionic/react";

function MyKnockoutPoints({ myProfile }) {
  const [currentTotal, setCurrentTotal] = useState(0);
  const [alreadyChecked, setAlreadyChecked] = useState(false);
  const [yearString, setYearString] = useState("");
  const [renderedDetailedPoints, setRenderedDetailedPoints] = useState([]);

  useEffect(() => {
    if (myProfile?.userId && !alreadyChecked && !currentTotal) {
      // get my knockOutPoints for this year
      setAlreadyChecked(true);
      const year = new Date().getFullYear().toString(10);
      setYearString(year + " ");
      getMyKnockoutPoints(year, myProfile.userId)
        .then((result) => {
          if (result) {
            const battles = result.battles;
            let lowestScore = 99999999999999999;
            let totalScore = 0;
            const tempRender = [];
            for (let j = 0; j < battles.length; j++) {
              const obj = battles[j];
              if (obj.points < lowestScore) {
                lowestScore = obj.points;
              }
              totalScore += obj.points;
              const season = getSeasonByBattleId(obj.battleId);
              if (obj.points) {
                tempRender.push(
                  <div
                    className="flex align-items--center flex--gap-normal"
                    key={j.toString(10) + "_season"}
                  >
                    {season ? (
                      <IonIcon
                        className="battle-icon--small"
                        icon={getIconByBattleId(season)}
                      />
                    ) : null}
                    <p className="description">{season}</p>
                    <p className="description bold">{obj.points}</p>
                  </div>
                );
              }
            }
            if (battles.length === 4) {
              totalScore -= lowestScore;
            }
            setCurrentTotal(totalScore);
            setRenderedDetailedPoints(tempRender);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [myProfile]);

  if (!currentTotal) {
    return null;
  }

  return (
    <div>
      <div className="simple-divider"></div>
      <p className="description--larger bold center-text margin-auto margin-top-bigger margin-bottom-sm">
        {yearString + "Knockout Score"}
      </p>
      <p className="step-number dark-blue center-text">{currentTotal}</p>
      <br />
      <br />
      <div>{renderedDetailedPoints}</div>
      <br />
      <br />
      <p className="description--small">
        The writer with the highest Knockout Score at the end of the year is
        crowned the MVW and wins <strong>$1,000</strong> cash. We count your
        best 3 out of the 4 Quarterly Battle knockout points in the calendar
        year to determine your Knockout Score. Not participating in a Battle
        counts as zero for that quarter.
        <br />
        <br />
        Knockout points for a Battle are calculated as follows:
        <br />
        <em>Swiss points * 5 + Final showdown knockouts * 10</em>
        <br />
        <br />
      </p>
      <p className="description--smaller">
        Note: Since the Winter 2023 Battle used a different format, and was one
        big single elimination tournament, the Knockout points were calculated
        by multiplying the total number of knockouts by 10. The result is a
        relatively similar scoring to the new Swiss-style system.
      </p>
    </div>
  );
}

function getSeasonByBattleId(battleId) {
  if (!battleId || battleId.length < 6) {
    return "";
  }
  const month = battleId.slice(0, -4);
  let toReturn = "";
  if (month === "feb") {
    toReturn = "Winter";
  } else if (month === "may") {
    toReturn = "Spring";
  } else if (month === "aug") {
    toReturn = "Summer";
  } else if (month === "nov") {
    toReturn = "Autumn";
  }
  return toReturn;
}

export default MyKnockoutPoints;
