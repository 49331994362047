import { useEffect, useState } from "react";
import SelectUserButton from "./SelectUserButton";

function TaggedUsersList({
  handleUserSelectedToBeRemoved,
  listOfTaggedUserIds,
  isReadOnly,
}) {
  const [renderedTaggedUsers, setRenderedTaggedUsers] = useState(null);

  useEffect(() => {
    if (listOfTaggedUserIds?.length) {
      const tempRendered = [];
      for (let i = 0; i < listOfTaggedUserIds.length; i++) {
        const userId = listOfTaggedUserIds[i];
        if (userId) {
          tempRendered.push(
            <SelectUserButton
              key={i + "_" + userId}
              userId={userId}
              clickingRemovesMe={true}
              handleWhatHappensWhenYouClickMe={handleUserSelectedToBeRemoved}
              isReadOnly={isReadOnly ? true : false}
            />
          );
        }
      }
      setRenderedTaggedUsers(tempRendered);
    } else {
      setRenderedTaggedUsers(null);
    }
  }, [listOfTaggedUserIds]);

  return (
    <div className="tagged-users-list">
      {renderedTaggedUsers?.length ? (
        <>
          {!isReadOnly ? (
            <p className="description bold">
              Tagged Users{" "}
              {"(" + renderedTaggedUsers.length.toString(10) + " of 5)"}
            </p>
          ) : (
            <p className="description--smaller bold">Tagged Users</p>
          )}
          <div className="select-users-list">{renderedTaggedUsers}</div>
        </>
      ) : null}
    </div>
  );
}

export default TaggedUsersList;
