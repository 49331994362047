function DebriefFSListBye({ storyTitle }) {
  return (
    <div
      style={{ gridColumn: "span 5" }}
      className="description--small italic center-text"
    >
      This story advances automatically due to its House ranking
    </div>
  );
}

export default DebriefFSListBye;
