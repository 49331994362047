import { IonIcon } from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import ForumAndDebriefRules from "../siteRules/ForumAndDebriefRules";

function DeleteAThing({
  description,
  handleDelete,
  isModerator,
  isMyThing = true,
  rulesType,
}) {
  const [youSure, setYouSure] = useState(false);
  const [typeYes, setTypeYes] = useState("");
  const [message, setMessage] = useState(
    "Are you sure you want to delete this " + description + "?"
  );
  const [selectedReason, setSelectedReason] = useState("");

  useEffect(() => {
    if (typeYes && handleDelete) {
      const lowerCaseYes = typeYes.toLowerCase();
      if (lowerCaseYes === "yes") {
        setMessage("Deleting " + description + "... the page will refresh.");
        setTimeout(() => {
          handleDelete();
        }, 2000);
      }
    }
  }, [typeYes]);

  const showModeratorControls = isModerator && !isMyThing;

  const handleTypeYes = (event) => {
    setTypeYes(event.target.value);
  };

  const changeSelectedReason = (reason) => {
    setSelectedReason(reason);
  };

  return (
    <div className="delete-thing">
      {youSure ? (
        <div className="delete-you-sure">
          <p className="description bold">{message}</p>
          {showModeratorControls && !selectedReason ? (
            <ForumAndDebriefRules
              rulesType={rulesType}
              readOnly={false}
              selectedReason={selectedReason}
              changeSelectedReason={changeSelectedReason}
            />
          ) : showModeratorControls && selectedReason ? (
            <>
              <p className="description">Reason: {selectedReason}</p>
              <button
                className="description--small cursor--pointer"
                onClick={(event) => {
                  event.preventDefault();
                  const reason = selectedReason;
                  handleDelete(reason);
                  setSelectedReason("");
                }}
              >
                Delete {description}
              </button>
              <br />
              <button
                className="description--small cursor--pointer"
                onClick={(event) => {
                  event.preventDefault();
                  setSelectedReason("");
                }}
              >
                Change reason
              </button>
            </>
          ) : (
            <div className="flex justify-content--end text-align-right flex--gap-smaller">
              <>
                <p className="description--small">Type YES to delete</p>
                <input
                  value={typeYes}
                  onChange={handleTypeYes}
                  placeholder="YES"
                ></input>
              </>
            </div>
          )}
          <p className="description">
            or click{" "}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                setYouSure(false);
              }}
            >
              HERE
            </span>{" "}
            to cancel
          </p>
        </div>
      ) : (
        <div
          onClick={() => {
            setYouSure(true);
          }}
          className="delete-thing-icon"
        >
          <IonIcon className="social-icon" icon={trashOutline} />
        </div>
      )}
    </div>
  );
}

export default DeleteAThing;
