import { IonIcon } from "@ionic/react";
import { closeCircle, checkmarkCircleOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import ProfileDisplayTrophy from "./ProfileDisplayTrophy";
import { getTrophyDescription } from "../../publicFunctions/publicHelpers/trophyNaming";
import { combineTrophies, saveTrophyDisplay } from "../../config/firebase";
import { getTrophyImageUrlByFileName } from "../../publicFunctions/publicHelpers/imageUrlBuilder";

function ProfileTrophies({
  editable,
  trophyBank,
  trophiesDisplayed,
  refreshMyProfile,
}) {
  const [message, setMessage] = useState("");
  const [trophyDisplay1, setTrophyDisplay1] = useState(
    trophiesDisplayed?.trophy1 ? trophiesDisplayed.trophy1 : null
  );
  const [trophyDisplay2, setTrophyDisplay2] = useState(
    trophiesDisplayed?.trophy2 ? trophiesDisplayed.trophy2 : null
  );
  const [trophyDisplay3, setTrophyDisplay3] = useState(
    trophiesDisplayed?.trophy3 ? trophiesDisplayed.trophy3 : null
  );
  const [selectedTrophyPosition, setSelectedTrophyPosition] = useState(null);
  const [saving, setSaving] = useState(false);

  // trophy bank stuff
  const [showTrophyBank, setShowTrophyBank] = useState(false);
  const [renderedTrophyBank, setRenderedTrophyBank] = useState(null);
  const [refreshBank, setRefreshBank] = useState(false);

  const initializeTrophyDisplays = () => {
    if (!trophiesDisplayed) {
      setTrophyDisplay1(null);
      setTrophyDisplay2(null);
      setTrophyDisplay3(null);
    } else {
      const trophy1 = trophiesDisplayed.trophy1
        ? trophiesDisplayed.trophy1
        : null;
      const trophy2 = trophiesDisplayed.trophy2
        ? trophiesDisplayed.trophy2
        : null;
      const trophy3 = trophiesDisplayed.trophy3
        ? trophiesDisplayed.trophy3
        : null;
      setTrophyDisplay1(trophy1);
      setTrophyDisplay2(trophy2);
      setTrophyDisplay3(trophy3);
    }
  };

  const changesMade = () => {
    if (!trophiesDisplayed) {
      return false;
    }
    const trophy1 = trophiesDisplayed.trophy1
      ? trophiesDisplayed.trophy1
      : null;
    const trophy2 = trophiesDisplayed.trophy2
      ? trophiesDisplayed.trophy2
      : null;
    const trophy3 = trophiesDisplayed.trophy3
      ? trophiesDisplayed.trophy3
      : null;

    if (trophy1 !== trophyDisplay1) {
      return true;
    }
    if (trophy2 !== trophyDisplay2) {
      return true;
    }
    if (trophy3 !== trophyDisplay3) {
      return true;
    }
    return false;
  };

  const threeOfAKind = () => {
    if (
      !showTrophyBank ||
      !trophyDisplay1 ||
      !trophyDisplay2 ||
      !trophyDisplay3
    ) {
      return false;
    }

    // This looks like kind weird, but the third split
    // is just a modifier. You can still combine those trophies
    let type1 = trophyDisplay1.type;
    const split1 = trophyDisplay1.type.split("_");
    if (split1.length > 2) {
      type1 = split1[0] + "_" + split1[1];
    }
    let type2 = trophyDisplay2.type;
    const split2 = trophyDisplay2.type.split("_");
    if (split2.length > 2) {
      type2 = split2[0] + "_" + split2[1];
    }
    let type3 = trophyDisplay3.type;
    const split3 = trophyDisplay3.type.split("_");
    if (split3.length > 2) {
      type3 = split3[0] + "_" + split3[1];
    }
    return type1 === type2 && type2 === type3;
  };

  const trophyBankTrophySelect = (index) => {
    if (index === -999) {
      return;
    }
    if (index === -1 && selectedTrophyPosition) {
      if (selectedTrophyPosition === 1) {
        setTrophyDisplay1(null);
      } else if (selectedTrophyPosition === 2) {
        setTrophyDisplay2(null);
      } else if (selectedTrophyPosition === 3) {
        setTrophyDisplay3(null);
      }
      setMessage("");
    } else if (
      index > -1 &&
      trophyBank?.length > index &&
      selectedTrophyPosition
    ) {
      const trophy = trophyBank[index];
      if (selectedTrophyPosition === 1) {
        setTrophyDisplay1(trophy);
      } else if (selectedTrophyPosition === 2) {
        setTrophyDisplay2(trophy);
      } else if (selectedTrophyPosition === 3) {
        setTrophyDisplay3(trophy);
      }
      setMessage(getTrophyDescription(trophy.type, trophy.battleTitle));
    }
    setRefreshBank(!refreshBank);
  };

  const getSelectableAndNotSelectable = (position) => {
    let returnObj = {
      selected: null,
      notSelectable1: null,
      notSelectable2: null,
    };
    if (editable && position != 0) {
      // trigger trophyBank view, passing in currently
      // selected trophy and other ones grayed out trophies
      let selectedTrophy = null;
      if (position === 1) {
        selectedTrophy = trophyDisplay1;
        returnObj.notSelectable1 = trophyDisplay2;
        returnObj.notSelectable2 = trophyDisplay3;
      } else if (position === 2) {
        selectedTrophy = trophyDisplay2;
        returnObj.notSelectable1 = trophyDisplay1;
        returnObj.notSelectable2 = trophyDisplay3;
      } else if (position === 3) {
        selectedTrophy = trophyDisplay3;
        returnObj.notSelectable1 = trophyDisplay1;
        returnObj.notSelectable2 = trophyDisplay2;
      }
      returnObj.selected = selectedTrophy;
    }
    return returnObj;
  };

  useEffect(() => {
    if (trophyBank?.length) {
      const trophySelectObj = getSelectableAndNotSelectable(
        selectedTrophyPosition
      );
      const tempRender = [];
      // todo: add the null select option
      tempRender.push(
        <ProfileDisplayTrophy
          key="null-trophy"
          selected={!trophySelectObj.selected}
          trophyImg={"null-trophy"}
          trophyBankItem={true}
          trophyClickedHandler={() => {
            trophyBankTrophySelect(-1);
          }}
        />
      );
      for (let i = 0; i < trophyBank.length; i++) {
        const trophy = trophyBank[i];
        let selected =
          trophySelectObj.selected &&
          trophy?.id === trophySelectObj.selected.id;

        let unselectable = false;
        if (
          (trophySelectObj.notSelectable1 &&
            trophy?.id === trophySelectObj.notSelectable1.id) ||
          (trophySelectObj.notSelectable2 &&
            trophy?.id === trophySelectObj.notSelectable2.id)
        ) {
          unselectable = true;
        }

        tempRender.push(
          <ProfileDisplayTrophy
            key={i.toString(10)}
            selected={selected}
            trophyImg={getTrophyImageUrlByFileName(trophy.fileName)}
            trophyBankItem={true}
            trophyClickedHandler={() => {
              trophyBankTrophySelect(unselectable ? -999 : i);
            }}
            unselectable={unselectable}
          />
        );
      }
      setRenderedTrophyBank(tempRender);
    }
  }, [trophyBank, selectedTrophyPosition, refreshBank]);

  useEffect(() => {
    if (trophiesDisplayed) {
      setTrophyDisplay1(
        trophiesDisplayed.trophy1 ? trophiesDisplayed.trophy1 : null
      );
      setTrophyDisplay2(
        trophiesDisplayed.trophy2 ? trophiesDisplayed.trophy2 : null
      );
      setTrophyDisplay3(
        trophiesDisplayed.trophy3 ? trophiesDisplayed.trophy3 : null
      );
    }
  }, [trophiesDisplayed]);

  const triggerTrophyBank = () => {
    if (editable && trophyBank) {
      setShowTrophyBank(true);
    }
  };

  const clickTrophy = (position) => {
    if (editable && position != 0) {
      const trophySelectObj = getSelectableAndNotSelectable(position);
      if (trophySelectObj?.selected) {
        const trophy = trophySelectObj.selected;
        setMessage(getTrophyDescription(trophy.type, trophy.battleTitle));
      }
      triggerTrophyBank();
    } else if (!editable && position != 0) {
      let trophy;
      if (position === 1 && trophyDisplay1) {
        trophy = trophyDisplay1;
      } else if (position === 2 && trophyDisplay2) {
        trophy = trophyDisplay2;
      } else if (position === 3 && trophyDisplay3) {
        trophy = trophyDisplay3;
      }
      if (trophy) {
        setMessage(getTrophyDescription(trophy.type, trophy.battleTitle));
      }
    }
    // now set the position only if it's editable... else we just want message
    if (editable) {
      setSelectedTrophyPosition(position);
    }
  };

  const handleSaveTrophyDisplay = () => {
    setMessage("Saving...");
    setSaving(true);
    const trophyId1 = trophyDisplay1 ? trophyDisplay1.id : "";
    const trophyId2 = trophyDisplay2 ? trophyDisplay2.id : "";
    const trophyId3 = trophyDisplay3 ? trophyDisplay3.id : "";

    saveTrophyDisplay({ trophyId1, trophyId2, trophyId3 })
      .then((updatedProfile) => {
        // we don't actually need the updatedProfile
        // since we're refreshing the profile anyway
        refreshMyProfile();
        setShowTrophyBank(false);
        setSelectedTrophyPosition(null);
        setMessage("Trophy display saved!");
      })
      .catch((err) => {
        console.log(err);
        setMessage(err.message);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleCombineTrophies = () => {
    setMessage("Combining trophies...");
    setSaving(true);
    const trophyId1 = trophyDisplay1 ? trophyDisplay1.id : "";
    const trophyId2 = trophyDisplay2 ? trophyDisplay2.id : "";
    const trophyId3 = trophyDisplay3 ? trophyDisplay3.id : "";

    combineTrophies({ trophyId1, trophyId2, trophyId3 })
      .then(() => {
        // we don't actually need the updatedProfile
        // since we're refreshing the profile anyway
        refreshMyProfile();
        setShowTrophyBank(false);
        setSelectedTrophyPosition(null);
        setMessage("Trophies combined!");
      })
      .catch((err) => {
        console.log(err);
        setMessage(err.message);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <div className="profile-trophies">
      {/* trophy bank */}
      {showTrophyBank ? (
        <div
          style={{
            position: "relative",
            width: "fit-content",
            margin: "0 auto",
          }}
        >
          <p className="description bold center-text white black-background-faded">
            Trophy Chest
          </p>
          <div className="trophy-bank-wrapper">
            <div className="trophy-bank">{renderedTrophyBank}</div>
          </div>
        </div>
      ) : null}

      {/* everything else */}
      <div className="trophy-message-wrapper">
        <p className="description--smaller white-background-faded">{message}</p>
      </div>
      <div className="profile-trophies-display">
        <ProfileDisplayTrophy
          editable={editable}
          selected={selectedTrophyPosition === 1}
          trophyImg={
            trophyDisplay1?.fileName
              ? getTrophyImageUrlByFileName(trophyDisplay1.fileName)
              : ""
          }
          trophyClickedHandler={() => clickTrophy(1)}
        />
        <ProfileDisplayTrophy
          editable={editable}
          selected={selectedTrophyPosition === 2}
          trophyImg={
            trophyDisplay2?.fileName
              ? getTrophyImageUrlByFileName(trophyDisplay2.fileName)
              : ""
          }
          trophyClickedHandler={() => clickTrophy(2)}
        />
        <ProfileDisplayTrophy
          editable={editable}
          selected={selectedTrophyPosition === 3}
          trophyImg={
            trophyDisplay3?.fileName
              ? getTrophyImageUrlByFileName(trophyDisplay3.fileName)
              : ""
          }
          trophyClickedHandler={() => clickTrophy(3)}
        />
      </div>
      {changesMade() && !saving ? (
        <div className="profile--youSure white-background-faded">
          <p>Save trophy display?</p>
          <div style={{ display: "flex", gap: "1.2rem" }}>
            <IonIcon
              onClick={handleSaveTrophyDisplay}
              className="im-sure"
              icon={checkmarkCircleOutline}
            />
            <IonIcon
              onClick={() => {
                initializeTrophyDisplays();
                setShowTrophyBank(false);
                setSelectedTrophyPosition(null);
                setMessage("");
              }}
              className="not-sure"
              icon={closeCircle}
            />
          </div>
        </div>
      ) : null}
      {threeOfAKind() && !saving ? (
        <div className="white-background-faded">
          <br />
          <p className="description--small" style={{ display: "inline-block" }}>
            or click&nbsp;
          </p>
          <p
            onClick={handleCombineTrophies}
            className="description--small cursor--pointer"
            style={{ display: "inline-block" }}
          >
            <u>HERE</u>
          </p>
          <p className="description--small" style={{ display: "inline-block" }}>
            &nbsp;to combine
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default ProfileTrophies;
