import { useEffect, useState } from "react";
import JudgeMallet from "../../img/judgeMallet.png";

function DebriefRounds({
  houseData,
  selectedStoryId,
  mapOfPublishedStoryIds,
  mapStoryIdToStoryInfo,
  myStory,
  myDuels,
}) {
  const [renderedRounds, setRenderedRounds] = useState([]);

  useEffect(() => {
    const mapOfDuelsThatIJudged = new Map();
    if (myDuels?.length) {
      myDuels.forEach((duel) => {
        mapOfDuelsThatIJudged.set(duel.duelId, true);
      });
    }
    // Go through the house data and re-populate the rounds
    if (!houseData?.rounds || !houseData.revealedUpToRound) {
      return;
    }
    const tempRenderedRounds = [];
    // note that we go one above to display the upcoming round
    for (let i = houseData.revealedUpToRound + 1; i >= 1; i--) {
      const round = houseData.rounds["round" + i.toString(10)];
      if (!round) continue;
      // also update the current score for the duels in this round

      // Add a title of this round
      tempRenderedRounds.push(
        <div key={"roundTitle " + i.toString(10)}>
          <p
            style={{
              backgroundColor: "#2a6e8c",
              padding: "1.2rem",
              borderRadius: "11px",
            }}
            className="heading-secondary margin--none white"
          >
            {"Round " + i.toString(10)}
          </p>
          {i > houseData.revealedUpToRound ? (
            <p className="description">
              Results pending. Please refer to your reveal schedule to find out
              when the winners of these Duels will be announced.
            </p>
          ) : null}
        </div>
      );

      const tempRenderedDuels = [];
      for (let j = 0; j < round.length; j++) {
        const duel = round[j];
        if (
          selectedStoryId !== duel.storyIdA &&
          selectedStoryId !== duel.storyIdB
        ) {
          continue;
        }

        const storyTitleA = mapStoryIdToStoryInfo.get(duel.storyIdA).storyTitle;
        const storyTitleB = mapStoryIdToStoryInfo.get(duel.storyIdB).storyTitle;
        let storyAIsPublished = false;
        if (
          mapOfPublishedStoryIds &&
          mapOfPublishedStoryIds.has(duel.storyIdA)
        ) {
          storyAIsPublished = true;
        }
        let storyBIsPublished = false;
        if (
          mapOfPublishedStoryIds &&
          mapOfPublishedStoryIds.has(duel.storyIdB)
        ) {
          storyBIsPublished = true;
        }

        let storyATextClass = "description center-text";
        if (myStory?.storyId === duel.storyIdA) {
          storyATextClass += " bold";
        }
        if (storyAIsPublished) {
          storyATextClass += " underline cursor--pointer";
        }
        let storyBTextClass = "description center-text";
        if (myStory?.storyId === duel.storyIdB) {
          storyBTextClass += " bold";
        }
        if (storyBIsPublished) {
          storyBTextClass += " underline cursor--pointer";
        }

        tempRenderedDuels.push(
          <div
            key={i.toString(10) + "_" + j.toString(10) + "duel"}
            className={
              duel.winner
                ? "debrief-house-duel"
                : "debrief-house-duel debrief-house-duel--pending"
            }
          >
            {/* Story A */}
            <p
              style={
                duel.storyIdA === duel.winner
                  ? { backgroundColor: "#b5ca8d" }
                  : { backgroundColor: "transparent" }
              }
              className={storyATextClass}
            >
              <a
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  pointerEvents: storyAIsPublished ? "auto" : "none",
                  fontWeight: selectedStoryId === duel.storyIdA ? "600" : "500",
                }}
                href={"/story/debrief/" + duel.storyIdA + "?uploadedStory"}
              >
                {storyTitleA}
              </a>
            </p>
            {duel.storyIdA === duel.winner &&
            mapOfDuelsThatIJudged.has(duel.duelId) ? (
              <div
                style={{
                  alignSelf: "stretch",
                  backgroundColor: "#b5ca8d",
                }}
              >
                <img className="social-icon" src={JudgeMallet} />
              </div>
            ) : (
              <p
                style={
                  duel.storyIdA === duel.winner
                    ? { backgroundColor: "#b5ca8d" }
                    : { backgroundColor: "transparent" }
                }
                className="description center-text bold"
              >
                {duel.storyIdA === duel.winner ? "W" : ""}
              </p>
            )}

            {/* VERSUS */}
            <p className="description center-text">vs.</p>
            <div></div>

            {/* Story B */}
            <p
              style={
                duel.storyIdB === duel.winner
                  ? { backgroundColor: "#b5ca8d" }
                  : { backgroundColor: "transparent" }
              }
              className={storyBTextClass}
            >
              <a
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  pointerEvents: storyBIsPublished ? "auto" : "none",
                  fontWeight: selectedStoryId === duel.storyIdB ? "600" : "500",
                }}
                href={"/story/debrief/" + duel.storyIdB + "?uploadedStory"}
              >
                {storyTitleB}
              </a>
            </p>
            {duel.storyIdB === duel.winner &&
            mapOfDuelsThatIJudged.has(duel.duelId) ? (
              <div
                style={{
                  alignSelf: "stretch",
                  backgroundColor: "#b5ca8d",
                }}
              >
                <img className="social-icon" src={JudgeMallet} />
              </div>
            ) : (
              <p
                style={
                  duel.storyIdB === duel.winner
                    ? { backgroundColor: "#b5ca8d" }
                    : { backgroundColor: "transparent" }
                }
                className="description center-text bold"
              >
                {duel.storyIdB === duel.winner ? "W" : ""}
              </p>
            )}
          </div>
        );
      }
      tempRenderedRounds.push(
        <div
          key={i.toString(10) + "_" + "round-duels"}
          style={{ gap: "3rem" }}
          className="debrief-house-masterlist--stories"
        >
          {tempRenderedDuels}
        </div>
      );
      setRenderedRounds(tempRenderedRounds);
    }
  }, [houseData, selectedStoryId]);

  return <div key="master-rounds">{renderedRounds}</div>;
}

export default DebriefRounds;
