import AtAGlance from "./AtAGlance";
import OfficialRulesPDF from "./OfficialRulesPDF";
import FAQS from "./FAQS";
import PromptsSection from "./PromptsSection";
import { getPromptTypeDescriptionAndIcon } from "../../publicFunctions/publicHelpers/promptTypes";
import { useEffect, useState } from "react";
import DetailedBreakdown from "./DetailedBreakdown";
import AddToCalendarWrapper from "../AddToCalendarWrapper";
import Prizes from "./Prizes";
import BattleHeading from "../BattleHeading";

function RulesBody({ battleInfo, userInfo, peerJudged }) {
  const [detailed, setDetailed] = useState(
    userInfo && userInfo.isLoggedIn() ? true : false
  );

  useEffect(() => {
    if (userInfo && userInfo.isLoggedIn()) {
      setDetailed(true);
    }
  }, [userInfo]);

  const battleTuple = battleInfo.rawTuple;
  return (
    <div>
      <BattleHeading
        battleId={battleInfo.rawTuple.battleId}
        battleTitle={
          battleInfo.rawTuple.titlePrefix +
          " " +
          battleInfo.rawTuple.initialDate.getFullYear().toString(10) +
          " " +
          battleInfo.rawTuple.type +
          " Rules"
        }
      />
      <div className="container">
        {!detailed ? (
          <div className="justify-self--center detailed-rules-span margin-bottom-md">
            <AddToCalendarWrapper
              startDate={battleTuple.initialDate}
              endDate={battleTuple.submissionDate}
              title={"Write " + battleInfo.battleTitle}
              description={battleInfo.shortDescription}
            />
          </div>
        ) : null}
        <span className="subheading glance-text">
          {detailed ? "Detailed breakdown..." : "At a glance..."}
        </span>
      </div>
      {detailed ? (
        <DetailedBreakdown battleInfo={battleInfo} peerJudged={peerJudged} />
      ) : (
        <AtAGlance
          initDate={battleTuple.initialDate}
          submitDate={battleTuple.submissionDate}
          firstDuelDate={battleTuple.firstDuels}
          lastDuelDate={
            new Date(
              new Date(battleInfo.duelDates.duel5.getTime()).setDate(
                battleInfo.duelDates.duel5.getDate() + 2
              )
            )
          }
          winnersDate={battleTuple.winnersAnnounced}
          maxWords={battleTuple.maxWords.toString(10)}
          peerJudged={peerJudged}
        />
      )}
      <OfficialRulesPDF officialRulesLink={battleTuple.rulesUrl} />
      {!peerJudged ? (
        <OfficialRulesPDF officialRulesLink={""} isRubric={true} />
      ) : null}
      <Prizes
        battleInfo={battleInfo}
        isLegacy={battleTuple.battleId.includes("2024") ? true : false}
      />
      <PromptsSection
        prompt1Info={getPromptTypeDescriptionAndIcon(
          battleTuple.promptTypes[1]
        )}
        prompt2Info={getPromptTypeDescriptionAndIcon(
          battleTuple.promptTypes[2]
        )}
        peerJudged={peerJudged}
        genreListText={
          battleInfo?.genreListText ? battleInfo.genreListText : ""
        }
      />
      <FAQS peerJudged={peerJudged} />
    </div>
  );
}

export default RulesBody;
