import { IonIcon } from "@ionic/react";
import { bookOutline } from "ionicons/icons";

function JudgeCard({
  cardImage,
  judgeName,
  accoladeImage,
  goToType,
  goToLink,
  accolade1,
  accolade2,
  learnMoreLink,
  isMVW,
}) {
  const accTitle = getClickableAccoladeTitleByType(goToType);

  return (
    <div
      className="judge-card"
      style={{ backgroundImage: 'url("' + cardImage + '")' }}
    >
      <p className="card-title">{judgeName}</p>
      {accoladeImage ? (
        <div>
          <a
            target="_blank"
            title={accTitle}
            className="clickable-accolade"
            href={goToLink}
          >
            <img
              className={isMVW ? "accolade--mvw" : "accolade"}
              src={accoladeImage}
              alt=""
            />
          </a>
        </div>
      ) : null}
      <ul className="list">
        {accolade1 ? (
          <li className="list-item">
            <IonIcon icon={bookOutline} />
            <span>{accolade1}</span>
          </li>
        ) : null}
        {accolade1 ? (
          <li className="list-item">
            <IonIcon icon={bookOutline} />
            <span>{accolade2}</span>
          </li>
        ) : null}
      </ul>
      {learnMoreLink ? (
        <a target="_blank" href={learnMoreLink}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="more-info"
            viewBox="0 0 600 600"
          >
            <title>Learn more about this author</title>
            <path
              d="M248 64C146.39 64 64 146.39 64 248s82.39 184 184 184 184-82.39 184-184S349.61 64 248 64z"
              fill="none"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="32"
            />
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="32"
              d="M220 220h32v116"
            />
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="32"
              d="M208 340h88"
            />
            <path d="M248 130a26 26 0 1026 26 26 26 0 00-26-26z" />
          </svg>
        </a>
      ) : null}
    </div>
  );

  function getClickableAccoladeTitleByType(type) {
    switch (type) {
      case "Bookstore":
        return "Go to this author's bookstore";
      case "Service":
        return "Services offered by this author";
      case "Website":
        return "Author's website";
      case "Story":
        return "Author's story";
      default:
        return "Go to this author's bookstore";
    }
  }
}

export default JudgeCard;
