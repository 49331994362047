import { useState } from "react";

function SetUploadedStory({ story, alreadyChecked, handleChangesMade }) {
  const [checked, setChecked] = useState(alreadyChecked ? true : false);

  return (
    <div className="flex flex--gap-small">
      <input
        required
        id={"_" + story.storyTitle}
        className="checkbox"
        type="checkbox"
        value="checked"
        checked={checked}
        onChange={(event) => {
          const newChecked = !checked;
          setChecked(newChecked);
          handleChangesMade(story.storyId, newChecked);
        }}
        disabled={!story.published && !alreadyChecked}
      />
      <label htmlFor={"_" + story.storyTitle} className="description">
        {story.storyTitle + (story.published ? "" : "  (***not shared)")}
      </label>
    </div>
  );
}

export default SetUploadedStory;
