import "../styles/battle.css";
import "../styles/battleQueries.css";
import { useEffect, useState } from "react";
import GetDuel from "../components/admin-duel/GetDuel";
import Duel from "../components/battle/Duel";
import { stateNames } from "../publicFunctions/publicHelpers/helpers";
import SEO from "../components/SEO";
import { useParams } from "react-router-dom";

function AdminDuel({ battles, userInfo }) {
  const params = useParams();
  const [loadedDuel, setLoadedDuel] = useState(null);
  const [battleId, setBattleId] = useState("");

  useEffect(() => {
    if (battles?.length) {
      let tempBattleId = "";
      for (let i = 0; i < battles.length; i++) {
        const battle = battles[i];
        if (battle.state === stateNames.FIRST_DUELS) {
          tempBattleId = battle.battleId;
          break;
        }
      }
      if (tempBattleId) setBattleId(tempBattleId);
    }
  }, [battles]);

  const onBattleIdChange = (updatedBattleId) => {
    if (updatedBattleId) {
      setBattleId(updatedBattleId);
    }
  };

  const onLoadDuel = (updatedDuel) => {
    setLoadedDuel(updatedDuel);
  };
  const onUnloadDuel = () => {
    setLoadedDuel(null);
  };

  const refreshMockState = () => {
    onUnloadDuel();
  };

  return (
    <div>
      <SEO
        title="Admin Duel | Writing Battle"
        description=""
        name="Writing Battle"
        type="website"
      />
      <div className="hero-background">
        <div className="battle-section">
          <div className="battle-container">
            <div className="second-stage">
              {!loadedDuel ? (
                <GetDuel
                  battleId={battleId}
                  onBattleIdChange={onBattleIdChange}
                  onLoadDuel={onLoadDuel}
                  initialDuelId={params?.duelId ? params.duelId : ""}
                />
              ) : (
                <Duel
                  refreshGameState={refreshMockState}
                  battleId={battleId}
                  loadedDuel={loadedDuel}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDuel;
