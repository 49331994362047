import { AddToCalendarButton } from "add-to-calendar-button-react";

function AddToCalendarWrapper({
  title,
  description,
  startDate,
  endDate,
  locationUrl,
}) {
  if (!startDate || !endDate) return null;
  return (
    <AddToCalendarButton
      styleLight="--btn-background: #f3f5f2; --btn-text: #010101;"
      name={title}
      description={description}
      options={[
        "Apple",
        "Google",
        "Microsoft365",
        "MicrosoftTeams",
        "Outlook.com",
        "Yahoo",
      ]}
      location={
        locationUrl ? locationUrl : "https://www.writingbattle.com/battle"
      }
      startDate={formatDate(startDate)}
      endDate={formatDate(endDate)}
      startTime={formatTime(startDate)}
      endTime={formatTime(endDate)}
      timeZone="currentBrowser"
      trigger="click"
      size="6|3|1"
    />
  );
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function formatTime(date) {
  const pad = (i) => (i < 10 ? "0" + i : "" + i);
  return pad(date.getHours()) + ":" + pad(date.getMinutes());
}

export default AddToCalendarWrapper;
