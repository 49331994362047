import ProfileCard from "../../img/profile_onCard.png";

function PastWinnerCard({
  cardImage,
  name,
  storyTitle,
  isLoading,
  isFeatured,
  featureSelectedTrigger,
  cardIndex,
  isHidden,
}) {
  const cardSelectedHandler = (i) => {
    const index = Number(i);
    if (featureSelectedTrigger && !isNaN(index)) {
      featureSelectedTrigger(index);
    }
  };
  let classTemp = "profile-card";
  if (isFeatured || isLoading) classTemp += " selected";
  if (isHidden) return null;
  return (
    <div
      className={
        isFeatured
          ? "profile-card-container featured-past-winner-card"
          : "profile-card-container"
      }
    >
      <div
        onClick={() => cardSelectedHandler(cardIndex)}
        className={classTemp}
        style={
          cardImage
            ? { backgroundImage: 'url("' + cardImage + '")' }
            : { backgroundImage: 'url("' + ProfileCard + '")' }
        }
      >
        <div className={isLoading ? "overlay" : ""}>
          <div className={isLoading ? "loading" : ""}></div>
        </div>
        <p className="past-winners-card--name">{name}</p>
        <p className="past-winners-card--title">{storyTitle}</p>
      </div>
    </div>
  );
}

export default PastWinnerCard;
