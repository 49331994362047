import { IonIcon } from "@ionic/react";
import {
  logoInstagram,
  logoFacebook,
  logoTwitter,
  bookOutline,
  globeOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";

function ProfileSocialLinks({ socials }) {
  const [renderedSocialLinks, setRenderedSocialLinks] = useState(null);

  const getIcon = (type) => {
    switch (type) {
      case "facebook":
        return logoFacebook;
      case "instagram":
        return logoInstagram;
      case "twitter":
        return logoTwitter;
      case "book":
        return bookOutline;
      case "website":
        return globeOutline;
      default:
        return globeOutline;
    }
  };

  const getUrl = (type, urlPiece) => {
    switch (type) {
      case "facebook":
        return "https://www.facebook.com/" + urlPiece;
      case "instagram":
        return "https://www.instagram.com/" + urlPiece;
      case "twitter":
        return "https://www.twitter.com/" + urlPiece;
      default:
        // we have to make sure the link starts with https and not just www
        if (urlPiece.startsWith("www.")) {
          return "https://" + urlPiece;
        } else {
          return urlPiece;
        }
    }
  };

  useEffect(() => {
    if (socials) {
      const socialTypesArray = Array.from(Object.keys(socials));
      socialTypesArray.sort();
      const tempRenderItems = socialTypesArray.map((item, index) => {
        const type = item ? item : "";
        const tempUrlPiece = socials[type] ? socials[type] : "";
        if (!tempUrlPiece) return;

        return (
          <li key={index}>
            <a
              className="footer-link"
              target="_blank"
              href={getUrl(type, tempUrlPiece)}
            >
              <IonIcon className="social-icon" icon={getIcon(type)} />
            </a>
          </li>
        );
      });

      const renderedList = (
        <ul className="social-links margin--none">{tempRenderItems}</ul>
      );
      setRenderedSocialLinks(renderedList);
    }
  }, [socials]);

  return renderedSocialLinks;
}

export default ProfileSocialLinks;
