function ForumBodyNav({ selectPageHandler, selectedPage }) {
  const selectableItemClass = "heading-tertiary debrief-nav-item";
  let discussClass = selectableItemClass;
  let betaReadClass = selectableItemClass;
  if (selectedPage === "topics") {
    discussClass += " debrief-nav-item--selected";
  } else if (selectedPage === "betaRead") {
    betaReadClass += " debrief-nav-item--selected";
  }

  return (
    <div className="container debrief-body-nav padding--none">
      <div
        tabIndex={0}
        role="button"
        onClick={() => {
          selectPageHandler("topics");
        }}
      >
        <p className={discussClass}>Discuss</p>
      </div>
      <div
        tabIndex={0}
        role="button"
        onClick={() => {
          selectPageHandler("betaRead");
        }}
      >
        <p className={betaReadClass}>Beta-Read</p>
      </div>
    </div>
  );
}

export default ForumBodyNav;
