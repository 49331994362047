function TarotMeaningsNav({ handleListChange, listKey, meaningsRef }) {
  const navClassName = "heading-tertiary debrief-nav-item";
  return (
    <div className="padding-top-bot--sm purple-background--lightest">
      <h2
        ref={meaningsRef}
        className="heading-secondary margin--none center-text"
      >
        Tarot Meanings
      </h2>
      <p className="description center-text">A guide to all 78 tarot cards</p>
      {listKey === "none" ? (
        <p className="description center-text italic">
          Click on one of the tarot suits below
        </p>
      ) : null}
      <br />
      <div className="container debrief-body-nav justify-content--center">
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            handleListChange("major");
          }}
        >
          <p
            className={
              navClassName +
              (listKey === "major" ? " debrief-nav-item--selected" : "")
            }
          >
            Major
          </p>
        </div>
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            handleListChange("wands");
          }}
        >
          <p
            className={
              navClassName +
              (listKey === "wands" ? " debrief-nav-item--selected" : "")
            }
          >
            Wands
          </p>
        </div>
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            handleListChange("cups");
          }}
        >
          <p
            className={
              navClassName +
              (listKey === "cups" ? " debrief-nav-item--selected" : "")
            }
          >
            Cups
          </p>
        </div>
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            handleListChange("swords");
          }}
        >
          <p
            className={
              navClassName +
              (listKey === "swords" ? " debrief-nav-item--selected" : "")
            }
          >
            Swords
          </p>
        </div>
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            handleListChange("coins");
          }}
        >
          <p
            className={
              navClassName +
              (listKey === "coins" ? " debrief-nav-item--selected" : "")
            }
          >
            Coins
          </p>
        </div>
      </div>
    </div>
  );
}

export default TarotMeaningsNav;
