import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../styles/everythingQuill.css";
import "./Counter";

const hiddenToolbar = {
  toolbar: false,
  counter: {
    container: "#counter",
    unit: "word",
  },
};

const modules = {
  toolbar: [
    [{ header: [false] }],
    ["bold", "italic", "underline", "strike"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
  counter: {
    container: "#counter",
    unit: "word",
  },
};

let formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "indent",
];

function MyQuillWrapper({
  storyContentHandler,
  storyContent,
  isReadOnly,
  hideHeader,
}) {
  // we need to make sure this doesn't change
  // according to api
  const placeholder = isReadOnly ? "" : "Your story goes here.";
  const toolbar = hideHeader ? hiddenToolbar : modules;

  function handleChange(content, delta, source, editor) {
    if (storyContentHandler) {
      storyContentHandler(editor.getContents());
    }
  }

  return (
    <ReactQuill
      className="story-editor break-long-words"
      theme="snow"
      value={storyContent}
      onChange={handleChange}
      modules={toolbar}
      formats={formats}
      readOnly={isReadOnly}
      placeholder={placeholder}
    />
  );
}

export default MyQuillWrapper;
