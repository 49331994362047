import { useEffect, useState } from "react";
import { caretForwardOutline, caretDownOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { getReply } from "../../publicFunctions/forumHandler";
import SelectUserButton from "../tag-users/SelectUserButton";
import ForumQuillWrapper from "./ForumQuillWrapper";

function ReplyToMessage({ replyToMessageId, isWritingReply }) {
  const [messageFromServer, setMessageFromServer] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [publicUserId, setPublicUserId] = useState("");

  useEffect(() => {
    if (replyToMessageId) {
      getReply(replyToMessageId)
        .then((data) => {
          if (data) {
            setMessageFromServer(data);
            if (data.userId) {
              setPublicUserId(data.userId);
            }
          } else {
            setMessageFromServer(null);
            setPublicUserId("");
          }
        })
        .catch((err) => {
          console.log(err);
          setMessageFromServer(null);
          setPublicUserId("");
        });
    } else {
      setMessageFromServer(null);
      setPublicUserId("");
    }
  }, [replyToMessageId]);

  return messageFromServer ? (
    <div className="reply-to-message">
      <div
        role="button"
        onClick={(event) => {
          event.preventDefault();
          setIsExpanded(!isExpanded);
        }}
        className="flex justify-content--center align-items--center flex--gap-normal expand-outline"
      >
        <IonIcon
          className="battle-icon--small"
          icon={isExpanded ? caretDownOutline : caretForwardOutline}
        ></IonIcon>
        <p className="description--small center-text margin--none">
          Replying to comment by
        </p>
        {publicUserId ? (
          <SelectUserButton
            userId={publicUserId}
            isReadOnly={true}
            clickingRemovesMe={true}
            handleWhatHappensWhenYouClickMe={() => {}}
          />
        ) : null}
      </div>
      {isExpanded ? (
        <ForumQuillWrapper
          content={messageFromServer.content}
          contentHandler={(newContent) => {}}
          isReadOnly={true}
          hideHeader={true}
        />
      ) : null}
    </div>
  ) : null;
}

export default ReplyToMessage;
