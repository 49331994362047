function PrivacyPolicy() {
  return (
    <div className="hero-background">
      <div className="container">
        <h1 className="heading-primary">Privacy Policy</h1>
        <br />
        <br />
        <br />
        <h2 className="heading-secondary margin--none">What we collect</h2>
        <p className="description margin-bottom-sm">
          We collect information necessary to operate a modern website that
          requires a username and password. When you register, this includes
          your name and email address. Since the Writing Battle website is a
          portal for uploading stories, we (of course) store story information
          and related content if you choose to submit a story. We do not store
          technical information such as IP addresses, browser types, and device
          information; however we use Google Analytics that collects this data.
        </p>
        <h2 className="heading-secondary margin--none">How we use your data</h2>
        <p className="description margin-bottom-sm">
          With the collected information, we use it to help run the website and
          admin our contests, and communicate with writers on our platform. We
          do NOT and will never use your story data for any other purpose than
          to admin the Battles. This includes having members and Official Judges
          of Writing Battle read the stories. We do not own ANY rights to ANY of
          the stories on this platform. Authors retain ALL rights to their work.
          We would never use the story data on our platform for any other
          purpose than to run our contest. We do NOT sell story data to any
          third-party. That would be bonkers for any ethical company to do that
          with your work, and we would NEVER under any circumstances do that
          with your story data.
        </p>
        <h2 className="heading-secondary margin--none">Google Analytics</h2>
        <p className="description margin-bottom-sm">
          This website uses Google Analytics, a web analytics service. It is
          operated by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
          94043, USA.
        </p>
        <p className="description margin-bottom-sm">
          Google Analytics uses so-called “cookies”. These are text files that
          are stored on your computer and that allow an analysis of the use of
          the website by you. The information generated by the cookie about your
          use of this website is usually transmitted to a Google server in the
          USA and stored there.
        </p>
        <p className="description margin-bottom-sm">
          Google Analytics cookies are stored based on Art. 6 (1) (f) GDPR. The
          website operator has a legitimate interest in analyzing user behavior
          to optimize both its website and its advertising.
        </p>

        <h2 className="heading-secondary margin--none">IP Anonymization</h2>
        <p className="description margin-bottom-sm">
          We have activated the IP anonymization feature on this website. Your
          IP address will be shortened by Google within the European Union or
          other parties to the Agreement on the European Economic Area prior to
          transmission to the United States. Only in exceptional cases is the
          full IP address sent to a Google server in the US and shortened there.
          Google will use this information on behalf of the operator of this
          website to evaluate your use of the website, to compile reports on
          website activity, and to provide other services regarding website
          activity and Internet usage for the website operator. The IP address
          transmitted by your browser as part of Google Analytics will not be
          merged with any other data held by Google.
        </p>

        <h2 className="heading-secondary margin--none">Browser Plugin</h2>
        <p className="description margin-bottom-sm">
          You can prevent these cookies from being stored by selecting the
          appropriate settings in your browser. However, we wish to point out
          that doing so may mean you will not be able to enjoy the full
          functionality of this website. You can also prevent the data generated
          by cookies about your use of the website (including your IP address)
          from being passed to Google, and the processing of these data by
          Google, by downloading and installing the browser plugin available at
          the following link:{" "}
          <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
            https://tools.google.com/dlpage/gaoptout?hl=en
          </a>
          .
        </p>

        <h2 className="heading-secondary margin--none">
          Objecting to the Collection of Data
        </h2>
        <p className="description margin-bottom-sm">
          You can prevent the collection of your data by Google Analytics by
          clicking on the following link. An opt-out cookie will be set to
          prevent your data from being collected on future visits to this site:
          Disable Google Analytics.
        </p>
        <p className="description margin-bottom-sm">
          For more information about how Google Analytics handles user data, see
          Google’s privacy policy:{" "}
          <a href="https://support.google.com/analytics/answer/6004245?hl=en">
            https://support.google.com/analytics/answer/6004245?hl=en
          </a>
          .
        </p>

        <h2 className="heading-secondary margin--none">
          Outsourced Data Processing
        </h2>
        <p className="description margin-bottom-sm">
          We have entered into an agreement with Google for the outsourcing of
          our data processing and fully implement the strict requirements of the
          German data protection authorities when using Google Analytics.
        </p>

        <h2 className="heading-secondary margin--none">
          Google AdWords and Google Conversion Tracking
        </h2>
        <p className="description margin-bottom-sm">
          This website uses Google AdWords. AdWords is an online advertising
          program from Google Inc., 1600 Amphitheater Parkway, Mountain View, CA
          94043, United States (“Google”).
        </p>
        <p className="description margin-bottom-sm">
          As part of Google AdWords, we use so-called conversion tracking. When
          you click on an ad served by Google, a conversion tracking cookie is
          set. Cookies are small text files that your internet browser stores on
          your computer. These cookies expire after 30 days and are not used for
          personal identification of the user. Should the user visit certain
          pages of the website and the cookie has not yet expired, Google and
          the website can tell that the user clicked on the ad and proceeded to
          that page.
        </p>
        <p className="description margin-bottom-sm">
          Each Google AdWords advertiser has a different cookie. Thus, cookies
          cannot be tracked using the website of an AdWords advertiser. The
          information obtained using the conversion cookie is used to create
          conversion statistics for the AdWords advertisers who have opted for
          conversion tracking. Customers are told the total number of users who
          clicked on their ad and were redirected to a conversion tracking tag
          page. However, advertisers do not obtain any information that can be
          used to personally identify users. If you do not want to participate
          in tracking, you can opt-out of this by easily disabling the Google
          Conversion Tracking cookie by changing your browser settings. In doing
          so, you will not be included in the conversion tracking statistics.
        </p>
        <p className="description margin-bottom-sm">
          Conversion cookies are stored based on Art. 6 (1) (f) GDPR. The
          website operator has a legitimate interest in analyzing user behavior
          to optimize both its website and its advertising.
        </p>
        <p className="description margin-bottom-sm">
          For more information about Google AdWords and Google Conversion
          Tracking, see the Google Privacy Policy:{" "}
          <a href="https://www.google.de/policies/privacy/">
            https://www.google.de/policies/privacy/
          </a>
          .
        </p>
        <p className="description margin-bottom-sm">
          You can configure your browser to inform you about the use of cookies
          so that you can decide on a case-by-case basis whether to accept or
          reject a cookie. Alternatively, your browser can be configured to
          automatically accept cookies under certain conditions or to always
          reject them, or to automatically delete cookies when closing your
          browser. Disabling cookies may limit the functionality of this
          website.
        </p>

        <h2 className="heading-secondary margin--none">Google Tag Manager</h2>
        <p className="description margin-bottom-sm">
          This website uses Google Tag Manager. Google Tag Manager is a solution
          operated by Google LLC. 1600 Amphitheatre Parkway, Mountain View, CA
          94043, USA (“Google”) that allows marketed website tags to be managed
          using an interface. The Tag Manager tool itself (which implements the
          tags) is a cookie-less domain and does not register personal data. The
          tool causes other tags to be activated which may, for their part,
          register data under certain circumstances. Google Tag Manager does not
          access this information. If recording has been deactivated on domain
          or cookie level, this setting will remain in place for all tracking
          tags implemented with Google Tag Manager.
        </p>

        <h2 className="heading-secondary margin--none">Data Security</h2>
        <p className="description margin-bottom-sm">
          We take security very seriously and use the latest technologies and
          best practices to keep your data safe.
        </p>
        <h2 className="heading-secondary margin--none">Your Rights</h2>
        <p className="description margin-bottom-sm">
          You have the right to access, correct, or delete your personal
          information, including all story content with the exception of your
          story title which may be listed for the users of the platform. You can
          delete your account at any time and have your personal information
          removed from our website. Please email us at admin@writingbattle.com
          if you would like your account and story information deleted.
        </p>
        <h2 className="heading-secondary margin--none">
          Changes to our Privacy Policy
        </h2>
        <p className="description margin-bottom-sm">
          We reserve the right to update this privacy policy at any time. Any
          changes will be promptly posted on this page. We encourage you to
          check our privacy policy regularly to stay informed about how we
          protect the personal information we collect. Your continued use of our
          services after any changes to this policy will signify your acceptance
          of those changes.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
