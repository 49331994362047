import { useEffect, useState } from "react";
import { getDateStringInUserTimeZone } from "../../publicFunctions/publicHelpers/helpers";

function Countdown({ date, isHomePage }) {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const timeInfo = getTimeInfo(currentTime, date);

  return (
    <div className="battle-date-countdown">
      <div className="countdown-grid">
        <div className="countdown-unit-container">
          <span>{timeInfo.days}</span>
          <p>{timeInfo.days === "1" ? "Day" : "Days"}</p>
        </div>
        <div className="countdown-unit-container">
          <span>{timeInfo.hours}</span>
          <p>{timeInfo.hours === "1" ? "Hour" : "Hours"}</p>
        </div>
        <div className="countdown-unit-container">
          <span>{timeInfo.minutes}</span>
          <p>{timeInfo.minutes === "1" ? "Minute" : "Minutes"}</p>
        </div>
        <div className="countdown-unit-container">
          <span>{timeInfo.seconds}</span>
          <p>Seconds</p>
        </div>
        <p className="center-text date-text">
          <em>
            {timeInfo.days === "0" &&
            timeInfo.hours === "0" &&
            timeInfo.minutes === "0" &&
            timeInfo.seconds === "0"
              ? "It's time." + (isHomePage ? "" : " Please refresh the page")
              : getDateStringInUserTimeZone(new Date(date))}
          </em>
        </p>
      </div>
    </div>
  );
}

const ZERO_TIME = {
  days: (0).toString(10),
  hours: (0).toString(10),
  minutes: (0).toString(10),
  seconds: (0).toString(10),
};

function getTimeInfo(current, dateToCheck) {
  if (!current || !dateToCheck) {
    return ZERO_TIME;
  }

  let timeDifference = (dateToCheck.getTime() - current.getTime()) / 1000;
  if (timeDifference > 0) {
    const daysRemaining = Math.floor(timeDifference / 86400);
    timeDifference -= daysRemaining * 86400;
    const hoursRemaining = Math.floor(timeDifference / 3600) % 24;
    timeDifference -= hoursRemaining * 3600;
    const minutesRemaining = Math.floor(timeDifference / 60) % 60;
    timeDifference -= minutesRemaining * 60;
    const secondsRemaining = Math.floor(timeDifference) % 60;
    return {
      days: daysRemaining.toString(10),
      hours: hoursRemaining.toString(10),
      minutes: minutesRemaining.toString(10),
      seconds: secondsRemaining.toString(10),
    };
  } else {
    return ZERO_TIME;
  }
}

export default Countdown;
