import { getFlagImageUrlByFlagFileName } from "../../publicFunctions/publicHelpers/imageUrlBuilder";

function DebriefFSListStory({
  match,
  story,
  houseInfo,
  myStory,
  isPublished,
  isLeft,
  otherStoryIsBye,
  debriefMeta,
  searchParams,
  setSearchParams,
}) {
  let storyTitleClass = "description center-text";
  if (myStory?.storyId === story.storyId) {
    storyTitleClass += " bold";
  }
  if (isPublished) {
    storyTitleClass += " underline cursor--pointer";
  }

  return (
    <>
      {!isLeft && !otherStoryIsBye ? (
        <p
          style={
            story.storyId === match.winner
              ? { backgroundColor: "#b5ca8d" }
              : { backgroundColor: "transparent" }
          }
          className="description center-text bold"
        >
          {match.winner ? match.rightScore.toString(10) : ""}
        </p>
      ) : null}
      {isLeft ? (
        <a
          role="button"
          href={
            "/debrief?page=houses&houseId=" +
            houseInfo.houseId +
            "&battleId=" +
            debriefMeta.battleId
          }
          onClick={(event) => {
            event.preventDefault();
            // normal left click
            if (event.button === 0) {
              const tempSearch = searchParams;
              tempSearch.set("page", "houses");
              tempSearch.set("houseId", houseInfo.houseId);
              setSearchParams(tempSearch);
            }
          }}
        >
          <img
            className="debrief-flag--small"
            src={getFlagImageUrlByFlagFileName(houseInfo.flagFileName)}
          />
        </a>
      ) : null}
      <p
        style={
          story.storyId === match.winner || otherStoryIsBye
            ? { backgroundColor: "#b5ca8d" }
            : { backgroundColor: "transparent" }
        }
        className={storyTitleClass}
      >
        <a
          style={{
            textDecoration: "none",
            color: "inherit",
            pointerEvents: isPublished ? "auto" : "none",
          }}
          href={"story/" + "debrief/" + story.storyId + "?uploadedStory"}
        >
          {story.storyTitle}
        </a>
      </p>
      {isLeft && !otherStoryIsBye ? (
        <p
          style={
            story.storyId === match.winner
              ? { backgroundColor: "#b5ca8d" }
              : { backgroundColor: "transparent" }
          }
          className="description center-text bold"
        >
          {match.winner ? match.leftScore.toString(10) : ""}
        </p>
      ) : null}

      {!isLeft ? (
        <a
          role="button"
          href={
            "/debrief?page=houses&houseId=" +
            houseInfo.houseId +
            "&battleId=" +
            debriefMeta.battleId
          }
          onClick={(event) => {
            event.preventDefault();
            // normal left click
            if (event.button === 0) {
              const tempSearch = searchParams;
              tempSearch.set("page", "houses");
              tempSearch.set("houseId", houseInfo.houseId);
              setSearchParams(tempSearch);
            }
          }}
        >
          <img
            className="debrief-flag--small"
            src={getFlagImageUrlByFlagFileName(houseInfo.flagFileName)}
          />
        </a>
      ) : null}
    </>
  );
}

export default DebriefFSListStory;
