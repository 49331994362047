import { IonIcon } from "@ionic/react";
import { logoFacebook, logoTwitter } from "ionicons/icons";

function ShareOnSocials({ socialShare }) {
  return (
    <div className="social-share">
      <p className="heading-tertiary center-text">
        Share that you joined the Battle!
      </p>
      <p className="description center-text bold">
        Earn one extra draw by sharing the upcoming Battle
      </p>
      <p className="description center-text">
        Click the buttons below and share that you're competing in the next
        Writing Battle on Facebook <em>or</em> Twitter and earn an extra draw.
      </p>
      <div
        className={
          "flex align-items--center flex--gap-small margin-auto " +
          "social-share-icon--wrapper"
        }
      >
        <button className="btn btn--register margin--none">
          <IonIcon
            onClick={() => socialShare("facebook")}
            className={"social-share--icon"}
            icon={logoFacebook}
          />
        </button>
        <p className="description center-text bold">- OR -</p>
        <button className="btn btn--register margin--none">
          <IonIcon
            onClick={() => socialShare("twitter")}
            className={"social-share--icon"}
            icon={logoTwitter}
          />
        </button>
      </div>
    </div>
  );
}

export default ShareOnSocials;
