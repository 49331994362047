import Reveal from "../animations/Reveal";
import JudgeCard from "./JudgeCard";

function JudgeCardList({ judgeCards }) {
  const renderedJudgeCards = judgeCards.map((judge) => {
    return (
      <Reveal
        type="fromBottom"
        fadeIn={true}
        delay={0.1 + Math.random() * 0.25}
        key={judge.id}
      >
        <JudgeCard
          cardImage={judge.cardImage}
          judgeName={judge.name}
          accoladeImage={judge.accoladeImage}
          goToType={judge.goToType}
          goToLink={judge.goToLink}
          accolade1={judge.accolade1}
          accolade2={judge.accolade2}
          learnMoreLink={judge.learnMoreLink}
        />
      </Reveal>
    );
  });
  return (
    <div className="grid grid--5-cols judge-cards">{renderedJudgeCards}</div>
  );
}

export default JudgeCardList;
