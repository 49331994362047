class BattleSwatch {
  constructor(
    colorKey,
    landscape,
    halfLandscape,
    quarterLandscape,
    page,
    queen,
    king,
    color,
    colorDark,
    colorDarkest
  ) {
    this.colorKey = colorKey;
    this.landscape = landscape;
    this.halfLandscape = halfLandscape;
    this.quarterLandscape = quarterLandscape;
    this.page = page;
    this.queen = queen;
    this.king = king;
    this.color = color;
    this.colorDark = colorDark;
    this.colorDarkest = colorDarkest;
  }
}

export default BattleSwatch;
