import {
  getDataFromLocalStorageWithExpiry,
  saveDataToLocalStorageWithExpiry,
} from "./localStorageHandler";

const GOOGLE_CONSENT_KEY = "GOOGLE_CONSENT_KEY";

export function hasConsented() {
  try {
    const consent = getDataFromLocalStorageWithExpiry(GOOGLE_CONSENT_KEY);
    if (consent?.granted && consent.granted === "true") {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    return true;
  }
}

export function setConsent(isTrue) {
  const consent = isTrue ? "true" : "false";
  saveDataToLocalStorageWithExpiry(
    GOOGLE_CONSENT_KEY,
    {
      granted: consent,
    },
    false,
    true,
    false,
    true
  );
}
