import { useEffect, useState } from "react";
import BattleCard from "../battle/BattleCard";
import CardState from "../../publicFunctions/clientClasses/CardState";
import DrawPromptsControls from "../battle/DrawPromptsControls";
import { shuffleArray } from "../../publicFunctions/publicHelpers/helpers";
import {
  createCardWrapperForTavernCard,
  getTavernCard,
} from "../../publicFunctions/tavernHandler";
import DeckBannerAds from "../decks/DeckBannerAds";

function PromptGenerator({
  myProfile,
  myCards,
  collectionMeta,
  requestCollectionMeta,
  requestMyCards,
  setLoadedStory,
  searchParams,
  setSearchParams,
}) {
  const [genrePrompt, setGenrePrompt] = useState(new CardState("Genre"));
  const [prompt1, setPrompt1] = useState(new CardState(""));
  const [prompt2, setPrompt2] = useState(new CardState(""));
  const [isWorking, setIsWorking] = useState(true);
  const [drawControlsLocked, setDrawControlsLocked] = useState(true);
  const [forceLockCards, setForceLockCards] = useState(true);
  const [message, setMessage] = useState("");
  const [myPromptCardsList, setMyCardsList] = useState([]);
  const [myGenreCardsList, setMyGenreCardsList] = useState([]);

  useEffect(() => {
    // set the list of cards to draw from
    if (!collectionMeta) {
      requestCollectionMeta();
    }
    if (!myCards && myProfile?.userId) {
      requestMyCards(myProfile.userId);
    } else if (myCards && collectionMeta) {
      const tempMyPromptCardsList = [];
      const tempMyGenreCardsList = [];
      const freebieMap = new Map();
      const promptsVolumeGenreCards = 20;
      for (
        let i = 0;
        collectionMeta.freebies?.length && i < collectionMeta.freebies.length;
        i++
      ) {
        freebieMap.set(collectionMeta.freebies[i], true);
      }
      for (
        let i = 0;
        collectionMeta.cardGroups?.length &&
        i < collectionMeta.cardGroups.length;
        i++
      ) {
        const cardGroup = collectionMeta.cardGroups[i];
        if (!cardGroup.cards?.length) {
          continue;
        }
        const groupName = cardGroup.groupName;
        if (
          !groupName.includes("Prompts Volume") &&
          (freebieMap.has(groupName) || myCards[groupName])
        ) {
          if (groupName.includes("Genre")) {
            tempMyGenreCardsList.push(...cardGroup.cards);
          } else {
            tempMyPromptCardsList.push(...cardGroup.cards);
          }
        } else if (groupName === "Raids") {
          for (let j = 0; j < cardGroup.cards.length; j++) {
            const card = cardGroup.cards[j];
            if (!card.silly && myCards.map.has(card.id)) {
              if (card.type === "Genre") {
                tempMyGenreCardsList.push(card);
              } else {
                tempMyPromptCardsList.push(card);
              }
            }
          }
        } else if (myCards[groupName] && groupName.includes("Prompts Volume")) {
          // the first 20 you want in the genre list, the rest in the prompts list
          for (let j = 0; j < cardGroup.cards.length; j++) {
            const card = cardGroup.cards[j];
            if (j < promptsVolumeGenreCards) {
              tempMyGenreCardsList.push(card);
            } else {
              tempMyPromptCardsList.push(card);
            }
          }
        } else {
          for (let j = 0; j < cardGroup.cards.length; j++) {
            const card = cardGroup.cards[j];
            if (myCards.map.has(card.id)) {
              if (groupName.includes("Genre")) {
                tempMyGenreCardsList.push(card);
              } else if (
                groupName.includes("Prompts Volume") &&
                j < promptsVolumeGenreCards
              ) {
                tempMyGenreCardsList.push(card);
              } else {
                tempMyPromptCardsList.push(card);
              }
            }
          }
        }
      }
      // now shuffle them both
      shuffleArray(tempMyPromptCardsList);
      shuffleArray(tempMyGenreCardsList);

      // deal the cards
      getNewCards(
        true,
        true,
        true,
        tempMyGenreCardsList,
        tempMyPromptCardsList
      ).then(() => {
        setForceLockCards(false);
      });
    }
  }, [myCards, myProfile, collectionMeta]);

  useEffect(() => {
    if (forceLockCards) {
      setDrawControlsLocked(true);
    } else {
      setDrawControlsLocked(
        genrePrompt.isLocked && prompt1.isLocked && prompt2.isLocked
          ? true
          : false
      );
    }
  }, [genrePrompt, prompt1, prompt2, forceLockCards]);

  const getNewCards = async (
    redrawGenre,
    redrawPrompt1,
    redrawPrompt2,
    genreList,
    promptList
  ) => {
    setIsWorking(true);
    const tempGenreList = [...genreList];
    const tempPromptList = [...promptList];

    if (redrawGenre && tempGenreList.length) {
      const card = tempGenreList.shift();
      await getAndSetTavernCard(
        card.id,
        0,
        tempGenreList.length ? false : true
      );
    }

    let useAltTextIfCharacter = false;
    if (!redrawPrompt2 && prompt2.type === "Character") {
      useAltTextIfCharacter = true;
    }

    if (redrawPrompt1) {
      const card = tempPromptList.shift();
      let isHardLocked = true;
      if (
        tempPromptList.length > 2 ||
        (tempPromptList.length === 1 && !redrawPrompt2)
      ) {
        isHardLocked = false;
      }
      useAltTextIfCharacter = await getAndSetTavernCard(
        card.id,
        1,
        isHardLocked,
        useAltTextIfCharacter
      );
    } else if (prompt1.type === "Character") {
      useAltTextIfCharacter = true;
    }

    if (redrawPrompt2) {
      const card = tempPromptList.shift();
      useAltTextIfCharacter = await getAndSetTavernCard(
        card.id,
        2,
        tempPromptList.length ? false : true,
        useAltTextIfCharacter
      );
    }
    if (tempPromptList.length === 1) {
      setMessage("You're down to the last prompt in your collection!");
    }
    if (tempGenreList.length === 1) {
      setMessage("You're down to the last Genre in your collection!");
    }
    if (!tempPromptList.length) {
      setMessage("You've redrawn every prompt in your collection!");
    }
    if (!tempGenreList.length) {
      setMessage("You've redrawn every Genre prompt in your collection!");
    }

    setMyGenreCardsList(tempGenreList);
    setMyCardsList(tempPromptList);
    setIsWorking(false);
  };

  const getAndSetTavernCard = async (
    cardId,
    position,
    isHardLocked,
    useAltTextIfCharacter = false
  ) => {
    const tavernCard = await getTavernCard(cardId);
    const tempState = new CardState();
    tempState.injectCardWithDataFromServer(
      createCardWrapperForTavernCard(tavernCard)
    );
    tempState.isHardLocked = isHardLocked;
    if (
      useAltTextIfCharacter &&
      tavernCard.defaultType === "Character" &&
      tavernCard.altText &&
      tavernCard.altType
    ) {
      tempState.text = tavernCard.altText;
      tempState.type = tavernCard.altType;
    } else {
      tempState.text = tavernCard.defaultText;
      tempState.type = tavernCard.defaultType;
    }
    if (tavernCard.defaultDefinition) {
      tempState.definition = tavernCard.defaultDefinition;
    }
    tempState.tavernCard = tavernCard;
    tempState.tavernCardId = tavernCard.id;
    if (position === 0) {
      setGenrePrompt(tempState);
    } else if (position === 1) {
      setPrompt1(tempState);
    } else {
      setPrompt2(tempState);
    }
    console.log(tempState);
    return tempState.type === "Character" ? true : false;
  };

  const requestUnlock = (position) => {
    const currentCard =
      position === 0 ? genrePrompt : position === 1 ? prompt1 : prompt2;
    const isGenre = position === 0 ? true : false;
    const tempCard = currentCard.clone();
    tempCard.setLocked(!tempCard.isLocked);
    if (!tempCard.isHardLocked) {
      if (isGenre) {
        setGenrePrompt(tempCard);
      } else if (position === 1) {
        setPrompt1(tempCard);
      } else if (position === 2) {
        setPrompt2(tempCard);
      }
    }
  };

  const handleRedraw = async () => {
    setDrawControlsLocked(true);
    setIsWorking(true);
    setMessage("");
    await getNewCards(
      !genrePrompt.isLocked,
      !prompt1.isLocked,
      !prompt2.isLocked,
      myGenreCardsList,
      myPromptCardsList
    );
  };

  const handleChangeOfMessage = (msg) => {
    setMessage(msg);
  };

  const handleForceLockCards = (lock) => {
    setForceLockCards(lock);
  };

  const genreDrawAmount = genrePrompt.isLocked ? "" : "-1";
  const drawAmount =
    !prompt1.isLocked && !prompt2.isLocked
      ? "-2"
      : !prompt1.isLocked || !prompt2.isLocked
      ? "-1"
      : "";

  const onSubmit = (event) => {
    event.preventDefault();
    setLoadedStory({
      storyId: "",
      fromGenerator: true,
      cards: {
        genre: {
          id: genrePrompt.tavernCardId,
          definition: genrePrompt.definition,
          imageFileName: genrePrompt.tavernCard.imageFileName,
          text: genrePrompt.text,
          type: genrePrompt.type,
        },
        prompt1: {
          id: prompt1.tavernCardId,
          imageFileName: prompt1.tavernCard.imageFileName,
          text: prompt1.text,
          type: prompt1.type,
        },
        prompt2: {
          id: prompt2.tavernCardId,
          imageFileName: prompt2.tavernCard.imageFileName,
          text: prompt2.text,
          type: prompt2.type,
        },
      },
    });
    const tempSearch = searchParams;
    tempSearch.set("page", "create");
    setSearchParams(tempSearch);
  };

  return (
    <div className="first-stage-container">
      <DeckBannerAds />
      <p className="description center-text padding--small">
        Note: this is for personal projects only. Stories from active Battles{" "}
        <strong>do not</strong> belong in the Tavern.
        <br />
        These are <strong>not</strong> your Battle prompts.
        <br />
        If you are currently participating in a Battle, then submit your story
        on the{" "}
        <a
          className="simple-underline this-anchor-is-a-button"
          href="https://www.writingbattle.com/battle"
        >
          Battle page
        </a>
        . Not Here.
      </p>
      <br />
      <button
        className="btn btn--register min-width--larger story-submission-btn max-width--readable-center"
        disabled={isWorking}
        onClick={onSubmit}
      >
        Create story with these prompts
      </button>
      <br />
      <br />
      <br />
      <br />
      <div className="battle-cards">
        <BattleCard
          cardState={genrePrompt}
          position={0}
          requestUnlock={requestUnlock}
          isWorking={isWorking}
          cardIsBeingRedrawn={isWorking && !genrePrompt.isLocked}
          forceLockCards={forceLockCards}
        />
        <BattleCard
          cardState={prompt1}
          position={1}
          requestUnlock={requestUnlock}
          isWorking={isWorking}
          cardIsBeingRedrawn={isWorking && !prompt1.isLocked}
          forceLockCards={forceLockCards}
        />
        <BattleCard
          cardState={prompt2}
          position={2}
          requestUnlock={requestUnlock}
          isWorking={isWorking}
          cardIsBeingRedrawn={isWorking && !prompt2.isLocked}
          forceLockCards={forceLockCards}
        />
      </div>
      <p className="description center-text bold">{message}</p>
      <DrawPromptsControls
        loadedGameState={{ infDraws: true }}
        drawControlsLocked={drawControlsLocked || isWorking}
        handleRedraw={handleRedraw}
        genreDrawAmount={genreDrawAmount}
        drawAmount={drawAmount}
        handleChangeOfMessage={handleChangeOfMessage}
        handleForceLockCards={handleForceLockCards}
        lockedInInfo={{ isLockedIn: false }}
        handlePrematureSubmit={() => {}}
      />
    </div>
  );
}

export default PromptGenerator;
