import "../../styles/register.css";
import { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import LogIn from "./LogIn";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";

function RegisterOverlay({ closeHandler, goToPage, userInfo }) {
  const [pageType, setPageType] = useState("login");
  const isOpen = goToPage !== "closed";

  useEffect(() => {
    if (goToPage !== "closed") {
      setPageType(goToPage);
    }
  }, [goToPage]);

  const switchPage = (pageToSwitchTo) => {
    setPageType(pageToSwitchTo);
  };

  const forgotPasswordClicked = () => {
    setPageType("forgotPassword");
  };

  return (
    <div
      className="register-overlay fade-in"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="register-box center-box">
        <button
          onClick={closeHandler}
          className="general-icon-box general-icon-box--close"
        >
          <IonIcon className="general-icon gray" icon={closeOutline} />
        </button>

        {pageType === "login" ? (
          <LogIn switchToForgotPasswordPage={forgotPasswordClicked} />
        ) : pageType === "register" ? (
          <Register />
        ) : (
          <ForgotPassword userInfo={userInfo} />
        )}
        <div className="simple-divider description--small center-text"></div>
        <a
          onClick={() =>
            switchPage(pageType === "login" ? "register" : "login")
          }
          className="description center-text clickable clickable-text"
        >
          {pageType === "login"
            ? "Not a member yet?"
            : pageType === "register"
            ? "Already a member?"
            : "Back to login"}
        </a>
      </div>
    </div>
  );
}

export default RegisterOverlay;
