import Lottie from "react-lottie-player";
import Reveal from "../animations/Reveal";
import loadingAnimation from "../../img/animations/simpleLoading.json";
import { IonIcon } from "@ionic/react";
import { cardOutline, logoPaypal } from "ionicons/icons";

function CheckoutOptionsButtons({
  onCancel,
  onPayPalClick,
  onStripeClick,
  isLoading,
}) {
  return (
    <Reveal type="fromRight" rotate={false}>
      <p className="description bold center-text">How would you like to pay?</p>
      <br />
      <div className="flex flex--gap-smaller align-items--center checkout-buttons-mobile">
        <button
          className="btn btn--register checkout-btn"
          disabled={isLoading}
          onClick={onStripeClick}
        >
          <div className="flex justify-content--center flex--align-center flex--gap-smaller">
            <IonIcon className="social-icon" icon={cardOutline} />
            Card
          </div>
          <div className={isLoading ? "simple-loader" : "hidden"}>
            <Lottie loop animationData={loadingAnimation} speed={1.5} play />
          </div>
        </button>
        <p className="description bold center-text">or</p>
        <button
          className="btn btn--register checkout-btn"
          disabled={isLoading}
          onClick={onPayPalClick}
        >
          <div className="flex justify-content--center flex--align-center flex--gap-smaller">
            <IonIcon className="social-icon" icon={logoPaypal} />
            PayPal
          </div>
          <div className={isLoading ? "simple-loader" : "hidden"}>
            <Lottie loop animationData={loadingAnimation} speed={1.5} play />
          </div>
        </button>
      </div>
      <p
        className="description--smaller right-text margin-top-smaller underline cursor--pointer"
        onClick={(event) => {
          event.preventDefault();
          onCancel();
        }}
      >
        Cancel
      </p>
    </Reveal>
  );
}

export default CheckoutOptionsButtons;
