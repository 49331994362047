import { NavLink, useNavigate } from "react-router-dom";
import SocialLinks from "../SocialLinks";
import LogoFull from "../../img/logos/writingBattleLogoFull_600.webp";
import { pageScrollSettings } from "../../publicFunctions/publicHelpers/helpers";

function Footer({ openLogInHandler, userInfo, topRef }) {
  const navigate = useNavigate();
  const now = new Date();

  const goToTopOfPage = () => {
    if (topRef?.current) {
      topRef.current.scrollIntoView(pageScrollSettings);
    }
  };

  return (
    <footer id="main-footer" className="footer">
      <div className="container grid grid--footer">
        <div className="logo-col">
          <NavLink to="/" className="footer-logo-clickable">
            <img
              className="footer-logo"
              src={LogoFull}
              alt="Writing Battle logo"
            />
          </NavLink>
          <SocialLinks />
          <p className="copyright">
            Copyright &copy;{" "}
            <span className="year">{now.getFullYear().toString(10)}</span> by
            Writing Battle, Inc. All rights reserved.
          </p>
        </div>
        <div className="grid--footer-content">
          <nav className="nav-col">
            <p className="footer-heading">Account</p>
            <ul className="footer-nav">
              <li>
                <NavLink
                  onClick={
                    !userInfo.isLoggedIn()
                      ? () => openLogInHandler("register")
                      : (event) => {
                          event.preventDefault();
                          navigate("/join-battle");
                        }
                  }
                  className="footer-link"
                >
                  Create account
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={(event) => {
                    event.preventDefault();
                    if (!userInfo.isLoggedIn()) {
                      openLogInHandler("login");
                    } else {
                      navigate("/join-battle");
                    }
                  }}
                  className="footer-link"
                >
                  Sign in
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="footer-link"
                  to="/my-account"
                  onClick={() => {
                    goToTopOfPage();
                  }}
                >
                  My Account
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="footer-link"
                  to="/privacy-policy"
                  onClick={() => {
                    goToTopOfPage();
                  }}
                >
                  Privacy Policy
                </NavLink>
              </li>
            </ul>
          </nav>
          <nav className="nav-col">
            <p className="footer-heading">Navigate</p>
            <ul className="footer-nav">
              <li>
                <NavLink
                  className="footer-link"
                  to="/battle"
                  onClick={() => {
                    goToTopOfPage();
                  }}
                >
                  Battle
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="footer-link"
                  to="/rules"
                  onClick={() => {
                    goToTopOfPage();
                  }}
                >
                  Rules
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="footer-link"
                  to="/past-winners"
                  onClick={() => {
                    goToTopOfPage();
                  }}
                >
                  Past Winners
                </NavLink>
              </li>
            </ul>
          </nav>
          <nav className="nav-col">
            <p className="footer-heading">Community</p>
            <ul className="footer-nav">
              <li>
                <NavLink
                  className="footer-link"
                  to="/forum"
                  onClick={() => {
                    goToTopOfPage();
                  }}
                >
                  Forum
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="footer-link"
                  to="/debrief"
                  onClick={() => {
                    goToTopOfPage();
                  }}
                >
                  Debrief
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="footer-link"
                  to="/join-battle"
                  onClick={() => {
                    goToTopOfPage();
                  }}
                >
                  Join Battle
                </NavLink>
              </li>
            </ul>
          </nav>
          <nav className="nav-col">
            <p className="footer-heading">Shop</p>
            <ul className="footer-nav">
              <li>
                <NavLink
                  className="footer-link"
                  to="https://www.writingbattle.shop"
                  target="_blank"
                >
                  Merch
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="footer-link"
                  to="/tarot"
                  onClick={() => {
                    goToTopOfPage();
                  }}
                >
                  Free Tarot Reading
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="footer-link"
                  to="https://www.writingbattle.shop"
                  target="_blank"
                >
                  Shop Tarot
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="address-col center-text">
        <div>
          <p className="footer-heading">Contact us</p>
          <address className="contacts">
            <p>
              Max Bjork
              <br />
              <a className="footer-link" href="mailto:admin@writingbattle.com">
                admin@writingbattle.com
              </a>
            </p>
            <p className="address">
              PO Box 36103,
              <br />
              Halifax, Nova Scotia,
              <br />
              B3J 3S9, Canada
            </p>
          </address>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
