import { collection, doc, getDoc, query, where } from "firebase/firestore";
import { db } from "../config/firebase";
import {
  convertAllTimestampsToDatesInArray,
  getQueryAsArray,
} from "./publicHelpers/helpers";

export const MAX_TYPE_PROMPT_LENGTH = 9;
export const MAX_TEXT_PROMPT_LENGTH = 18;
export const MAXIMUM_TITLE_CHARACTERS = 110;
export const MAXIMUM_DEFINITION_CHARACTERS = 1000;
export const unknownCardImageFileName = "misc/unknownCard";

export async function getMyUploads(userId) {
  const snap = await getDoc(doc(db, "myUploads", userId));
  if (snap.exists) {
    const data = snap.data();
    if (data.uploads?.length) {
      convertAllTimestampsToDatesInArray(data.uploads);
      return data.uploads;
    } else {
      return [];
    }
  } else {
    return [];
  }
}

export async function getCardCollection() {
  const snap = await getDoc(doc(db, "collectionMeta", "tavern"));
  if (snap.exists) {
    const data = snap.data();
    return data;
  } else {
    return null;
  }
}

export async function getRaids() {
  const q = query(collection(db, "raids"), where("unlocked", "==", true));
  const tempArray = await getQueryAsArray(q);
  if (tempArray?.length) {
    convertAllTimestampsToDatesInArray(tempArray);
    return tempArray;
  } else {
    return [];
  }
}

export async function getMyTavernCardsObject(userId) {
  const myTavernCardsMap = new Map();
  const toReturn = {
    map: myTavernCardsMap,
  };
  try {
    const snap = await getDoc(doc(db, "myTavernCards", userId));
    if (snap.exists) {
      const data = snap.data();
      if (data?.cardIds?.length) {
        for (let i = 0; i < data.cardIds.length; i++) {
          const cardId = data.cardIds[i];
          if (cardId) {
            myTavernCardsMap.set(cardId, true);
          }
        }
      }
      // go through all attributes and map them to this object
      for (const property in data) {
        if (property !== "cardIds") {
          toReturn[property] = true;
        }
      }
    }
  } catch (err) {
    console.log(err);
  }
  return toReturn;
}

export async function getTavernCard(cardId) {
  const snap = await getDoc(doc(db, "tavernCards", cardId));
  if (snap.exists) {
    const data = snap.data();
    return data;
  } else {
    return null;
  }
}

export function createCardWrapperForTavernCard(tavernCard) {
  return {
    id: tavernCard.id,
    imageFileName: tavernCard.imageFileName,
    text: "",
    type: "",
    definition: "",
    tavernCard: true,
  };
}

export function createHowToEarnText(howToEarnArray) {
  let stringBuild = "";
  if (howToEarnArray?.length) {
    for (let i = 0; i < howToEarnArray.length; i++) {
      const howToEarnKey = howToEarnArray[i];
      if (howToEarnArray.length === 1 && howToEarnKey === "FROM_BATTLE") {
        stringBuild +=
          "Complete a Battle with this card as one of your prompts. Keep an eye out for raid loot or a prompt deck that may contain this card";
      } else if (howToEarnKey === "FROM_BATTLE") {
        stringBuild +=
          "Complete a Battle with this card as one of your prompts";
      } else if (howToEarnKey && howToEarnKey.includes("_RAID_")) {
        const raidName = howToEarnKey.split("_RAID_")[1];
        stringBuild += "Complete the Raid: " + raidName;
      } else if (howToEarnKey === "FREEBIE") {
        return "Freebie! This card is given to every writer in Writing Battle.";
      }
      if (i < howToEarnArray.length - 1) {
        stringBuild += "; or\n";
      } else {
        stringBuild += ".";
      }
    }
  }
  return stringBuild;
}
