import Reveal from "../animations/Reveal";
import CreateAccountOrLogin from "../preview/CreateAccountOrLogin";
import BattleDatesList from "./BattleDatesList";

function StillHaveNotJoined({ userInfo, openLogInHandler, battles }) {
  return (
    <div>
      <div className="still-not-joined-bground">
        <div className="container padding-top--sm">
          <div className="how-container">
            <Reveal type="fromBottom" fadeIn={true}>
              <span className="subheading">Join today!</span>
            </Reveal>
            <Reveal type="fromBottom" fadeIn={true}>
              <h2 className="heading-secondary">
                Are you seriously still scrolling!?
              </h2>
            </Reveal>
          </div>
          <div className="preview-flex flex--gap-large preview-flex--no-margin">
            <Reveal type="fromLeft" fadeIn={true}>
              <BattleDatesList battles={battles} />
            </Reveal>
            <Reveal type="fromBottom" fadeIn={true}>
              <p className="description">
                Joining Writing Battle might be the best decision you make as a
                writer in 2024.
              </p>
              <div className="description margin-bottom-large">
                <br />
                Here are a few more reasons to join:
                <ul className="padding-left--home">
                  <li>
                    <strong>Prizes</strong> &mdash; 2025 has a total cash prize
                    pool of $112,000 USD
                  </li>
                  <li>
                    <strong>Published</strong> &mdash; Read the winning stories
                    and author interviews from previous Battles on our{" "}
                    <a
                      className="bold text-color--inherit underline"
                      href={"past-winners"}
                    >
                      Past Winners
                    </a>{" "}
                    page
                  </li>
                  <li>
                    <strong>International</strong> &mdash; We have writers from
                    all around the world. Cash prizes are converted to the
                    currency of your choice. Register for a Battle with USD,
                    CAD, GBP, EUR, AUD, or NZD
                  </li>
                  <li>
                    <strong>Fun</strong> &mdash; Writing Battle has been
                    described as a sports league for creatives. It's about time
                    us writer nerds had an outlet for comradery and a friendly
                    tournament-style competition. We hope to see you in the
                    Battle!
                  </li>
                </ul>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
      <div className="yellow-background padding-bottom">
        <div className="triangle-element"></div>
        <h2 className="heading-secondary center-text bottom-margin--small">
          Write something awesome.
        </h2>
        <CreateAccountOrLogin
          userInfo={userInfo}
          openLogInHandler={openLogInHandler}
          isActive={false}
        />
      </div>
    </div>
  );
}

export default StillHaveNotJoined;
