import { db } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";

// TAROT STUFF
export const TAROT_COOKIE_KEY = "tarot";
export const TAROT_PREVIEW_1 =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2FbattleRavenTarot%2Ffront_flat-ish.webp?alt=media";
export const TAROT_PREVIEW_2 =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2FbattleRavenTarot%2Fopen_empty_card_spread.webp?alt=media";
export const TAROT_PREVIEW_3 =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2FbattleRavenTarot%2Fbunch_of_cards.webp?alt=media";
export const TAROT_PREVIEW_4 =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2FbattleRavenTarot%2Fopen_box_full.webp?alt=media";
export const TAROT_PREVIEW_5 =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2FbattleRavenTarot%2Fback_flat.webp?alt=media";
export const TAROT_PREVIEW_6 =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2FbattleRavenTarot%2Fthree_cards_1.webp?alt=media";
export const TAROT_PREVIEW_7 =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2FbattleRavenTarot%2Fthree_cards_2.webp?alt=media";
export const TAROT_PREVIEW_8 =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2FbattleRavenTarot%2Fone_card.webp?alt=media";
export const TAROT_PREVIEW_9 =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2FbattleRavenTarot%2Ffront_2.webp?alt=media";

export const VINCENZO_PREVIEW_1 =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2FbattleRavenTarot%2Fnoire.webp?alt=media";
export const VINCENZO_PREVIEW_2 =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2FbattleRavenTarot%2Fvincenzo_art.webp?alt=media";

export async function getFreeTarotInfo() {
  const refForumMeta = doc(db, "tarot", "freeTarot");
  const obj = await getDoc(refForumMeta);
  if (obj.exists()) {
    return obj.data();
  } else {
    return null;
  }
}

export function constructTarotUrl(tarotNumber, isTiny = false) {
  const fileName =
    tarotNumber.toString(10) +
    "_Tarot" +
    (isTiny ? "_tiny" : "") +
    ".webp?alt=media";
  return (
    "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/tarotCards%2F" +
    fileName
  );
}
