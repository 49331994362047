import "../styles/profile.css";
import "../styles/profileQueries.css";
import { doc, updateDoc } from "firebase/firestore";
import { IonIcon } from "@ionic/react";
import {
  pencilOutline,
  checkmarkCircleOutline,
  closeCircle,
} from "ionicons/icons";
import BackgroundProfile from "../img/background_profile_2.webp";
import DefaultProfilePicture from "../img/profile_picture.png";
import Lottie from "react-lottie-player";
import loadingAnimation from "../img/animations/simpleLoading.json";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ProfileSocialLinks from "../components/profile/ProfileSocialLinks";
import { getPublicProfile } from "../publicFunctions/publicProfileHandler";
import { db, uploadImage } from "../config/firebase";
import LoadingSection from "../components/LoadingSection";
import ProfileTrophies from "../components/profile/ProfileTrophies";
import SelectUploadedStories from "../components/profile/SelectUploadedStories";
import FeaturedStories from "../components/profile/FeaturedStories";
import SEO from "../components/SEO";
import SharingSettings from "../components/SharingSettings";
import { convertAllTimestampsToDatesInArray } from "../publicFunctions/publicHelpers/helpers";
import { getMyUploads } from "../publicFunctions/tavernHandler";

function Profile({ userInfo, myProfile, refreshMyProfile }) {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [publicId, setPublicId] = useState(params.publicId);
  const [userId, setUserId] = useState("");
  const [editable, setEditableMode] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [name, setName] = useState("");
  const [bio, setBio] = useState(null);
  const [socials, setSocials] = useState({});
  const [location, setLocation] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [pageInitialized, setPageInitialized] = useState(false);
  const [editingLocation, setEditingLocation] = useState(false);
  const [editingBio, setEditingBio] = useState(false);
  const [editBio, setEditBio] = useState("");
  const [editCity, setEditCity] = useState("");
  const [editCountry, setEditCountry] = useState("");
  const [editingSocials, setEditingSocials] = useState(false);
  const [editFacebook, setEditFacebook] = useState("");
  const [editInstagram, setEditInstagram] = useState("");
  const [editTwitter, setEditTwitter] = useState("");
  const [editWebsite, setEditWebsite] = useState("");
  const [editBook, setEditBook] = useState("");
  const [featuredStories, setFeaturedStories] = useState(null);
  const [editingFeaturedStories, setEditingFeaturedStories] = useState(false);
  const [trophiesDisplayed, setTrophiesDisplayed] = useState(null);
  const [isPublic, setIsPublic] = useState(false);
  const [tavernStories, setTavernStories] = useState(null);

  useEffect(() => {
    // When this first loads, get all of the tavern stories (if any exist)
    if (userId && editable) {
      getMyUploads(userId).then((tempUploads) => {
        setTavernStories(tempUploads);
      });
    }
  }, [userId, editable]);

  useEffect(() => {
    // get profile
    getPublicProfile(publicId)
      .then((result) => {
        setMessage("");
        setPageInitialized(true);
        if (result) {
          // profile pic
          setUserId(result.userId);
          if (result.profilePic) {
            setProfilePicture(
              result.profilePic + "?" + Date.now().toString(10)
            );
          } else {
            setProfilePicture("");
          }

          setIsPublic(result.superPublic ? true : false);

          // name
          if (result.name) {
            setName(result.name);
          } else {
            setName("");
          }

          // featuredStories
          if (result.featuredStories?.length) {
            convertAllTimestampsToDatesInArray(result.featuredStories);
            setFeaturedStories(result.featuredStories);
          } else {
            setFeaturedStories(null);
          }

          // socials
          if (result.socials) {
            setSocials(result.socials);
            if (result.socials.facebook) {
              setEditFacebook(result.socials.facebook);
            }
            if (result.socials.instagram) {
              setEditInstagram(result.socials.instagram);
            }
            if (result.socials.twitter) {
              setEditTwitter(result.socials.twitter);
            }
            if (result.socials.website) {
              setEditWebsite(result.socials.website);
            }
            if (result.socials.book) {
              setEditBook(result.socials.book);
            }
          } else {
            setSocials(null);
            setEditFacebook("");
            setEditInstagram("");
            setEditTwitter("");
            setEditWebsite("");
            setEditBook("");
          }

          // bio
          if (result.bio) {
            const tempBioText = result.bio.text.split("\\r\\n").join("\n");
            const tempBio = result.bio;
            tempBio.text = tempBioText;
            setBio(tempBio);
            setEditBio(tempBioText);
          } else {
            setBio("");
            setEditBio("");
          }

          // trophies displayed
          setTrophiesDisplayed(result.trophiesDisplayed);

          // location
          if (result.location?.city || result.location?.country) {
            const city = result.location.city ? result.location.city : "";
            const country = result.location.country
              ? result.location.country
              : "";
            setEditCity(city);
            setEditCountry(country);

            let tempLocation = city;
            if (!tempLocation) {
              tempLocation = country;
            } else {
              tempLocation += ", " + country;
            }
            setLocation(tempLocation);
          } else {
            setLocation(null);
            setEditCity("");
            setEditCountry("");
          }
        } else {
          setMessage(
            "Hmmmm... we couldn't find this profile. It's possible that it's no longer public."
          );
        }
      })
      .catch((err) => {
        if (err?.message) {
          if (err.message.includes("insufficient permissions")) {
            setIsLoading(false);
            setMessage(
              "Error: You must have registered for a Battle within " +
                "the last year to view other profiles or edit your own profile." +
                " If you just signed up for a Battle, then please try logging out then logging back in again."
            );
            setPageInitialized(true);
          }
        }
      });
  }, [userInfo, publicId]);

  useEffect(() => {
    if (userInfo?.isLoggedIn() && myProfile) {
      if (myProfile.publicId === publicId) {
        setEditableMode(true);
      } else {
        setEditableMode(false);
      }

      // updated trophies displayed
      if (myProfile?.publicProfile?.trophiesDisplayed && editable) {
        setTrophiesDisplayed(myProfile.publicProfile.trophiesDisplayed);
      }

      // updated featured stories
      if (myProfile?.publicProfile?.featuredStories && editable) {
        convertAllTimestampsToDatesInArray(
          myProfile.publicProfile.featuredStories
        );
        setFeaturedStories(myProfile.publicProfile.featuredStories);
      } else if (editable) {
        setFeaturedStories(null);
      }
    }
  }, [userInfo, myProfile, publicId]);

  useEffect(() => {
    if (selectedImage) {
      setIsLoading(true);
      setMessage("Uploading...");
      const reader = new FileReader();
      reader.onloadend = async (event) => {
        const image = event.target.result;
        try {
          const result = await uploadImage({ image });
          if (result?.data?.imageUrl) {
            setProfilePicture(
              result.data.imageUrl + "?" + Date.now().toString(10)
            );
            setSelectedImage(null);
            setIsLoading(false);
            setMessage(
              "Success! Please note that new images " +
                "can take up to 24 hours to show up across the entire site."
            );
          }
        } catch (error) {
          console.error(error);
          setMessage(error.message);
          setIsLoading(false);
        }
      };
      reader.readAsDataURL(selectedImage);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (params?.publicId) {
      setPublicId(params.publicId);
    }
  }, [params]);

  const refreshHandler = (toPublicOrPrivate) => {
    // refresh the page
    window.location.reload();
  };

  const showMessageToAddSocials =
    editable &&
    !editingSocials &&
    !editFacebook &&
    !editInstagram &&
    !editTwitter &&
    !editWebsite &&
    !editBook;

  return (
    <div className="hero-background">
      <SEO
        title={(name ? name : "Profile") + " | Writing Battle"}
        description=""
        name="Writing Battle"
        type="website"
      />
      <div className="profile-container">
        {editable ? (
          <SharingSettings
            type={"Profile"}
            isPublic={isPublic}
            refreshHandler={refreshHandler}
            documentId={publicId}
          />
        ) : null}
        <div className="profile-staging">
          {!pageInitialized ? (
            <LoadingSection />
          ) : (
            <div
              className="profile-grid"
              style={{
                backgroundImage: `url(${BackgroundProfile})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundColor: "#e6ded3",
              }}
            >
              <div></div>
              <div className="profile-pic-container">
                <label htmlFor="image-input">
                  <img
                    className={
                      !editable || isLoading
                        ? "user-image"
                        : profilePicture
                        ? "user-image pic-change profile-editable"
                        : "user-image pic-upload profile-editable"
                    }
                    src={
                      !profilePicture ? DefaultProfilePicture : profilePicture
                    }
                  />
                  <p className="edit-picture-text description center-text">
                    {!editable || isLoading
                      ? ""
                      : profilePicture
                      ? "Change Photo"
                      : "Upload Photo"}
                  </p>
                  <div
                    className={
                      isLoading
                        ? "simple-loader simple-loader--bottom-right"
                        : "hidden"
                    }
                  >
                    <Lottie
                      loop
                      animationData={loadingAnimation}
                      speed={1.5}
                      play
                    ></Lottie>
                  </div>
                </label>
                <input
                  id="image-input"
                  type="file"
                  name="myImage"
                  accept="image/png, image/jpeg, image/jpg"
                  disabled={isLoading || !editable}
                  onChange={(event) => {
                    const file = event.target.files[0];
                    if (!file) return;
                    const lowercaseName = file.name.toLowerCase();
                    if (
                      !lowercaseName.endsWith(".png") &&
                      !lowercaseName.endsWith(".jpg") &&
                      !lowercaseName.endsWith(".jpeg")
                    ) {
                      setMessage(
                        "Invalid file type. The image must be a .jpg or .png"
                      );
                    } else {
                      setMessage("");
                      setSelectedImage(event.target.files[0]);
                    }
                  }}
                />
              </div>
              <ProfileTrophies
                editable={editable}
                trophyBank={
                  editable && myProfile?.trophyBank ? myProfile.trophyBank : []
                }
                trophiesDisplayed={trophiesDisplayed}
                refreshMyProfile={refreshMyProfile}
              />
            </div>
          )}
          <div className="profile-content">
            <p className="description center-text">{message}</p>
            <p className="heading-secondary center-text bold profile-name">
              {name}
            </p>

            {/* Location */}
            {editingLocation ? (
              <div>
                <div className="editing-location register-form">
                  <input
                    type="text"
                    placeholder="City"
                    value={editCity}
                    onChange={(event) => {
                      setEditCity(event.target.value);
                    }}
                  />
                  <p className="description--large center-text bold">,</p>
                  <input
                    type="text"
                    placeholder="State, Province, or Country"
                    value={editCountry}
                    onChange={(event) => {
                      setEditCountry(event.target.value);
                    }}
                  />
                </div>

                <div className="redraw--youSure align-self--center margin-auto">
                  <p>All done?</p>
                  <IonIcon
                    onClick={async () => {
                      const docRef = doc(db, "publicUsers", userId);
                      await updateDoc(docRef, {
                        location: {
                          city: editCity,
                          country: editCountry,
                        },
                      });
                      let tempLocation = editCity;
                      if (!tempLocation) {
                        tempLocation = editCountry;
                      } else {
                        tempLocation += ", " + editCountry;
                      }
                      setLocation(tempLocation);
                      setEditingLocation(false);
                    }}
                    className="im-sure"
                    icon={checkmarkCircleOutline}
                  />
                  <IonIcon
                    onClick={() => {
                      setEditingLocation(false);
                    }}
                    className="not-sure"
                    icon={closeCircle}
                  />
                </div>
              </div>
            ) : (
              <div
                onClick={() => {
                  if (editable) {
                    setEditingLocation(true);
                  }
                }}
                className="editable-text-container"
              >
                <p
                  className={
                    !editable
                      ? "description--large center-text"
                      : "description--large center-text profile-editable"
                  }
                >
                  {location
                    ? location
                    : editable
                    ? "Click here to add a location"
                    : ""}
                </p>
                {editable ? (
                  <IonIcon
                    className="social-icon editable-text--icon"
                    icon={pencilOutline}
                  />
                ) : null}
              </div>
            )}

            {/* BIOGRAPHY */}
            {editingBio ? (
              <div>
                <div className="editing-location register-form">
                  <textarea
                    className="feedback-text-area center-text margin--none"
                    type="text"
                    placeholder="Enter a short bio here..."
                    value={editBio}
                    onChange={(event) => {
                      setEditBio(event.target.value);
                    }}
                  />
                </div>

                <div className="redraw--youSure align-self--center margin-auto">
                  <p>All done?</p>
                  <IonIcon
                    onClick={async () => {
                      const docRef = doc(db, "publicUsers", userId);
                      const tempBio = bio;
                      tempBio.text = editBio;
                      await updateDoc(docRef, {
                        bio: tempBio,
                      });
                      setBio(tempBio);
                      setEditingBio(false);
                    }}
                    className="im-sure"
                    icon={checkmarkCircleOutline}
                  />
                  <IonIcon
                    onClick={() => {
                      setEditingBio(false);
                      setEditBio(bio.text);
                    }}
                    className="not-sure"
                    icon={closeCircle}
                  />
                </div>
              </div>
            ) : (
              <div
                onClick={() => {
                  if (editable) {
                    setEditingBio(true);
                  }
                }}
                className="editable-text-container"
              >
                <p
                  className={
                    !editable
                      ? "description--small center-text " +
                        "max-width--readable-center profile-text"
                      : "description--small center-text " +
                        "max-width--readable-center profile-text profile-editable"
                  }
                >
                  {bio?.text
                    ? bio.text
                    : editable
                    ? "Click here to add a short bio!"
                    : ""}
                </p>
                {editable ? (
                  <IonIcon
                    className="social-icon editable-text--icon"
                    icon={pencilOutline}
                  />
                ) : null}
              </div>
            )}

            <br />
            <br />

            {/* Socials */}
            {editingSocials ? (
              <div className="register-form editing-socials">
                <div className="flex--align-center max-width--readable-center editing-socials--grid">
                  <label className="margin--none" htmlFor="facebookEdit">
                    Facebook
                  </label>
                  <p className="description--large bold justify-self--end">@</p>
                  <input
                    id="facebookEdit"
                    type="text"
                    placeholder="username"
                    value={editFacebook}
                    onChange={(event) => {
                      setEditFacebook(event.target.value);
                    }}
                  />
                </div>
                <div className="flex--align-center max-width--readable-center editing-socials--grid">
                  <label className="margin--none" htmlFor="instagramEdit">
                    Instagram
                  </label>
                  <p className="description--large bold justify-self--end">@</p>
                  <input
                    id="instagramEdit"
                    type="text"
                    placeholder="username"
                    value={editInstagram}
                    onChange={(event) => {
                      setEditInstagram(event.target.value);
                    }}
                  />
                </div>
                <div className="flex--align-center max-width--readable-center editing-socials--grid">
                  <label className="margin--none" htmlFor="twitterEdit">
                    Twitter
                  </label>
                  <p className="description--large bold justify-self--end">@</p>
                  <input
                    id="twitterEdit"
                    type="text"
                    placeholder="username"
                    value={editTwitter}
                    onChange={(event) => {
                      setEditTwitter(event.target.value);
                    }}
                  />
                </div>
                <div
                  className={
                    "flex--align-center max-width--readable-center" +
                    " editing-socials--grid editing-socials--grid-website"
                  }
                >
                  <label className="margin--none" htmlFor="websiteEdit">
                    Website
                  </label>
                  <input
                    id="websiteEdit"
                    type="text"
                    placeholder="https://www.yourwebsite.com"
                    value={editWebsite}
                    onChange={(event) => {
                      setEditWebsite(event.target.value);
                    }}
                  />
                </div>
                <div
                  className={
                    "flex--align-center max-width--readable-center" +
                    " editing-socials--grid editing-socials--grid-website"
                  }
                >
                  <label className="margin--none" htmlFor="bookEdit">
                    Your Book
                  </label>
                  <input
                    id="bookEdit"
                    type="text"
                    placeholder="https://www.whereWeCanBuyYourBook.com"
                    value={editBook}
                    onChange={(event) => {
                      setEditBook(event.target.value);
                    }}
                  />
                </div>

                <div className="redraw--youSure align-self--center margin-auto">
                  <p>All done?</p>
                  <IonIcon
                    onClick={async () => {
                      const docRef = doc(db, "publicUsers", userId);
                      const tempSocials = socials;
                      tempSocials.facebook = editFacebook;
                      tempSocials.instagram = editInstagram;
                      tempSocials.twitter = editTwitter;
                      tempSocials.website = editWebsite;
                      tempSocials.book = editBook;
                      await updateDoc(docRef, {
                        socials: tempSocials,
                      });
                      setSocials(socials);
                      setEditingSocials(false);
                    }}
                    className="im-sure"
                    icon={checkmarkCircleOutline}
                  />
                  <IonIcon
                    onClick={() => {
                      setEditingSocials(false);
                    }}
                    className="not-sure"
                    icon={closeCircle}
                  />
                </div>
              </div>
            ) : (
              <div
                onClick={() => {
                  if (editable) {
                    setEditingSocials(true);
                  }
                }}
                className="editable-text-container"
              >
                {showMessageToAddSocials ? (
                  <p
                    className={
                      "description--small center-text " +
                      "max-width--readable-center profile-text profile-editable"
                    }
                  >
                    Click here to add socials to your bio!
                  </p>
                ) : (
                  <div className={editable ? "profile-editable" : ""}>
                    <ProfileSocialLinks socials={socials} />
                  </div>
                )}
                {editable ? (
                  <IonIcon
                    className="social-icon editable-text--icon"
                    icon={pencilOutline}
                  />
                ) : null}
              </div>
            )}

            <br />
            <br />
            {/* FEATURED STORIES */}
            {!editingFeaturedStories ? (
              <div
                onClick={() => {
                  if (editable && !featuredStories?.length) {
                    setEditingFeaturedStories(true);
                  }
                }}
                className="editable-text-container"
              >
                {/* Figure out what we're going to do about the story listings here */}
                {featuredStories?.length ? (
                  <FeaturedStories
                    featuredStories={featuredStories}
                    editable={editable}
                    handleChangeToEditing={() => {
                      setEditingFeaturedStories(true);
                    }}
                  />
                ) : (
                  <p
                    className={
                      !editable
                        ? "description--small center-text " +
                          "max-width--readable-center profile-text"
                        : "description--small center-text " +
                          "max-width--readable-center profile-text profile-editable"
                    }
                  >
                    {editable &&
                    (myProfile.uploads?.length || tavernStories?.length)
                      ? "Click here to add the stories you've shared to your profile!"
                      : ""}
                  </p>
                )}
                {editable && !featuredStories?.length ? (
                  <IonIcon
                    className="social-icon editable-text--icon"
                    icon={pencilOutline}
                  />
                ) : null}
              </div>
            ) : editable && editingFeaturedStories && myProfile?.uploads ? (
              <>
                <br />
                <br />
                <SelectUploadedStories
                  featuredStories={featuredStories}
                  uploads={myProfile.uploads}
                  cancelEditingFeaturedStories={() => {
                    setEditingFeaturedStories(false);
                  }}
                  refreshMyProfile={refreshMyProfile}
                  userId={userId}
                  tavernStories={tavernStories}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
