import { useRef, useState } from "react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/simpleLoading.json";
import ReCAPTCHA from "react-google-recaptcha";
import {
  createUserAccount,
  logInWithEmailAndPassword,
} from "../../publicFunctions/registerHandler";
import { useNavigate } from "react-router-dom";

function Register() {
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [whereYouHear, setWhereYouHear] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const recaptchaRef = useRef();

  const navigate = useNavigate();

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    // Maybe we don't care about captcha?
    // recaptchaRef.current.executeAsync().then((token) => {
    //   console.log(token);
    // });
    try {
      await createUserAccount(
        firstName,
        lastName,
        email,
        password,
        whereYouHear
      );
      await logInWithEmailAndPassword(email, password);

      // we only want to navigate to join battle if we're not on the gifted-battle page
      if (!window?.location?.pathname?.includes("/gifted-battle")) {
        navigate("/join-battle");
      }
    } catch (err) {
      let message = err && err.message ? err.message : "";
      // insert warning here if they already have an account
      if (
        message.includes(
          "The email address is already in use by another account."
        )
      ) {
        message +=
          " If you already have an account, click 'Already a Member?' below and " +
          "log in or if you have not reset your password since the site change, click " +
          "'Already a Member' then 'Forgot password?' and reset your password.";
      }
      setErrorMessage(message);
      setIsLoading(false);
    }
  };

  const onFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const onLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const onWhereYouHearChange = (event) => {
    setWhereYouHear(event.target.value);
  };

  return (
    <div>
      <form className="register-form" onSubmit={onSubmit}>
        <div className="flex flex--gap-small">
          <div>
            <label htmlFor="firstNameRegister">First Name</label>
            <input
              autoFocus
              id="firstNameRegister"
              type="text"
              placeholder="Jane"
              required
              value={firstName}
              onChange={onFirstNameChange}
            />
          </div>
          <div>
            <label htmlFor="lastNameRegister">Last Name</label>
            <input
              id="lastNameRegister"
              type="text"
              placeholder="Doe"
              required
              value={lastName}
              onChange={onLastNameChange}
            />
          </div>
        </div>
        <div>
          <label htmlFor="emailRegister">Email</label>
          <input
            id="emailRegister"
            type="email"
            placeholder="me@example.com"
            required
            value={email}
            onChange={onEmailChange}
          />
        </div>
        <div>
          <label htmlFor="passwordRegister">Password</label>
          <input
            id="passwordRegister"
            type="password"
            placeholder="password"
            required
            value={password}
            onChange={onPasswordChange}
          />
        </div>
        <div>
          <label htmlFor="select-whereRegister">
            Where did you hear about us?
          </label>
          <select
            name=""
            id="select-whereRegister"
            required
            value={whereYouHear}
            onChange={onWhereYouHearChange}
          >
            <option value="">Please choose one option:</option>
            <option value="googleAd">Google Ad</option>
            <option value="facebook">Facebook</option>
            <option value="instagram">Instagram</option>
            <option value="twitter">Twitter</option>
            <option value="writersOnlineNewsletter">
              Writers Online Newsletter
            </option>
            <option value="friends">Friends and family</option>
            <option value="nycm">NYCM forums</option>
            <option value="searchEngine">Search engine</option>
            <option value="other">Other</option>
          </select>
        </div>
        {errorMessage ? (
          <p className="description error-text">{errorMessage}</p>
        ) : null}
        <button className="btn btn--register" disabled={isLoading}>
          Create Account
          <div className={isLoading ? "simple-loader" : "hidden"}>
            <Lottie
              loop
              animationData={loadingAnimation}
              speed={1.5}
              play
            ></Lottie>
          </div>
        </button>
      </form>
      <div>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey="6LfnWFslAAAAAB-TcxUWGVHSo2yRplMeRFIonhUb"
        />
      </div>
    </div>
  );
}

export default Register;
