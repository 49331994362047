import { useEffect, useState } from "react";
import { updateUploadedStory } from "../../config/firebase";
import { IonIcon } from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import { getCardImageUrlByCard } from "../../publicFunctions/publicHelpers/imageUrlBuilder";
import {
  cloneContentWarningObjectFromServer,
  commentType2,
  commentType3,
  commentType4,
  commentTypeDefault,
} from "../../publicFunctions/debriefHandler";
import ContentWarningSelector from "../ContentWarningSelector";

function MyDebriefStory({
  myUploadedStory,
  refreshMyUploadedStory,
  myProfile,
  resetListHandler,
  isTavern,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [myRenderedCards, setMyRenderedCard] = useState(null);
  const [contentWarning, setContentWarning] = useState("");
  const [debriefMessage, setDebriefMessage] = useState("");
  const [commentsEnabled, setCommentsEnabled] = useState(false);
  const [iUnderstand, setIUnderstand] = useState(false);
  const [publishThisStory, setPublishThisStory] = useState(true);
  const [changesMade, setChangesMade] = useState(false);
  const [audioLink, setAudioLink] = useState("");
  const [typeOfComments, setTypeOfComments] = useState("");
  const [featureThisStoryOnMyProfile, setFeatureThisStoryOnMyProfile] =
    useState(true);
  const [contentWarningObject, setContentWarningObject] = useState(
    myUploadedStory?.contentWarningObject
      ? cloneContentWarningObjectFromServer(
          myUploadedStory.contentWarningObject
        )
      : null
  );

  // we guarantee that myUploadedStory is not null
  useEffect(() => {
    const renderedCards = [];
    renderedCards.push(
      <img
        className="debrief-genre-title-card"
        key={"genre_card"}
        src={getCardImageUrlByCard(myUploadedStory.cards.genre, true)}
      />
    );
    renderedCards.push(
      <img
        className="debrief-genre-title-card"
        key={"prompt1_card"}
        src={getCardImageUrlByCard(myUploadedStory.cards.prompt1, true)}
      />
    );
    renderedCards.push(
      <img
        className="debrief-genre-title-card"
        key={"prompt2_card"}
        src={getCardImageUrlByCard(myUploadedStory.cards.prompt2, true)}
      />
    );
    setMyRenderedCard(renderedCards);
    setContentWarning(
      myUploadedStory.contentWarning ? myUploadedStory.contentWarning : ""
    );
    setAudioLink(myUploadedStory.audioLink ? myUploadedStory.audioLink : "");
    setTypeOfComments(
      myUploadedStory.typeOfComments ? myUploadedStory.typeOfComments : ""
    );
    setDebriefMessage(myUploadedStory.message ? myUploadedStory.message : "");

    // We want to have the default for this form to ENABLE comments
    // but only if the user has not published it yet
    const currentEnabled = myUploadedStory.commentsEnabled ? true : false;
    const tempEnabled = myUploadedStory.published ? currentEnabled : true;
    setCommentsEnabled(tempEnabled);
    setPublishThisStory(true);
    setIUnderstand(false);
  }, [myUploadedStory]);

  const onUpdateUploadedStory = (event) => {
    event.preventDefault();
    if (!iUnderstand && !myUploadedStory.published) return;
    setStatusMessage("One moment, please...");
    setIsLoading(true);
    setChangesMade(false);
    let tempContentWarning = "";
    if (contentWarningObject) {
      tempContentWarning = contentWarningObject.print();
    } else if (contentWarning) {
      tempContentWarning = contentWarning;
    }
    updateUploadedStory({
      storyId: myUploadedStory.storyId,
      contentWarning: tempContentWarning,
      message: debriefMessage,
      userCommentsEnabled: commentsEnabled,
      publishThisStory,
      audioLink,
      typeOfComments,
      featureThisStoryOnMyProfile,
      contentWarningObject: JSON.parse(JSON.stringify(contentWarningObject)),
    })
      .then((result) => {
        setStatusMessage("Success!");
        refreshMyUploadedStory(result.data.updated);
        resetListHandler();
      })
      .catch((err) => {
        setStatusMessage(err.message);
        setChangesMade(true);
        //todo handle error
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const goToStoryClass = "my-debrief--title-wrapper";

  return (
    <div className="my-debrief-story blue-background">
      <div className="my-debrief-story--content">
        <div className={goToStoryClass}>
          <p className="description--large center-text margin-bottom-sm">
            {myUploadedStory.published
              ? "Go to your story"
              : "Share your story"}
          </p>
          <div className="flex align-items--center justify-content--center">
            {myRenderedCards}
          </div>
          <p className="description bold center-text">
            <a
              className="center-text text-dec--none text-color--inherit clickable-text cursor--pointer"
              href={
                "/story/debrief/" + myUploadedStory.storyId + "?uploadedStory"
              }
            >
              {myUploadedStory.storyTitle}
            </a>
          </p>
          {myProfile?.publicProfile?.name ? (
            <p className="description center-text">
              {"by " + myProfile.publicProfile.name}
            </p>
          ) : null}
          {!myUploadedStory.published && !myUploadedStory.superPublic ? (
            <p className="description--smaller center-text">
              Note: this story is NOT shared in{" "}
              {isTavern ? "the Tavern" : "Debrief"}, although you can click the
              link above to read your own story.
            </p>
          ) : !myUploadedStory.published && myUploadedStory.superPublic ? (
            <p className="description--smaller center-text">
              IMPORTANT: Although your story is not shared in{" "}
              {isTavern ? "the Tavern" : "Debrief"}, it looks like it is still
              shared publicly with anyone that has a link. If this was not
              intentional, then please click the title above and on the Story
              page, change the share settings in the top right.
            </p>
          ) : null}
        </div>
        <div className="register-form my-debrief--message-wrap">
          {contentWarning && !contentWarningObject ? (
            <div>
              <label htmlFor="contentWarning">Content Warning</label>
              <input
                style={{ backgroundColor: "#f3f5f2" }}
                id="contentWarning"
                type="text"
                placeholder="list content warnings (if any)"
                value={contentWarning}
                onChange={(event) => {
                  setContentWarning(event.target.value);
                  setChangesMade(true);
                  setStatusMessage("");
                }}
                disabled={isLoading}
              />
            </div>
          ) : (
            <ContentWarningSelector
              contentWarningObject={contentWarningObject}
              setContentWarningObject={setContentWarningObject}
              isLoading={isLoading}
              setChangesMade={setChangesMade}
            />
          )}
          <div>
            <label htmlFor="audioLink">Audio Link</label>
            <input
              style={{ backgroundColor: "#f3f5f2" }}
              id="audioLink"
              type="text"
              placeholder="audio link (if any)"
              value={audioLink}
              onChange={(event) => {
                setAudioLink(event.target.value);
                setChangesMade(true);
                setStatusMessage("");
              }}
              disabled={isLoading}
            />
          </div>
          <div>
            <label htmlFor="debriefMessage">Message</label>
            <textarea
              className="feedback-text-area text-area--resize-none"
              id="debriefMessage"
              type="text"
              placeholder="message to the reader"
              value={debriefMessage}
              onChange={(event) => {
                setDebriefMessage(event.target.value);
                setChangesMade(true);
                setStatusMessage("");
              }}
              disabled={isLoading}
            />
          </div>
        </div>
        <div className="my-debrief--controls my-debrief--message-wrap">
          <p className="description">User comments enabled?</p>
          <div className="flex align-items--center flex--gap-normal">
            <input
              className="radio-button"
              type="radio"
              id="commentsOn"
              value="commentsOn"
              checked={commentsEnabled}
              onChange={(event) => {
                setCommentsEnabled(!commentsEnabled);
                setChangesMade(true);
                setStatusMessage("");
              }}
            />
            <label className="description--small" htmlFor="commentsOn">
              ON
            </label>
            <input
              className="radio-button"
              type="radio"
              id="commentsOff"
              value="commentsOff"
              checked={!commentsEnabled}
              onChange={(event) => {
                setCommentsEnabled(!commentsEnabled);
                setChangesMade(true);
                setStatusMessage("");
              }}
            />
            <label className="description--small" htmlFor="commentsOff">
              OFF
            </label>
          </div>
          {commentsEnabled ? (
            <select
              value={typeOfComments}
              className="join-battle-select join-battle-select--max-width-none margin--sm"
              onChange={(event) => {
                setChangesMade(true);
                setTypeOfComments(event.target.value);
              }}
            >
              <option key="default_option" value={commentTypeDefault}>
                {commentTypeDefault}
              </option>
              <option key="option2" value={commentType2}>
                {commentType2}
              </option>
              <option key="option3" value={commentType3}>
                {commentType3}
              </option>
              <option key="option4" value={commentType4}>
                {commentType4}
              </option>
            </select>
          ) : null}
          {!myUploadedStory.published ? (
            <>
              <div
                style={{ paddingBottom: "0" }}
                className="flex flex--gap-smaller padding"
              >
                <input
                  id="featureThisStoryCheckbox"
                  className="checkbox"
                  type="checkbox"
                  value="featureThisStoryCheckbox"
                  checked={featureThisStoryOnMyProfile}
                  onChange={(event) => {
                    setFeatureThisStoryOnMyProfile(
                      !featureThisStoryOnMyProfile
                    );
                    setChangesMade(true);
                    setStatusMessage("");
                  }}
                />
                <label
                  htmlFor="featureThisStoryCheckbox"
                  className="description--small"
                >
                  Automatically feature this story on my Writing Battle profile.
                </label>
              </div>
              <div className="flex flex--gap-smaller padding">
                <input
                  required
                  id="iUnderstandCheckbox"
                  className="checkbox--larger"
                  type="checkbox"
                  value="iUnderstand"
                  checked={iUnderstand}
                  onChange={(event) => {
                    setIUnderstand(!iUnderstand);
                    setChangesMade(true);
                    setStatusMessage("");
                  }}
                />
                <label
                  htmlFor="iUnderstandCheckbox"
                  className="description--small"
                >
                  {"(*REQUIRED) "}I understand that my story will no longer be
                  anonymous and Writing Battle users may read my story and
                  associate it with my name or alias. Please check the box to
                  the left in order to share your story.
                </label>
              </div>
            </>
          ) : null}
          <button
            className={"btn btn--register min-width--larger"}
            disabled={
              isLoading ||
              (!iUnderstand && !myUploadedStory.published) ||
              !changesMade
            }
            onClick={onUpdateUploadedStory}
          >
            {publishThisStory
              ? myUploadedStory.published
                ? "Update"
                : "Share story"
              : "Unshare story"}
            &nbsp;
            {!publishThisStory ? (
              <IonIcon className="social-icon" icon={trashOutline} />
            ) : null}
          </button>
          {statusMessage ? (
            <p className="description center-text">{statusMessage}</p>
          ) : null}
          {myUploadedStory.published ? (
            <div className="flex flex--gap-smaller padding">
              <input
                required
                className="checkbox--larger"
                type="checkbox"
                value="unshare"
                checked={!publishThisStory}
                onChange={(event) => {
                  setPublishThisStory(!publishThisStory);
                  setChangesMade(true);
                  if (publishThisStory) {
                    setStatusMessage(
                      "Attention! You are about to unshare your story from " +
                        (isTavern ? "the Tavern!" : "Debrief!")
                    );
                  } else {
                    setStatusMessage("");
                  }
                }}
              />
              <label className="description--smaller">
                Do you no longer wish to share your story? Check this box and
                click "Unshare" if you want your story delisted from{" "}
                {isTavern ? "the Tavern." : "Debrief."}
              </label>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default MyDebriefStory;
