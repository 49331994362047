import { useEffect, useState } from "react";
import { getCardImageUrlByCard } from "../../publicFunctions/publicHelpers/imageUrlBuilder";

function FeaturedStories({ featuredStories, editable, handleChangeToEditing }) {
  const [renderedFeaturedStories, setRenderedFeaturedStories] = useState(null);
  useEffect(() => {
    if (featuredStories?.length) {
      // sort by createdDate
      const tempArray = [...featuredStories];
      tempArray.sort((a, b) => {
        const battleIdA = a.battleId;
        const battleIdB = b.battleId;
        if (!battleIdA && battleIdB) {
          return 1;
        } else if (battleIdA && !battleIdB) {
          return -1;
        } else if (!battleIdA && !battleIdB) {
          return 0;
        } else {
          // get month and year integers
          const lastUpdatedDateA = a.lastUpdatedDate;
          const lastUpdatedDateB = b.lastUpdatedDate;
          const dateObjectA = getDateObject(battleIdA, lastUpdatedDateA);
          const dateObjectB = getDateObject(battleIdB, lastUpdatedDateB);
          if (dateObjectA.date && dateObjectB.date) {
            return dateObjectB.date.getTime() - dateObjectA.date.getTime();
          }

          if (dateObjectA.year > dateObjectB.year) {
            return -1;
          } else if (dateObjectA.year < dateObjectB.year) {
            return 1;
          } else {
            if (dateObjectA.month > dateObjectB.month) {
              return -1;
            } else if (dateObjectA.month < dateObjectB.month) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      });

      const tempRender = [];
      for (let i = 0; i < tempArray.length; i++) {
        const story = tempArray[i];
        const renderedCards = [];
        if (story.tinyCardImages?.length) {
          for (let j = 0; j < story.tinyCardImages?.length; j++) {
            renderedCards.push(
              <img
                className="debrief-genre-title-card"
                key={i.toString + "_" + j.toString(10) + "_card"}
                src={story.tinyCardImages[j]}
              />
            );
          }
        }
        if (!renderedCards.length && story.cards) {
          renderedCards.push(
            <img
              className="debrief-genre-title-card"
              key={"genre_" + story.storyId + "_img"}
              src={getCardImageUrlByCard(story.cards.genre, true)}
            />,
            <img
              className="debrief-genre-title-card"
              key={"prompt1_" + story.storyId + "_img"}
              src={getCardImageUrlByCard(story.cards.prompt1, true)}
            />,
            <img
              className="debrief-genre-title-card"
              key={"prompt2_" + story.storyId + "_img"}
              src={getCardImageUrlByCard(story.cards.prompt2, true)}
            />
          );
        }

        let battleDescriptor = "";
        if (story.battleId) {
          const dateObject = getDateObject(
            story.battleId,
            story.lastUpdatedDate
          );
          battleDescriptor = dateObject.simpleBattleDescriptor;
        }

        tempRender.push(
          <a
            key={story.storyId}
            className="debrief-story-container debrief-story-container--profile"
            role="button"
            href={"/story/debrief/" + story.storyId + "?uploadedStory"}
          >
            <div className="flex align-items--center">{renderedCards}</div>
            <div className={"flex flex--direction-column justify-content--end"}>
              <p className="description--large bold">{story.storyTitle}</p>
              <p className="description--smaller align-self--end">
                {battleDescriptor}
              </p>
            </div>
          </a>
        );
      }
      setRenderedFeaturedStories(tempRender);
    } else {
      setRenderedFeaturedStories(null);
    }
  }, [featuredStories]);

  return (
    <div className="flex flex--direction-column align-items--center justify-content--center margin-left-right-small flex--gap-small">
      {renderedFeaturedStories}
      {editable ? (
        <p
          onClick={handleChangeToEditing}
          className={
            !editable
              ? "description--small center-text " +
                "max-width--readable-center profile-text"
              : "description--small center-text " +
                "max-width--readable-center profile-text profile-editable"
          }
        >
          Click here to edit the Featured Stories on your profile
        </p>
      ) : null}
    </div>
  );
}

function getDateObject(battleId, lastUpdatedDate = null) {
  if (battleId === "tavern" && lastUpdatedDate) {
    const date = lastUpdatedDate;
    return {
      month: date.getMonth(),
      year: date.getFullYear(),
      simpleBattleDescriptor: "Tavern",
      date,
    };
  }
  const yearString = battleId.substring(battleId.length - 4);
  const monthString = battleId.slice(0, -4);
  let returnObj = { year: parseInt(yearString), month: -1 };
  let monthNum = -1;
  let seasonDescription = "";
  if (monthString === "jan") {
    monthNum = 0;
    seasonDescription = "Winter";
  } else if (monthString === "feb") {
    monthNum = 1;
    seasonDescription = "Winter";
  } else if (monthString === "mar") {
    monthNum = 2;
    seasonDescription = "Spring";
  } else if (monthString === "apr") {
    monthNum = 3;
    seasonDescription = "Spring";
  } else if (monthString === "may") {
    monthNum = 4;
    seasonDescription = "Spring";
  } else if (monthString === "jun") {
    monthNum = 5;
    seasonDescription = "Summer";
  } else if (monthString === "jly") {
    monthNum = 6;
    seasonDescription = "Summer";
  } else if (monthString === "aug") {
    monthNum = 7;
    seasonDescription = "Summer";
  } else if (monthString === "sep") {
    monthNum = 8;
    seasonDescription = "Autumn";
  } else if (monthString === "oct") {
    monthNum = 9;
    seasonDescription = "Autumn";
  } else if (monthString === "nov") {
    monthNum = 10;
    seasonDescription = "Autumn";
  } else if (monthString === "dec") {
    monthNum = 11;
    seasonDescription = "Winter";
  }
  returnObj.month = monthNum;
  returnObj.simpleBattleDescriptor = seasonDescription
    ? seasonDescription + " " + yearString
    : yearString;
  return returnObj;
}

export default FeaturedStories;
