function BattleFaceWrapper({ faceImg, title, words, body }) {
  return (
    <div className="battle-face-wrapper">
      <img className="battle-face" src={faceImg} />
      <div>
        <p className="description">
          <span className="bold">{title} </span>
          <span className="description--smaller">
            {"("}
            {words} word story{")"}
          </span>
        </p>
        <p className="description--small">{body}</p>
      </div>
    </div>
  );
}

export default BattleFaceWrapper;
