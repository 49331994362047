import { copyOutline, checkmarkOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { useState } from "react";

function CopyToClipboard({ textToCopy }) {
  const [clicked, setClicked] = useState(false);

  if (
    !textToCopy ||
    !(typeof textToCopy === "string" || textToCopy instanceof String)
  ) {
    return null;
  }
  return (
    <div
      className={clicked ? "icon-button icon-button--clicked" : "icon-button"}
      role="button"
      onClick={() => {
        navigator.clipboard.writeText(textToCopy);
        setClicked(true);
      }}
    >
      <IonIcon
        className="battle-icon--small"
        icon={clicked ? checkmarkOutline : copyOutline}
      />
      <p className="bold bottom-ish-position">{clicked ? "Copied!" : ""}</p>
    </div>
  );
}

export default CopyToClipboard;
