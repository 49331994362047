function BasicNav({
  selectedBattle,
  isLoading,
  battleOptions,
  selectedBattleHandler,
  yearOptions,
  selectedYearHandler,
  selectedYear,
  title,
  hideNav,
}) {
  return (
    <div className="container rules-nav">
      <h1 className="heading-primary">{title}</h1>
      {!hideNav ? (
        <form className="cta-form rules-form" action="#">
          {yearOptions && selectedYearHandler ? (
            <div>
              <select
                className="select--small"
                onChange={selectedYearHandler}
                value={selectedYear}
                disabled={isLoading}
                required
              >
                {yearOptions}
              </select>
            </div>
          ) : null}
          <div>
            <select
              onChange={selectedBattleHandler}
              value={selectedBattle}
              disabled={isLoading}
              required
            >
              {battleOptions}
            </select>
          </div>
        </form>
      ) : null}
    </div>
  );
}

export default BasicNav;
